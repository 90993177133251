import { FormattedMessage } from 'react-intl'
import React from 'react'
import { compose, defaultProps, mapProps, pure, setDisplayName, setPropTypes, withHandlers } from 'recompose'
import { DEFAULT_PROPS, PROP_TYPES } from './config'
import { handleClearClick, propsMapper } from './lib'

const enhance = compose(
  setDisplayName('views/components/CartSummary'),
  setPropTypes(PROP_TYPES),
  defaultProps(DEFAULT_PROPS),
  mapProps(propsMapper),
  withHandlers({
    handleClearClick
  }),
  pure
)

export default enhance(({
  cartUsers,
  currency,
  handleClearClick,
  productsString,
  total
}) =>
  <div className='cartSummary'>
    <div className='container'>
      <div className='row'>
        <div className='col-12 col-sm-3'>
          <FormattedMessage id="CartSummary.Cart"/> {cartUsers}
        </div>
        <div className='col-12 col-sm-3'>
          <FormattedMessage id="CartSummary.Total"/> {total} {currency}
        </div>
        <div className='col-12 col-sm-3'>
          {productsString}
        </div>
        <div className='col-12 col-sm-3 cartSummary_col--last'>
          <span
            className='cartSummary_clear'
            onClick={handleClearClick}
          >
            <FormattedMessage id="CartSummary.Buttons.ClearCart"/>
          </span>
        </div>
      </div>
    </div>
  </div>
)
