import { apiCall } from 'helpers'
import { makeLogic } from 'helpers/redux'
import * as cx from './constants'
import { API_DOMAINS } from 'data/constants'

const getProductSettings = makeLogic({
    httpMethod: apiCall.get,
    latest: true,
    path: `${API_DOMAINS.CATALOG}/GetProductSettingsByProductId`,
    payload: ({ payload }) => ({ params: payload }),
    processError: err => {
      return err
    },
    processResult: res => res.data,
    type: cx.GET_PRODUCT_SETTINGS
  })

  export default [
      getProductSettings
  ]