
import { methodFromProps } from 'helpers'

export default props => () => {
  const toggleCheck = methodFromProps('toggleCheck', props)
  toggleCheck({
    checked: !props.checked,
    label: props.label,
    name: props.name
  })
}
