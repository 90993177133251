
import { Datepicker } from 'views/components/forms'
import { iconed } from 'views/components/forms/hocs'
import React from 'react'
import { Icon } from 'react-icons-kit'
import { calendar } from 'react-icons-kit/fa/calendar'

export default iconed({
  icon: <Icon icon={calendar} />,
  label: 'From'
})(Datepicker)
