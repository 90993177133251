import { createAction } from 'redux-actions'
import * as cx from './constants'

export const createOrdersFromShoppingCart = createAction(cx.CREATE_ORDERS_FROM_SHOPPING_CART)

export const getOrdersPageForClient = createAction(cx.GET_ORDERS_PAGE_FOR_CLIENT)

export const getOrderItemsBtOrderId = createAction(cx.GET_ORDER_ITEMS_BY_ORDER_ID)

export const getClientOrders = createAction(cx.GET_CLIENT_ORDERS, Ids => ({ Ids }))

export const getOrderByRandomId = createAction(cx.GET_ORDER_BY_RANDOM_ID, orderId => ({ orderId }))