
import { get } from 'lodash'
import { getRelatedProducts } from 'redux/modules/shoppingCart/actions'
import { getProductSettings } from 'redux/modules/productSettings/actions';

export default props => {
    const state = props.store.getState()
    const productId = get(state, 'product.data.id')
    const loaders = [
      {
        action: getProductSettings,
        payload: productId
      }
    ]

    loaders.push({
      action: getRelatedProducts,
      payload: productId
    })
  
    return loaders
  }
