
import classnames from 'classnames'
import React from 'react'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { compose, defaultProps, pure, setDisplayName, setPropTypes } from 'recompose'
import { DEFAULT_PROPS, PROP_TYPES } from './config'

const enhance = compose(
  setDisplayName('views/components/sliders/Carousel'),
  setPropTypes(PROP_TYPES),
  defaultProps(DEFAULT_PROPS),
  pure
)

export default enhance(({
  images,
  modification
}) =>
  <Carousel
    autoPlay
    infiniteLoop
    className={classnames('carousel-main', {
      mod_1: modification === 1
    })}
    showArrows={false}
    showStatus={false}
    showThumbs={false}
    stopOnHover={false}
  >
    {images.map((image, key) =>
      <div key={key}>
        <img
          alt={image.alt}
          src={image.src}
        />
      </div>
    )}
  </Carousel>
)
