/* Fetches products from the API: /SearchProducts.
 * @param data = {
 *  attributes: [{ // from Redux: "categoryAttributes.allByCategory"
 *    categoryAttributeValues: [{
 *      selected: Boolean,
 *      value: String | Number
 *    }]
 *  }],
 *  formValues: { // from fields of "components/forms/SearchExtended"
 *   address: { label: String },
 *   from: Moment(time),
 *   lookingFor: { value: String },
 *   page: Number,
 *   pageSize: Number,
 *   to: Moment(time),
 *  },
 *  sortBy: String
 * }
 * @param props = { // properties of "routes/search/products/Page"
 *   searchProducts: Function
 * }
*/
import { methodFromProps } from 'helpers'
import { getSelectedValues } from 'helpers/categoryAttributes'
import { get } from 'lodash'

export default props => data => {
  const searchProducts = methodFromProps('searchProducts', props)
  searchProducts({
    category: get(data, 'formValues.lookingFor.value', null),
    fromDate: get(data, 'formValues.from'),
    location: get(data, 'formValues.address'),
    page: get(data, 'formValues.page'),
    pageSize: get(data, 'formValues.pageSize'),
    productAttributeValueIds: getSelectedValues(data.attributes),
    sortBy: get(data, 'sortBy'),
    toDate: get(data, 'formValues.to'),
    searchText: get(data, 'formValues.searchText', null),
    merchantsIds: get(data, 'selectedMerchants'),
    merchantDistance: get(data, 'searchRadius', null),
  })
}