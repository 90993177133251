
import { Rollet } from 'views/components'
import React from 'react'
import { compose, defaultProps, pure, setDisplayName, setPropTypes } from 'recompose'
import { DEFAULT_PROPS, PROP_TYPES } from './config'

const enhance = compose(
  setDisplayName('views/components/RolletScrolled'),
  setPropTypes(PROP_TYPES),
  defaultProps(DEFAULT_PROPS),
  pure
)

export default enhance(({
  children,
  ...props
}) =>
  <Rollet
    {...props}
    backgroundColor='none'
    className='rollet--scrolled'
  >
    {children}
  </Rollet>
)
