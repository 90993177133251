
import { FORM_ERRORS } from 'data/constants'
import { get } from 'lodash'

export default values => {
  const res = {}
  if (values) {
    if (!values.cardNumber || !values.cardNumber.complete) {
      res.cardNumber = FORM_ERRORS.REQUIRED
    }
    if (get(values, 'cardNumber.error')) {
      res.cardNumber = values.cardNumber.error.message
    }

    if (!values.expirationDate || !values.expirationDate.complete) {
      res.expirationDate = FORM_ERRORS.REQUIRED
    }
    if (get(values, 'expirationDate.error')) {
      res.expirationDate = values.expirationDate.error.message
    }
  }
  return res
}
