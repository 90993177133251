
import PropTypes from 'prop-types'

export const PROP_TYPES = {
  image: PropTypes.shape({
    alt: PropTypes.string,
    src: PropTypes.string
  }),
  name: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ])
}
