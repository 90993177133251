
import { success, error } from 'helpers/redux/actionStateTypes'
import { handleActions } from 'redux-actions'
import * as cx from './constants'
import { get } from 'lodash'

const initialState = {
  categoryAttributes: [],
  data: null,
  pictures: [],
  productAttributeId: null,
  error: {}
}

export default handleActions({
  [success(cx.GET_PRODUCT_DATA)]: (state, action) => {
    return {
      ...state,
      ...action.payload.reduce((acc, data, i) => {
        switch (i) {
          case 1:
            acc.data = data
            break
          case 2:
            acc.pictures = data
            break
          default:
            acc.categoryAttributes = data
        }

        return acc
      }, {})
    }
  },
  [error(cx.GET_PRODUCT_DATA)]: (state, action) => ({
    ...state,
    error: get(action, 'payload', [])
  }),
  [success(cx.GET_PRODUCT_ATTRIBUTE_ID_BY_VALUES)]: (state, action) => ({
    ...state,
    productAttributeId: get(action, 'payload', [])
  })
}, initialState)
