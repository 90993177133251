
import { ButtonSharp } from 'views/components/buttons'
import React from 'react'
import { compose, pure, setDisplayName, setPropTypes } from 'recompose'
import { PROP_TYPES } from './config'

const enhance = compose(
  setDisplayName('views/components/buttons/ButtonLess'),
  setPropTypes(PROP_TYPES),
  pure
)

export default enhance(({
  backgroundColor,
  children,
  color,
  type,
  ...props
}) =>
  <ButtonSharp
    className='button-less'
    hoverStyle={{
      borderColor: backgroundColor,
      color: backgroundColor
    }}
    style={{
      backgroundColor,
      borderColor: backgroundColor,
      color
    }}
    type={type}
    {...props}
  >
    {children}
  </ButtonSharp>
)
