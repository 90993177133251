
import { methodFromProps } from 'helpers'
import { format } from 'helpers/dateTime'
import { get, omit } from 'lodash'
import moment from 'moment'

export default ({
  dateFormat,
  input,
  meta,
  timeOnly,
  ...props
}) => {
  const error = get(meta, 'error')
  const inputValue = get(input, 'value')
  const newDateFormat = timeOnly ? 'LT' : dateFormat

  return {
    ...props,
    dateFormat: newDateFormat,
    error,
    input: omit(input, [
      'onChange',
      'value'
    ]),
    inputValue: format(inputValue, newDateFormat),
    minDate: props.disableNonUsed ? moment() : null,
    onInputChange: methodFromProps('onChange', input),
    selected: inputValue || null,
    showError: error && get(meta, 'touched') && !get(meta, 'active'),
    showTimeSelect: !!timeOnly,
    showTimeSelectOnly: !!timeOnly
  }
}
