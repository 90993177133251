
import { get } from 'lodash'

export default (groupedAttrs, attrValues) => groupedAttrs.map(ga => {
  const res = { ...ga }
  const value = get(attrValues, `${ga.name}`)
  if (value) {
    res.value = value
  }
  return res
})
