
import { connectRouter, routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import { get } from 'lodash'
import { applyMiddleware, compose } from 'redux'
import { configureStore } from '@reduxjs/toolkit'
import { createLogicMiddleware } from 'redux-logic'
import { createStore } from 'redux'
import logics from './logics'
import createRootReducer from './reducers'

const composeEnhancers = get(window, '__REDUX_DEVTOOLS_EXTENSION_COMPOSE__', compose)
export const history = createBrowserHistory()
const logicMV = createLogicMiddleware(logics)
const middleware = [
  logicMV,
  routerMiddleware(history)
]

export default initialState => configureStore({
  reducer: createRootReducer(history),
  preloadedState: initialState,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: middleware
})

// export default initialState => createStore(
//   connectRouter(history)(reducers),
//   initialState,
//   composeEnhancers(
//     applyMiddleware(...middleware)
//   )
// )
