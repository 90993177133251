
import { get } from 'lodash'
import React from 'react'
import { Icon } from 'react-icons-kit'
import { timesCircle } from 'react-icons-kit/fa/timesCircle'
import uniqid from 'uniqid'
import { handleClear } from './lib'

/*
@param config: {
  clearable: PropTypes.bool,
  icon: PropTypes.oneOfType([
    PropTypes.shape({
      alt: PropTypes.string,
      src: PropTypes.string
    }),
    PropTypes.element
  ]),
  label: PropTypes.string
}
*/
export default config => Component => {
  const {
    clearable,
    icon,
    label
  } = config
  const iconImage = get(icon, 'src') !== undefined
  const uid = uniqid()

  return props => (
    <div className='iconed-wrap'>
      {label ? (
        <label
          className='iconed_label'
          htmlFor={uid}
        >
          {props.label || label}
        </label>
      ) : null}
      <div className='iconed'>
        {icon ? (
          <div className='iconed_icon'>
            {iconImage ? (
              <img
                alt={icon.alt}
                src={icon.src}
              />
            ) : icon}
          </div>
        ) : null}
        <Component {...props} />
        {clearable ? (
          <Icon
            className='iconed_clear'
            icon={timesCircle}
            onClick={handleClear(props)}
            size={14}
          />
        ) : null}
      </div>
    </div>
  )
}
