import React from 'react';
import ReactDOM from 'react-dom/client';
import './theme/styles/index.css'
import App from './views/App';
import reportWebVitals from './reportWebVitals';

import { ConnectedRouter } from 'connected-react-router'
import { userManager } from './data'
import { Provider } from 'react-redux'
import { history, default as createStore } from './redux/configureStore'
import { loadUser, OidcProvider } from 'redux-oidc'
import registerServiceWorker from './registerServiceWorker'
import { ToastProvider } from 'react-toast-notifications'


const root = ReactDOM.createRoot(document.getElementById('root'));
const store = createStore({});
loadUser(store, userManager)

root.render(
  <Provider store={store}>
    <OidcProvider
      store={store}
      userManager={userManager}
    >
      <ConnectedRouter history={history}>
        <ToastProvider>
          <App />
        </ToastProvider>
      </ConnectedRouter>
    </OidcProvider>
  </Provider>
)


// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
registerServiceWorker()