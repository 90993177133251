
import classnames from 'classnames'
import { ALIGN } from 'data/constants'
import React from 'react'
import { When } from 'react-if'
import { compose, defaultProps, pure, setDisplayName, setPropTypes } from 'recompose'
import { DEFAULT_PROPS, PROP_TYPES } from './config'

const enhance = compose(
  setDisplayName('views/components/Status'),
  setPropTypes(PROP_TYPES),
  defaultProps(DEFAULT_PROPS),
  pure
)

const Message = props => (
  <When condition={!!props.message}>
    <p className='status_msg'>
      {props.message}
    </p>
  </When>
)

export default enhance(({
  className,
  message,
  position,
  size
}) =>
  <div className={classnames('status', {
    [className]: !!className
  })}>
    <When condition={position === ALIGN.TOP}>
      <Message message={message} />
    </When>
    <img
      alt=''
      height={size}
      src={require('theme/images/spinner.svg')}
      width={size}
    />
    <When condition={position === ALIGN.BOTTOM}>
      <Message message={message} />
    </When>
  </div>
)
