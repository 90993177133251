
import React from 'react'
import { compose, pure, setDisplayName, setPropTypes } from 'recompose'
import { PROP_TYPES } from './config'

const enhance = compose(
  setDisplayName('views/components/InnerBanner'),
  setPropTypes(PROP_TYPES),
  pure
)

export default enhance(({
  bannerCnt,
  title
}) =>
  <section className='inner-banner'>
    <div className='inner-banner_bg'>
      <div className='container'>
        <div className='row inner-banner_cnt'>
          {bannerCnt
            ? bannerCnt
            : title
            ? (
              <h1 className='inner-banner_hd'>
                {title}
              </h1>
            )
            : null
          }
        </div>
      </div>
    </div>
  </section>
)
