
import { Select } from 'views/components/forms'
import React from 'react'
import { compose, pure, setDisplayName, setPropTypes } from 'recompose'
import { PROP_TYPES } from './config'
import { FormattedMessage } from 'react-intl'

const enhance = compose(
  setDisplayName('views/components/forms/Sorter'),
  setPropTypes(PROP_TYPES),
  pure
)

export default enhance(props =>
  <div className='sorter'>
    <span className='sorter_label'>
      <FormattedMessage id="Components.Sorter"/>
    </span>
    <Select {...props} />
  </div>
)
