
import { SEARCH_PRODUCTS_DEFAULTS } from 'data/constants'
import { methodFromProps } from 'helpers'
import { get } from 'lodash'
import moment from 'moment'

export default props => () => {
  const fetchGoods = methodFromProps('fetchGoods', props)
  const productMeta = get(props, 'productMeta')
  const dateFrom = get(productMeta, 'date.from')
  const dateTo = get(productMeta, 'date.to')

  fetchGoods({
    attributes: get(props, 'categoryAttributes.allByCategory', []),
    formValues: {
      address: get(productMeta, 'deliveryAddress'),
      from: dateFrom ? moment(dateFrom) : null,
      lookingFor: get(productMeta, `categories.[${get(productMeta, 'categoryIdx')}]`),
      page: 1,
      pageSize: get(props, 'products.length', 0) + SEARCH_PRODUCTS_DEFAULTS.PAGE_SIZE,
      to: dateTo ? moment(dateTo) : null
    }
  })
}
