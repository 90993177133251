import { FormattedMessage } from 'react-intl'
import React from 'react'

export default status => {
    switch (status) {
        case 0: return <FormattedMessage id='OrderDeliveryType.OnlyDelivery'/>
        case 1: return <FormattedMessage id='OrderDeliveryType.OnlyReturn'/>
        case 2: return <FormattedMessage id='OrderDeliveryType.DeliveryAndReturn'/>
        case 3: return <FormattedMessage id='OrderDeliveryType.None'/>
        default: return <FormattedMessage id='OrderDeliveryType.None'/>
      }
}