import { FormattedMessage } from 'react-intl'
import React from 'react'

export default status => {
    switch (status) {
        case 0: return <FormattedMessage id='JobStatus.Accepted'/>
        case 1: return <FormattedMessage id='JobStatus.NotAssigned'/>
        case 2: return <FormattedMessage id='JobStatus.Assigned'/>
        case 3: return <FormattedMessage id='JobStatus.Completed'/>
        case 4: return <FormattedMessage id='JobStatus.Cancelled'/>
        case 5: return <FormattedMessage id='JobStatus.Collected'/>
        case 6: return <FormattedMessage id='JobStatus.TakeAway'/>
        case 7: return <FormattedMessage id='JobStatus.SelfReturn'/>
        case 11: return <FormattedMessage id='JobStatus.Emergency'/>
        case 14: return <FormattedMessage id='JobStatus.Blocked'/>
        case 15: return <FormattedMessage id='JobStatus.Unblocked'/>
        default: return <FormattedMessage id='JobStatus.NotAssigned'/>
      }
}