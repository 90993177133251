
import { HeadedForm } from 'views/blocks/forms'
import { InnerPage } from 'views/pages'
import React from 'react'
import { connect } from 'react-redux'
import { compose, mapProps, pure, setDisplayName, withHandlers } from 'recompose'
import { ACTIONS, FIELDS } from './config'
import { mapState, propsMapper, submitResetPassword, validate } from './lib'
import { FormattedMessage } from 'react-intl';

const enhance = compose(
  setDisplayName('views/routes/user/password/reset/Page'),
  connect(mapState, ACTIONS),
  mapProps(propsMapper),
  withHandlers({
    submitResetPassword
  }),
  pure
)

export default enhance(({
  updateUserPasswordStatus,
  submitResetPassword
}) =>
  <InnerPage
    className='passwordResetPage'
    withSearch
  >
    <div className='passwordResetPage_cnt'>
      <HeadedForm
        fields={FIELDS}
        form='views/routes/user/password/reset/Page--form'
        onSubmit={submitResetPassword}
        status={updateUserPasswordStatus}
        title={<FormattedMessage id="UserMenu.ResetPassword"/>}
        validate={validate}
      />
    </div>
  </InnerPage>
)
