
import React from 'react'
import { Icon } from 'react-icons-kit'
import { androidAddCircle } from 'react-icons-kit/ionicons/androidAddCircle'
import { compose, pure, setDisplayName, setPropTypes } from 'recompose'
import { PROP_TYPES } from './config'

const enhance = compose(
  setDisplayName('views/components/buttons/ButtonPlus'),
  setPropTypes(PROP_TYPES),
  pure
)

export default enhance(props =>
  <Icon
    className='buttonPlus'
    icon={androidAddCircle}
    size={30}
    {...props}
  />
)
