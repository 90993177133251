
import PropTypes from 'prop-types'

export const DEFAULT_PROPS = {
  images: [{
    alt: 'An images array is needed',
    src: ''
  }]
}

export const PROP_TYPES = {
  images: PropTypes.arrayOf(PropTypes.shape({
    alt: PropTypes.string,
    src: PropTypes.string
  })),
  modification: PropTypes.number
}
