
import React from 'react'
import { compose, pure, setDisplayName, setPropTypes } from 'recompose'
import { PROP_TYPES } from './config'

const enhance = compose(
  setDisplayName('views/components/Point'),
  setPropTypes(PROP_TYPES),
  pure
)

export default enhance(({
  description,
  image
}) =>
  <div className='point'>
    <img
      {...image}
      alt={image.alt}
      className='point-image'
    />
    <p>
      {description}
    </p>
  </div>
)
