
import { ASYNC_STATUSES } from 'data/constants'
import { values } from 'lodash'
import PropTypes from 'prop-types'

export const DEFAULT_PROPS = {
  errorMessage: 'Password change failed. Try again',
  fields: [],
  statusShowDuration: 3000,
  successMessage: 'Password changed successfully'
}

export const PROP_TYPES = {
  errorMessage: PropTypes.string,
  fields: PropTypes.arrayOf(PropTypes.shape({
    component: PropTypes.element,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    type: PropTypes.string
  })),
  form: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  status: PropTypes.oneOf([...values(ASYNC_STATUSES), '']),
  statusShowDuration: PropTypes.number,
  successMessage: PropTypes.string,
  title: PropTypes.string,
  validate: PropTypes.func
}
