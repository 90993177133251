
import classnames from 'classnames'
import { chunk } from 'lodash'
import React from 'react'
import { compose, defaultProps, pure, setDisplayName, setPropTypes } from 'recompose'
import { DEFAULT_PROPS, PROP_TYPES } from './config'

const enhance = compose(
  setDisplayName('views/components/lists/ResponsiveList'),
  setPropTypes(PROP_TYPES),
  defaultProps(DEFAULT_PROPS),
  pure
)

export default enhance(({
  className,
  items,
  itemsInRow
}) =>
  <ul className={classnames('responsiveList', {
    [className]: !!className,
    responsiveList_multi: itemsInRow > 1
  })}>
    {chunk(items, itemsInRow).map((item, key) =>
      <li key={key}>
        {item.map((it, k) =>
          <div
            className={`col-${12 / itemsInRow}`}
            key={k}
          >
            {it}
          </div>
        )}
      </li>
    )}
  </ul>
)
