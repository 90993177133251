
import { get } from 'lodash'
import React from 'react'
import { compose, pure, setDisplayName, setPropTypes } from 'recompose'
import { PROP_TYPES } from './config'

const enhance = compose(
  setDisplayName('views/components/ProductSec'),
  setPropTypes(PROP_TYPES),
  pure
)

export default enhance(({
  image,
  name
}) =>
  <div className='product-sec'>
    <img
      alt={get(image, 'alt', '')}
      className='product-sec-image'
      src={get(image, 'src', '')}
    />
    <p className='product-sec-name'>
      {name}
    </p>
  </div>
)
