
import { Carousel } from 'views/components/sliders'
import PropTypes from 'prop-types'
import React from 'react'
import { compose, pure, setDisplayName, setPropTypes } from 'recompose'

const enhance = compose(
  setDisplayName('views/routes/_lib/components/Sliders'),
  setPropTypes({
    title: PropTypes.string
  }),
  pure
)

export default enhance(({
  title
}) =>
  <div>
    <h2 style={{ marginTop: 20 }}>
      {title}
    </h2>
    <p>
      components/sliders/Carousel
    </p>
    <Carousel
      images={[
        {
          alt: '',
          src: require('theme/images/slider_img1.jpg')
        },
        {
          alt: '',
          src: require('theme/images/slider_img2.jpg')
        },
        {
          alt: '',
          src: require('theme/images/slider_img3.jpg')
        }
      ]}
    />
    <p>
      components/sliders/Carousel (mod 1)
    </p>
    <Carousel
      images={[
        {
          alt: '',
          src: require('theme/images/boot.jpg')
        },
        {
          alt: '',
          src: require('theme/images/boot.jpg')
        },
        {
          alt: '',
          src: require('theme/images/boot.jpg')
        }
      ]}
      modification={1}
    />
  </div>
)
