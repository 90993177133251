import { typeGen } from 'helpers/redux'
import { ORDERS_PAGE_DEFAULTS } from 'data/constants'

const makeType = typeGen('orders')

export const CREATE_ORDERS_FROM_SHOPPING_CART = makeType('createOrdersFromShoppingCart')

export const GET_ORDERS_PAGE_FOR_CLIENT = makeType('getOrdersPageForClient')

export const GET_ORDER_ITEMS_BY_ORDER_ID = makeType('getOrderItemsByOrderId')

export const GET_CLIENT_ORDERS = makeType('getClientOrders')

export const GET_ORDER_BY_RANDOM_ID = makeType('getOrderByRandomId')

export const ORDERS_PAGE_DEFAULT_PARAMS = {
    ...ORDERS_PAGE_DEFAULTS
  }