
import { get } from 'lodash'

export default ({
  meta,
  ...props
}) => {
  const error = get(meta, 'error')

  return {
    ...props,
    error,
    showError: error && get(meta, 'touched') && !get(meta, 'active')
  }
}
