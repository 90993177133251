import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import React from 'react'
import { API_DOMAINS } from 'data/constants'

export const CONTEXT_TYPES = {
  router: PropTypes.object
}

export const DEFAULT_PROPS = {
  cartQuantity: 0,
  user: {}
}

export const INFO_OPS = [
  {
    href: '/about',
    label: <FormattedMessage id="InfoList.AboutUs"/>
  },
  {
    href: '/faq',
    label: <FormattedMessage id="InfoList.Faq"/>
  },
  {
    href: '/terms',
    label: <FormattedMessage id="InfoList.TermsAndConditions"/>
  }
]

export const PROP_TYPES = {
  cartQuantity: PropTypes.number,
  children: PropTypes.any,
  className: PropTypes.string,
  user: PropTypes.object
}

export const SOCIAL_OPS = [
  {
    href: 'https://www.facebook.com/',
    icon: require('react-icons-kit/ionicons/socialFacebook').socialFacebook,
    targetBlank: true
  },
  {
    href: 'https://twitter.com/',
    icon: require('react-icons-kit/ionicons/socialTwitter').socialTwitter,
    targetBlank: true
  },
  {
    href: 'https://instagram.com/',
    icon: require('react-icons-kit/fa/instagram').instagram,
    targetBlank: true
  },
  {
    href: 'https://www.pinterest.com/',
    icon: require('react-icons-kit/fa/pinterestP').pinterestP,
    targetBlank: true
  }
]

export const USER_MENU = [
  {
    lighted: true,
    label: <FormattedMessage id="UserMenu.ShopNow"/>,
    path: '/'
  },
  {
    label: <FormattedMessage id="UserMenu.OrderHistory"/>,
    path: '/orders'
  },
  {
    label: <FormattedMessage id="UserMenu.Profile"/>,
    path: `${API_DOMAINS.AUTH}/UserProfile/Index?lang=${localStorage.lang}`,
  },
  {
    label: <FormattedMessage id="UserMenu.ResetPassword"/>,
    path: '/user/password/reset'
  },
  {
    label: <FormattedMessage id="UserMenu.Logout"/>,
    path: '/auth/logout'
  }
]
