
import PropTypes from 'prop-types'

export const DEFAULT_PROPS = {
  items: [],
  itemsInRow: 1
}

export const PROP_TYPES = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.any),
  itemsInRow: PropTypes.oneOf([1, 2, 3, 4, 6, 12])
}
