
import classnames from 'classnames'
import React from 'react'
import { Icon } from 'react-icons-kit'
import { androidAdd } from 'react-icons-kit/ionicons/androidAdd'
import { androidRemove } from 'react-icons-kit/ionicons/androidRemove'
import { compose, defaultProps, pure, setDisplayName, setPropTypes, withHandlers, withState } from 'recompose'
import { DEFAULT_PROPS, PROP_TYPES } from './config'
import { handleTitleClick } from './lib'

const enhance = compose(
  setDisplayName('views/components/Rollet'),
  setPropTypes(PROP_TYPES),
  defaultProps(DEFAULT_PROPS),
  withState('chOpened', 'chOpen', ({ opened }) => opened),
  withHandlers({
    handleTitleClick
  }),
  pure
)

export default enhance(({
  backgroundColor,
  children,
  chOpened,
  className,
  control,
  handleTitleClick,
  title
}) =>
  <div className={classnames('rollet', {
    [className]: !!className
  })}>
    <div
      className={classnames('rollet_title', {
        closed: !chOpened,
        control
      })}
      onClick={handleTitleClick}
    >
      {title}
      <Icon
        icon={chOpened ? androidRemove : androidAdd}
        size={20}
      />
    </div>
    {chOpened ? (
      <div
        className='rollet_children'
        style={{ backgroundColor }}
      >
        {children}
      </div>
    ) : null}
  </div>
)
