import { MainOffer } from 'views/blocks'
import { ProductsList, WorkFloatList } from 'views/components/lists'
import { Page } from 'views/pages'
import React from 'react'
import { connect } from 'react-redux'
import { compose, mapProps, pure, setDisplayName, withHandlers, withState } from 'recompose'
import { ACTIONS, PRODUCTS, WORK_FLOAT_LIST } from './config'
import { handleClickSearchWarning, 
         handleCloseLocationModal,
         handleClickSaveDefaultLocation,
         handleSubmitSearch,
         handleStartDate,
         handleEndDate,
         mapState, 
         propsMapper } from './lib'
import { ButtonDark } from 'views/components/buttons'
import { FormattedMessage } from 'react-intl'
import { Modal } from 'views/components/modals'
import { Search } from 'views/components/forms'
import { get } from 'lodash'
import { Field, reduxForm } from 'redux-form'
import { DatepickerIconed, DatepickerTo } from 'views/components/forms/SearchExtended/components'

const enhance = compose(
  setDisplayName('views/routes/Page'),
  connect(mapState, ACTIONS),
  withState('LocationModal', 'toggleLocationModal', false),
  withState('StartDefaultDate', 'toggleStartDefaultDate', ''),
  withState('EndDefaultDate', 'toggleEndDefaultDate', {}),
  mapProps(propsMapper),
  reduxForm({
    form: "views/routes/Page"
  }),
  withHandlers({
    handleClickSaveDefaultLocation,
    handleCloseLocationModal,
    handleClickSearchWarning,
    handleSubmitSearch,
    handleStartDate,
    handleEndDate,
  }),
  pure
)

export default enhance(({
  handleClickSaveDefaultLocation,
  handleClickSearchWarning,
  handleCloseLocationModal,
  handleSubmitSearch,
  handleStartDate,
  handleEndDate,
  search,
  LocationModal,
  StartDefaultDate,
  EndDefaultDate
}) =>
  <Page>
    <MainOffer
      clickSearchWarning={handleClickSearchWarning}
      search={search}
      submitSearch={handleSubmitSearch}
    />
    <section className='workfloat'>
      <h2 className='hd-mountain'>
        <FormattedMessage id="WorkFloatList.HowItWorks"/>
      </h2>
      <WorkFloatList items={WORK_FLOAT_LIST} />
    </section>
    <section className='products'>
      <h2 className='hd-mountain'>
        <FormattedMessage id="ProductsPage.Content.OurProducts"/>
      </h2>
      <ProductsList items={PRODUCTS} />
    </section>
    {LocationModal ? (
      <Modal
        children={
          <form name='modal_default_search' onSubmit={handleClickSaveDefaultLocation}>
            <article className='location_modal'>            
              <FormattedMessage id="LandingPage.LocationModal.Title"/>
              <section className='modal_search'>
                <FormattedMessage id="Forms.Search.Placeholder">
                  {
                    (msg) => (
                      <Search
                        name='modal-search'                     
                        onSubmit={handleClickSaveDefaultLocation}
                        placeholder={msg[0]}
                        status={get(search, 'status')}
                      />
                    )
                  }
                </FormattedMessage>
              </section>
              <FormattedMessage id="LandingPage.LocationModal.DateTitle"/>
              <section className='modal_search'>
                <div className='row f-search-extended--no-mt'>
                  <div className='col-12 col-sm-6 col-md-6 f-search-extended--mt'>
                    <Field
                      component={DatepickerIconed}
                      name='modal-date-from'
                      onChange={handleStartDate}
                      value={StartDefaultDate}
                      selected={StartDefaultDate}
                    />
                  </div>
                  <div className='col-12 col-sm-6 col-md-6 f-search-extended--mt'>
                    <Field
                      component={DatepickerTo}
                      label={<FormattedMessage id="SearchExtended.Label.To"/>}
                      name='modal-date-to'
                      onChange={handleEndDate}
                      value={EndDefaultDate}
                      selected={EndDefaultDate}
                    />
                  </div>
                </div>
              </section>
              <footer className='location_modal_footer'> 
                <ButtonDark type="submit">
                  <FormattedMessage id="LandingPage.LocationModal.ButtonSave"/>
                </ButtonDark>          
              </footer>
            </article>
          </form>
        }
        onClose={handleCloseLocationModal}
      />
    ) : null}
  </Page>
)
