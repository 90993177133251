
import { getCategoryIndexById } from 'helpers/categories'
import {
  addValuesToGroupedAttrs,
  filterCategoryAttrsByNameValue,
  getDatesAsDate,
  groupAllAttributesByName,
  mapCategoryAttrValues
} from 'helpers/products'
import { get } from 'lodash'
import { handleProductMetaSubmit } from './lib'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useToasts } from 'react-toast-notifications'
import { API_DOMAINS } from 'data/constants'


export default ({
  categories,
  product,
  products,
  shoppingCart,
  ...props
}) => {
  const { addToast } = useToasts();
  const categoriesAll = get(categories, 'all', [])
  const categoryAttrValuesFields = get(props, 'categoryAttrValues.fields')
  const productCategoryAttrs = filterCategoryAttrsByNameValue(
    get(product, 'categoryAttributes', []),
    mapCategoryAttrValues(categoryAttrValuesFields)
  )
  const productData = get(product, 'data')
  const productPictures = get(product, 'pictures', [])
  const shoopingCartItems = get(shoppingCart, 'shoppingCardItems',[])
  const relatedProducts = get(shoppingCart, 'relatedProducts', [])

  var loaded = (typeof productCategoryAttrs !== undefined && productCategoryAttrs.length > 0 && productData !== undefined) ? true : false

  const error = get(product, 'error') 
  if (error !== undefined && error.message !== undefined) {
    addToast(error.message, { appearance: 'error', autoDismiss: true })
  }

  const shoppingCartError = get(shoppingCart, 'error') 
  if (shoppingCartError !== undefined && shoppingCartError.message !== undefined) {
    addToast(shoppingCartError.message, { appearance: 'error', autoDismiss: true })
  }

  return {
    ...props,
    cartQuantity: 0,
    categoryAttributesFields: addValuesToGroupedAttrs(
      groupAllAttributesByName(productCategoryAttrs),
      categoryAttrValuesFields
    ),
    currency: localStorage.currency,
    description: get(productData, 'fullDescription', ''),
    forWhom: get(productData, 'recommendedFor', ''),
    images: productPictures.map(pic => ({
      alt: require('theme/images/boot.jpg'),
      src: pic.pictureFileName == null ? require('theme/images/thumbnail.svg') : `${API_DOMAINS.STORAGE}/${pic.pictureFileName}`,
    })),
    level: 'Level',
    orderTotal: shoopingCartItems.reduce(function(prev, current){
      return prev + +current.price
    }, 0),
    otherProducts: relatedProducts.map(item => ({
      chosen: false,
      label: item.product2Name
    })),
    price: get(productData, 'price', 0),
    productMeta: {
      categories: categoriesAll.map(ca => ({
        label: ca.name,
        value: ca.id,
        parentCategoryId: ca.parentCategoryId
      })),
      categoryIdx: getCategoryIndexById(categoriesAll, get(productData, 'categoryId')),
      date: {
        ...getDatesAsDate(products),
        noDate: <FormattedMessage id="Products.NotDefined"/>
      },
      deliveryAddress: get(products, 'location'),
      submitForm: handleProductMetaSubmit(props)
    },
    shop: get(productData, 'manufacturerName', ''),
    title: get(productData, 'name', ''),
    isLoaded: loaded
  }
}
