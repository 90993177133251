
import PropTypes from 'prop-types'

export const DEFAULT_PROPS = {
  required: false,
  type: 'text'
}

export const PROP_TYPES = {
  big: PropTypes.bool,
  borderRadius: PropTypes.number,
  className: PropTypes.string,
  errorAtBottom: PropTypes.bool,
  input: PropTypes.shape({
    onChange: PropTypes.func
  }),
  inputClassName: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string
  ]),
  meta: PropTypes.shape({
    error: PropTypes.string
  }),
  mod: PropTypes.oneOf([1]),
  noBorder: PropTypes.bool,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  small: PropTypes.bool,
  stripeElement: PropTypes.func,
  style: PropTypes.object,
  tip: PropTypes.string,
  type: PropTypes.oneOf([
    'email',
    'password',
    'text'
  ])
}
