
import PropTypes from 'prop-types'

export const DEFAULT_TYPES = {
  href: ''
}

export const PROP_TYPES = {
  children: PropTypes.string,
  href: PropTypes.string,
  target: PropTypes.string
}
