
import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import { reducer as oidcReducer } from 'redux-oidc'
import { connectRouter } from 'connected-react-router'

export default history => combineReducers({
  asyncState: require('./modules/asyncState').reducer,
  auth: oidcReducer,
  categories: require('./modules/categories').reducer,
  categoryAttributes: require('./modules/categoryAttributes').reducer,
  form: formReducer,
  notifications: require('./modules/notifications').reducer,
  product: require('./modules/product').reducer,
  products: require('./modules/products').reducer,
  user: require('./modules/user').reducer,
  locale: require('./modules/locale').reducer,
  shoppingCart: require('./modules/shoppingCart').reducer,
  productSettings: require('./modules/productSettings').reducer,
  orders: require('./modules/orders').reducer,
  currency: require('./modules/currency').reducer,
  languages: require('./modules/languages').reducer,
  router: connectRouter(history)
})
