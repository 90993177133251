
import { Breadcrumbs } from 'views/components'
import { Anchor, HdTitle2, MoreResults, NotFound, Price } from 'views/components/texts'
import { currencies } from 'data'
import { ALIGN } from 'data/constants'
import faker from 'faker'
import PropTypes from 'prop-types'
import React from 'react'
import { compose, pure, setDisplayName, setPropTypes } from 'recompose'

const enhance = compose(
  setDisplayName('views/routes/_lib/components/Texts'),
  setPropTypes({
    heading: PropTypes.string
  }),
  pure
)

export default enhance(({
  heading
}) =>
  <div>
    <p>
      components/Anchor
    </p>
    <Anchor href='/_lib'>
      Inside path
    </Anchor>
    <br />
    <Anchor href='https://google.com'>
      Outside path
    </Anchor>
    <br />
    <Anchor
      href='https://google.com'
      target='_blank'
    >
      Outside path in new page
    </Anchor>

    <h2 style={{ marginTop: 20 }}>
      {heading}
    </h2>

    <h1>
      Heading 1
    </h1>
    <h1 className='hd-page'>
      Hd-page heading
    </h1>
    <h1 className='hd-cap'>
      H1 Capitalized
    </h1>
    <h1 className='hd-cap2'>
      Heading cap 2
    </h1>
    <h1 className='hd-title'>
      Heading 1 title
    </h1>
    <HdTitle2>
      Heading 2 title
    </HdTitle2>

    <h2>
      Heading 2
    </h2>
    <h2 className='hd-mountain'>
      Mountained Heading
    </h2>
    <h2 className='hd-mountain hd-mountain-dark'>
      Mountained Heading (Dark)
    </h2>

    <h3>
      Heading 3
    </h3>
    <h4>
      Heading 4
    </h4>
    <h5>
      Heading 5
    </h5>
    <h6>
      Heading 6
    </h6>

    <p className='p-description'>
      Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
    </p>

    <p>
      components/Breadcrumbs
    </p>
    <Breadcrumbs
      items={[
        { label: 'Go back', path: '/' }
      ]}
    />
    <Breadcrumbs
      items={[
        { label: 'Go back', path: '/' },
        { label: 'Go back 2', path: '/' },
        { label: 'Go back 3', path: '/' }
      ]}
    />

    <p>
      components/texts/MoreResults
    </p>
    <MoreResults
      onClick={ev => { console.log(ev) }}
      text='Get me more'
    />

    <p>
      components/texts/NotFound
    </p>
    <NotFound />
    <NotFound text={faker.lorem.words()} />

    <p>
      components/texts/Price
    </p>
    <Price />
    <Price value={33} />
    <Price
      align={ALIGN.CENTER}
      currency={currencies.GBP}
      value={33}
    />
    <Price
      align={ALIGN.RIGHT}
      currency={currencies.EUR}
      value={33}
    />
  </div>
)
