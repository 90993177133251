
import { get } from 'lodash'
import { addValuesToGroupedAttrs } from 'helpers/products'

export default ({
  ...props
}) => {
  const productsFields = get(props, 'products', [])
  const productSettingVaues = get(props, 'productSettingValues', [])
  var newProductsFields = productsFields
  newProductsFields[0].fields = addValuesToGroupedAttrs(
    productsFields[0].fields,
    productSettingVaues.fields)

  return {
    ...props,
    products: newProductsFields
  }
}
