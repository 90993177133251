import { methodFromProps } from 'helpers'
import { get } from 'lodash'

export default props => values => {
    values.preventDefault()
    const displayLoader = methodFromProps('displayLoader', props)
    displayLoader(false)

    const enablePlaseOrder = methodFromProps('enablePlaseOrder', props)
    enablePlaseOrder(false)

    const createOrdersFromShoppingCart = methodFromProps('createOrdersFromShoppingCart', props)
    const userId = get(props, 'auth.user.profile.id')
    const deliveryAddress = values.target['deliveryAddress'].value
    const deliveryTime = values.target['deliveryTime'].value
    var returnAddress = ''
    const returnTime = values.target['returnTime'].value
    const useSameAddress = values.target['useSameAddress'].checked

    if (useSameAddress) {
        returnAddress = deliveryAddress
    } else {
        returnAddress = values.target['returnAddress'].value
    }

    const form = new FormData()
    form.set('CustomerId', userId)
    form.set('DeliveryAddress', deliveryAddress)
    form.set('DeliveryTime', deliveryTime)
    form.set('ReturnAddress', returnAddress)
    form.set('ReturnTime', returnTime)
    form.set('WithTakeAway', values.target['withTakeAway'].checked)
    form.set('WithSelfReturn', values.target['withSelfReturn'].checked)

    createOrdersFromShoppingCart(form)
}
