
import classnames from 'classnames'
import React from 'react'
import Paginator from 'react-paginate'
import { compose, pure, setDisplayName, setPropTypes } from 'recompose'
import { PROP_TYPES } from './config'
import { FormattedMessage } from 'react-intl'

const enhance = compose(
  setDisplayName('views/components/Paginator'),
  setPropTypes(PROP_TYPES),
  pure
)

export default enhance(({
  clickPage,
  isWide,
  selected,
  totalPages
}) =>
  <div className={classnames('paginator', {
    paginator_wide: isWide
  })}>
    <span className='paginator_count'>
      <FormattedMessage id="Components.Paginator.Page"/> {selected + 1} <FormattedMessage id="Components.Paginator.Page.Of"/> {totalPages}
    </span>
    <Paginator
      disableInitialCallback
      containerClassName='paginator_list'
      initialPage={selected}
      marginPagesDisplayed={1}
      pageCount={totalPages}
      pageRangeDisplayed={3}
      previousLabel='<<'
      nextLabel='>>'
      onPageChange={clickPage}
    />
  </div>
)
