
import { InnerPage } from 'views/pages'
import React from 'react'
import { compose, pure, setDisplayName } from 'recompose'

const enhance = compose(
  setDisplayName('views/routes/terms/Page'),
  pure
)

export default enhance(() =>
  <InnerPage title='Terms and conditions'>
    <h2>
      Welcome to Deliquity!
    </h2>
    <p>
      Take the Terms of Use text from the database.
    </p>
  </InnerPage>
)
