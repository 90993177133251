
import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { compose, pure, setDisplayName } from 'recompose'

const enhance = compose(
  setDisplayName('views/routes/_r/search/Router'),
  pure
)

export default enhance(() =>
  <Switch>
    <Redirect
      exact
      from='/search'
      to='/search/products'
    />
    <Route
      component={require('views/routes/_r/search/_r/products').Router}
      path='/search/products'
    />
  </Switch>
)
