
import PropTypes from 'prop-types'
import { PROP_TYPES as PARENT_PROP_TYPES } from '../../config'

export const DEFAULT_PROPS = {
  padding: 0
}

export const PROP_TYPES = {
  ...PARENT_PROP_TYPES,
  backgroundColor: PropTypes.string,
  disabled: PropTypes.bool,
  onImageUpload: PropTypes.func,
  padding: PropTypes.number
}
