
import { SocialBtn } from 'views/components'
import React from 'react'
import { compose, defaultProps, pure, setDisplayName, setPropTypes } from 'recompose'
import { DEFAULT_PROPS, PROP_TYPES } from './config'

const enhance = compose(
  setDisplayName('views/components/ListSocial'),
  setPropTypes(PROP_TYPES),
  defaultProps(DEFAULT_PROPS),
  pure
)

export default enhance(({
  items
}) =>
  <ul className='list-social'>
    {items.map((item, key) => {
      const Sbtn = <SocialBtn icon={item.icon} />

      return (
        <li key={key}>
          {item.href
            ? <a
              href={item.href}
              target={`_${item.targetBlank ? 'blank' : 'self'}`}
            >
              {Sbtn}
            </a>
            : Sbtn
          }
        </li>
      )
    })}
  </ul>
)
