import { typeGen } from 'helpers/redux'

const makeType = typeGen('shoppingCart')

export const GET_SHOPPING_CART_ITEMS = makeType('getShoppingCartItems')

export const INSERT_SHOPPING_CART_ITEM = makeType('InsertShoppingCartItem')

export const CLEAR_SHOPPING_CART_ITEMS = makeType('clearShoppingCartItems')

export const DELETE_SHOPPING_CART_ITEM = makeType('deleteShoppingCartItem')

export const UPDATE_SHOPPING_CART_ITEM = makeType('updateShoppingCartItem')

export const ADD_SHOPPING_CART_ITEM_DETAILS = makeType('addShoppingCartItemDetails')

export const GET_RELATED_PRODUCTS = makeType('getRelatedProducts')

export const ADD_SHOPPING_CART_ITEM = makeType('addShoppingCartItem')