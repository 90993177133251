
import { getUserData, initCardForm, initPersonalInfo } from 'helpers/user'
import { get } from 'lodash'
import { CHANGE_CUSTOMER_CARD, UPDATE_CLIENT_PROFILE } from 'redux/modules/user/constants'

export default ({
  asyncState,
  user,
  ...props
}) => ({
  ...props,
  cardFormInit: initCardForm(get(user, 'card')),
  changeCustomerCardStatus: get(asyncState, `statuses.${CHANGE_CUSTOMER_CARD}`),
  updateClientProfileStatus: get(asyncState, `statuses.${UPDATE_CLIENT_PROFILE}`),
  user: {
    avatar: get(user, 'avatar.src') || require('theme/images/default-user.jpg'),
    initial: initPersonalInfo(getUserData(user))
  }
})
