
import { methodFromProps } from 'helpers'
import { get } from 'lodash'

export default props => data => {
  const allCategoryAttributesByCategory = methodFromProps('allCategoryAttributesByCategory', props)
  const clearAttributesByCategory = methodFromProps('clearAttributesByCategory', props)
  const setProductsCategory = methodFromProps('setProductsCategory', props)

  if (data.value) {
    allCategoryAttributesByCategory({ categoryId: data.value })
  } else {
    clearAttributesByCategory()
  }
  setProductsCategory(get(data, 'value', null))
}
