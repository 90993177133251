
import { methodFromProps } from 'helpers'
import { get } from 'lodash'
import moment from 'moment'

export default props => {
  const fields = get(props, 'categoryAttrValues.fields', [])
  const addShoppingCartItem = methodFromProps('addShoppingCartItem', props)
  const productId = +get(props, 'match.params.product')
  const productAttrDetailIds = []

  Object.entries(fields).forEach(([key, value]) => {
    productAttrDetailIds.push(value.productDetailsId)
  })

  const form = new FormData()
  form.set('productId', productId)
  form.set('rentalStartDateUtc', moment(get(props, 'productMeta.date.from')).format('YYYY-MM-DD HH:mm'))
  form.set('rentalEndDateUtc', moment(get(props, 'productMeta.date.to')).format('YYYY-MM-DD HH:mm'))
  form.set('productAttrDetailIds', productAttrDetailIds)
  form.set('quantity', 1)

  addShoppingCartItem(form)
}
