
import { methodFromProps } from 'helpers'
import { get } from 'lodash'
import moment from 'moment'

export default props => (id) => {
  const insertShoppingCart = methodFromProps('insertShoppingCart', props)

  const form = new FormData()
  form.set('productId', id.id)
  form.set('rentalStartDateUtc', moment(get(props, 'productsAll.fromDate')).format('YYYY-MM-DD HH:mm'))
  form.set('rentalEndDateUtc', moment(get(props, 'productsAll.toDate')).format('YYYY-MM-DD HH:mm'))
  form.set('quantity', 1)

  insertShoppingCart(form)
  setTimeout(()=>{
      window.location.href = '/cart'
  }, 2000)
}