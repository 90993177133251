
import PropTypes from 'prop-types'

export const DEFAULT_PROPS = {
  fields: [],
  hideControl: false,
  initOpened: false,
  inReduxForm: false
}

const optionType = PropTypes.shape({
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ])
})

export const PROP_TYPES = {
  fields: PropTypes.arrayOf(PropTypes.shape({
    checkboxes: PropTypes.arrayOf(PropTypes.shape({
      checked: PropTypes.bool,
      label: PropTypes.string,
      name: PropTypes.string
    })),
    error: PropTypes.string,
    name: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(optionType),
    placeholder: PropTypes.string,
    tip: PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func
    }),
    units: PropTypes.shape({
      name: PropTypes.string,
      options: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([
          PropTypes.number,
          PropTypes.string
        ]).isRequired
      })),
      placeholder: PropTypes.string
    }),
    value: PropTypes.oneOfType([
      optionType
    ])
  })),
  heading: PropTypes.string,
  hideControl: PropTypes.bool,
  initOpened: PropTypes.bool,
  inReduxForm: PropTypes.bool,
  onChange: PropTypes.func
}
