
import { CardForm, UserProfile } from 'views/blocks/forms'
import { Modal } from 'views/components/modals'
import { ASYNC_STATUSES } from 'data/constants'
import { dataLoad } from 'helpers/redux'
import { get } from 'lodash'
import { InnerPage } from 'views/pages'
import React, { PureComponent } from 'react'
import ReactResizeDetector from 'react-resize-detector'
import { When } from 'react-if'
import { connect } from 'react-redux'
import { Elements } from 'react-stripe-elements'
import { compose, mapProps, setDisplayName, withHandlers, withState } from 'recompose'
import { ACTIONS } from './config'
import { clickSubmitCardForm, dataLoader, handleUpdateAvatar, handleUpdateCard, mapState, propsMapper, submitCardForm, submitUserProfile } from './lib'
import { FormattedMessage } from 'react-intl'

const enhance = compose(
  setDisplayName('views/routes/user/Page'),
  dataLoad(dataLoader),
  connect(mapState, ACTIONS),
  withState('cardModal', 'showCardModal', false),
  withState('updateStatus', 'showUpdateStatus', false),
  mapProps(propsMapper),
  withHandlers({
    clickSubmitCardForm,
    handleUpdateAvatar,
    handleUpdateCard,
    submitCardForm,
    submitUserProfile
  })
)

class Page extends PureComponent {
  _updateUserTimer = null

  componentDidUpdate (prev) {
    const {
      changeCustomerCardStatus,
      showCardModal,
      showUpdateStatus,
      updateClientProfileStatus
    } = this.props

    if (prev.changeCustomerCardStatus === ASYNC_STATUSES.PENDING && changeCustomerCardStatus === ASYNC_STATUSES.SUCCESS) {
      showCardModal(false)
    }

    if (prev.updateClientProfileStatus === ASYNC_STATUSES.PENDING && updateClientProfileStatus === ASYNC_STATUSES.SUCCESS) {
      showUpdateStatus(true)
      setTimeout(() => {
        showUpdateStatus(false)
      }, 3000)
    }
  }

  render () {
    const {
      cardFormInit,
      cardModal,
      changeCustomerCardStatus,
      clickSubmitCardForm,
      handleUpdateAvatar,
      handleUpdateCard,
      submitCardForm,
      submitUserProfile,
      updateClientProfileStatus,
      updateStatus,
      user
    } = this.props

    return (
      <InnerPage
        withSearch
        className='userProfilePage'
        user={user}
      >
        <When condition={updateStatus}>
          <span className='text-success userProfilePage_status'>
            <FormattedMessage id="UserPage.Content.SuccessUpdate"/>
          </span>
        </When>
        <h1 className='hd-page'>
          <FormattedMessage id="UserPage.Content.Title"/>
        </h1>
        <UserProfile
          enableReinitialize
          avatar={get(user, 'avatar')}
          form='views/routes/_lib/blocks/Page--UserProfile'
          initialValues={get(user, 'initial', {})}
          onSubmit={submitUserProfile}
          status={updateClientProfileStatus}
          updateAvatar={handleUpdateAvatar}
          updateCard={handleUpdateCard}
        />
        <When condition={cardModal}>
          <ReactResizeDetector handleWidth>
            {({ width }) => (
              <Modal
                fullWidth={width < 576}
                onClose={handleUpdateCard}
              >
                <h2 className='userProfilePage_updateCard_hd'>
                  <FormattedMessage id="UserProfile.Header.UpdateCard"/>
                </h2>
                <Elements>
                  <CardForm
                    enableReinitialize
                    isWide
                    clickSubmit={clickSubmitCardForm}
                    form='views/routes/user/Page--updateCard'
                    initialValues={cardFormInit}
                    onSubmit={submitCardForm}
                    status={changeCustomerCardStatus}
                  />
                </Elements>
              </Modal>
            )}
          </ReactResizeDetector>
        </When>
      </InnerPage>
    )
  }

  componentWillUnmount () {
    clearTimeout(this._updateUserTimer)
  }
}

export default enhance(Page)
