
import PropTypes from 'prop-types'

export const DEFAULT_PROPS = {
  visible: true
}

export const PROP_TYPES = {
  children: PropTypes.any,
  clickCurtain: PropTypes.func,
  fullWidth: PropTypes.bool,
  transparent: PropTypes.bool,
  visible: PropTypes.bool
}
