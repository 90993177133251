
import React from 'react'
import { compose, pure, setDisplayName, setPropTypes } from 'recompose'
import { PROP_TYPES } from './config'

const enhance = compose(
  setDisplayName('views/components/ImageRound'),
  setPropTypes(PROP_TYPES),
  pure
)

export default enhance(({
  borderColor,
  size,
  src
}) =>
  <span
    className='imageRound'
    style={{
      backgroundImage: `url(${src})`,
      borderColor,
      height: size,
      width: size,
    }}
  />
)
