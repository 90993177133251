
import { random } from 'lodash'
import React from 'react'
import { FormattedMessage } from 'react-intl';

export const ALIGN = {
  BOTTOM: 'bottom',
  CENTER: 'center',
  LEFT: 'left',
  RIGHT: 'right',
  TOP: 'top'
}

export const API_DOMAINS = {
  NOTIFICATIONS: `${process.env.REACT_APP_NOTIFICATIONS_API}`,
  STRIPE: `${process.env.REACT_APP_STRIPE_API}`,
  USER: `${process.env.REACT_APP_USER_API}`,
  CATALOG: `${process.env.REACT_APP_CATALOG_API}`,
  ORDER: `${process.env.REACT_APP_ORDER_API}`,
  BASKET: `${process.env.REACT_APP_BASKET_API}`,
  AUTH: `${process.env.REACT_APP_AUTH_PATH}`,
  STORAGE: `${process.env.REACT_APP_STORAGE_URL}`
}

export const ASYNC_STATUSES = {
  ERROR: 'error',
  ONSUCCESS: 'onSuccess',
  PENDING: 'pending',
  SUCCESS: 'success'
}

export const AUTH_REDIRECTS = {
  IN: '/'
}

export const BUTTON_TYPES = {
  BUTTON: 'button',
  RESET: 'reset',
  SUBMIT: 'submit'
}

export const DATE_FORMAT = 'YYYY/MM/DD'
export const FULL_DATE_FORMAT = 'YYYY-MM-DD HH:mm'

export const FALSIES = (function () {
  const fValues = [
    '',
    null,
    undefined
  ]
  return {
    getRandom: () => fValues[random(0, fValues.length - 1)]
  }
})()

export const FORM_ERRORS = {
  INCORRECT_VALUE: 'Incorrect value',
  MATCH_NEW_PASSWORD: 'Must match new password',
  REQUIRED: '*Required',
  SELECT_FROM_LIST: 'Select from list',
  SPECIAL_CHARS: 'Must contain special characters'
}

export const NOT_FOUND = {
  DEFAULT: <FormattedMessage id="NotFound.Default"/>
}

export const NOTIFICATIONS = {
  PAGE_SIZE: 6
}

export const POINT_ITEMS = [
  {
    description: <FormattedMessage id="PointItems.ChooseShopsAround.Description"/>,
    image: {
      alt: '',
      src: require('theme/images/checkmark-outlined-verification-sign.svg')
    }
  },
  {
    description: <FormattedMessage id="PointItems.ComparePriceAndProducts.Description"/>,
    image: {
      alt: '',
      src: require('theme/images/compare.svg')
    }
  },
  {
    description: <FormattedMessage id="PointItems.NoMoreQueueing.Description"/>,
    image: {
      alt: '',
      src: require('theme/images/delivery-truck.svg')
    }
  }
]

export const SEARCH_PRODUCTS_DEFAULTS = {
  FROM_DATE: '',
  PAGE: 1,
  PAGE_SIZE: 2,
  TO_DATE: ''
}

export const ORDERS_PAGE_DEFAULTS = {
  PAGE: 1,
  PAGE_SIZE: 5,
}


export const SEARCH_PRODUCTS_SORT_BY = {
  HIGHER_PRICE: 'ByHigherPrice',
  LOWER_PRICE: 'ByLowerPrice',
  NONE: 'None',
}

export const SPECIAL_CHARS = /\W|_/g

export const ATTRIBUTE_CONTROL_TYPE = {
  DROPDOWN_LIST : 1,
  RADIO_LIST : 2,
  CHECK_BOX : 3,
  TEXT_BOX : 4,
  MULTILINE_TEXTBOX : 10,
  DATEPICKER : 20,
  FILE_UPLOAD : 30,
  COLOR_SQUARES : 40,
  IMAGE_SQUARES : 45,
  READONLY_CHECKBOXES : 50
}

export const PAYMENT_STATUS = {
  CANCELED : 0,
  ACTIVE : 1,
  COMPLETED : 2,
  NOT_STARTED : 3
}
