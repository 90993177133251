
import { Card, CartSummary, Clearer, DeliverTo, Dropdown, DropdownToggler, ImageRound, InnerBanner, LookingIn, MainOffer, ManageResults, Notifications, Paginator, Point, ProductInCart, ProductMeta, ProductSec, Ranger, Rollet, RolletScrolled, Service, SocialBtn, Sorter, Status, Tip } from 'views/components'
import { WithCamera } from 'views/components/ImageRound/family'
import { ALIGN } from 'data/constants'
import faker from 'faker'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { compose, pure, setDisplayName, setPropTypes } from 'recompose'

const enhance = compose(
  setDisplayName('views/routes/_lib/components/Blocks'),
  setPropTypes({
    heading: PropTypes.string
  }),
  pure
)

const ClearerChild = props => (
  <div>
    ClearerChild
  </div>
)
const DropdownChild = props => (
  <div>
    DropdownChild
  </div>
)
const DropdownTogglerCh = props => (
  <div>
    DropdownTogglerCh
  </div>
)

export default enhance(({
  heading
}) =>
  <div>
    <h2 style={{ marginTop: 20 }}>
      {heading}
    </h2>

    <p>
      components/Card
    </p>
    <Card
      date='11/11/2011'
      message='Lorem ipsum dolor sit amet'
      time='12:30:45'
    />

    <p>
      components/CartSummary
    </p>
    <CartSummary
      clearCart={() => { console.log('clearCart') }}
      users={1}
      total={150}
    />

    <p>
      components/Clearer
    </p>
    <Clearer
      children={
        <ClearerChild />
      }
      onClear={() => { console.log('Clearrrr!!!') }}
    />

    <p>
      components/DeliverTo
    </p>
    <DeliverTo address='Bangor, Gwynedd' />

    <p>
      components/Dropdown
    </p>
    <Dropdown
      children={<DropdownChild />}
      toggler={<DropdownTogglerCh />}
    />
    <br />
    <Dropdown
      children={<DropdownChild />}
      childrenPosition={{
        right: 0
      }}
      toggler={<DropdownTogglerCh />}
    />

    <p>
      components/DropdownToggler
    </p>
    <DropdownToggler>
      <p>
        DropdownToggler closed
      </p>
    </DropdownToggler>
    <DropdownToggler dropdown={{ opened: true }}>
      <p>
        DropdownToggler opened
      </p>
    </DropdownToggler>

    <p>
      components/ImageRound
    </p>
    <ImageRound
      size={100}
      src={require('theme/images/grupo-ski.jpg')}
    />
    <p>
      components/ImageRound/family/WithCamera
    </p>
    <WithCamera
      backgroundColor='#0f0'
      padding={8}
      size={144}
      src={require('theme/images/grupo-ski.jpg')}
    />

    <p>
      components/InnerBanner
    </p>
    <InnerBanner
      bannerCnt={
        <div>
          Banner content
        </div>
      }
      title='Banner title'
    />
    <InnerBanner title='Banner title' />

    <p>
      components/MainOffer
    </p>
    <div style={{
      background: 'blue',
      padding: 20
    }}>
      <MainOffer
        description='Delivered Where You Need'
        offer='Ski Hire'
        options={[
          {
            emphasized: true,
            label: 'Book Your Favorite Ski'
          },
          { label: 'Delivery Service' }
        ]}
      />
    </div>

    <p>
      components/ManageResults
    </p>
    <ManageResults
      filters={1}
      results={12}
      submitSearch={values => { console.log(values) }}
    />

    <p>
      components/Notifications
    </p>
    <Notifications quantity={0} />
    <Notifications quantity={faker.random.number()} />
    <Notifications />

    <p>
      components/Paginator
    </p>
    <Paginator
      clickPage={page => { console.log(page) }}
      selected={20}
      totalPages={100}
    />

    <p>
      components/Point
    </p>
    <Point
      description='description'
      image={{
        alt: 'alt',
        src: require('theme/images/delivery-truck.svg')
      }}
    />

    <p>
      components/ProductInCart
    </p>
    <ProductInCart
      description='Description'
      image={{
        alt: '',
        src: require('theme/images/boot.jpg')
      }}
      price={1}
      title='Product title'
    />

    <p>
      components/ProductMeta
    </p>
    <ProductMeta
      categories={[
        { label: '1', value: 1 },
        { label: '2', value: 2 },
        { label: '3', value: 3 }
      ]}
      categoryIdx={1}
      date={{
        from: moment().toDate(),
        to: moment().add(1, 'd').toDate()
      }}
      deliveryAddress={{
        label: 'Street, House, City, State'
      }}
      submitForm={values => { console.log(values) }}
    />

    <p>
      components/ProductSec
    </p>
    <ProductSec
      image={{
        alt: '',
        src: require('theme/images/product-1.jpg')
      }}
      name='Product Name'
    />

    <p>
      components/Rollet
    </p>
    <Rollet
      control
      opened
      children={
        <div>
          Rollet children
        </div>
      }
      title='Rollet'
    />

    <p>
      components/RolletScrolled
    </p>
    <RolletScrolled
      title='Scrolled'
    >
      <Clearer
        children={
          <ClearerChild />
        }
        onClear={() => { console.log('Clearrrr!!!') }}
      />
    </RolletScrolled>
    <RolletScrolled
      children={
        <LookingIn
          clickPlace={() => { console.log('Place clicked') }}
          where='Aachen, Germany'
        />
      }
      title='views/components/LookingIn'
    />
    <RolletScrolled
      children={
        <Clearer
          children={
            <Ranger
              limits={{
                bottom: 50,
                top: 200
              }}
              onChange={values => { console.log(values) }}
              units='cm'
              values={{
                bottom: 70,
                top: 180
              }}
            />
          }
          onClear={() => { console.log('Clearrrr!!!') }}
        />
      }
      title='views/components/Ranger'
    />

    <p>
      components/Service
    </p>
    <Service
      description='Lorem ipsum dolor sit amet'
      heading='Heading'
      image={{
        alt: '',
        path: require('theme/images/search.png')
      }}
      number={1}
    />

    <p>
      components/SocialBtn
    </p>
    <SocialBtn
      icon={require('react-icons-kit/ionicons/socialFacebook').socialFacebook}
    />

    <p>
      components/forms/Sorter
    </p>
    <Sorter
      onChange={ev => { console.log(ev) }}
      options={[
        { label: '1', value: 1 },
        { label: '2', value: 2 },
        { label: '3', value: 3 }
      ]}
      value={{ label: '2', value: 2 }}
    />

    <p>
      components/Status
    </p>
    <Status />
    <Status message='Message' />
    <Status message='Message' position={ALIGN.TOP} />

    <p>
      components/Tip
    </p>
    <div>
      <Tip
        inline
        children='Hover on me to see a tip'
        text='Tip text'
        tipTextStyle={{
          top: '-2em'
        }}
      />
    </div>
  </div>
)
