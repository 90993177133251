
import PropTypes from 'prop-types'

export const DEFAULT_PROPS = {
  backgroundColor: '#fff'
}

export const PROP_TYPES = {
  backgroundColor: PropTypes.string,
  children: PropTypes.any,
  className: PropTypes.string,
  control: PropTypes.bool,
  opened: PropTypes.bool,
  title: PropTypes.string.isRequired
}
