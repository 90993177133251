import { handleActions } from 'redux-actions'
import { success, error } from 'helpers/redux/actionStateTypes'
import * as cx from './constants'
import { get } from 'lodash'

const initialState = {
  languages: [],
  error: {}
}

export default handleActions({
  [success(cx.GET_AVAIBLE_LANGUAGES)]: (state, action) => ({
    ...state,
    languages: get(action, 'payload', [])
  }),
  [error(cx.GET_AVAIBLE_LANGUAGES)]: (state, action) => ({
    ...state,
    error: get(action, 'payload', [])
  })
}, initialState)