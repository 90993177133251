import { FormattedMessage } from 'react-intl'
import React from 'react'
import { compose, pure, setDisplayName, setPropTypes, withHandlers } from 'recompose'
import { PROP_TYPES } from './config'
import { handleWhereClick } from './lib'

const enhance = compose(
  setDisplayName('views/components/LookingIn'),
  setPropTypes(PROP_TYPES),
  withHandlers({
    handleWhereClick
  }),
  pure
)

export default enhance(({
  handleWhereClick,
  where
}) =>
  <div className='looking-in'>
    <FormattedMessage id="Components.LookingIn"/>
    <br />
    <span
      className='looking-in_place'
      onClick={handleWhereClick}
    >
      {where}
    </span>
  </div>
)
