
import { ALIGN } from 'data/constants'
import { values } from 'lodash'
import PropTypes from 'prop-types'

export const DEFAULT_PROPS = {
  align: ALIGN.LEFT,
  currency: localStorage.currency,
  value: 0
}

export const PROP_TYPES = {
  align: PropTypes.oneOf(values(ALIGN)),
  currency: PropTypes.string,
  value: PropTypes.number
}
