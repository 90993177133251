import { Rollet } from 'views/components'
import React from 'react'
import { compose, pure, setDisplayName, setPropTypes } from 'recompose'
import { PROP_TYPES} from './config'
import { FormattedMessage } from 'react-intl'


const enhance = compose(
  setDisplayName('views/components/OrderItem'),
  setPropTypes(PROP_TYPES),
  pure
)

export default enhance(({
    orders,
    details,
    currency
}) =>
    <section className='cartPage_cnt container'>
        {orders.map((order, key) => {
            return (
                <div> 
                    <h2><div className='text-success text-center mt-3 mb-3'><FormattedMessage id='OrderPage.OrderCreateSuccess'/></div></h2>
                    <Rollet
                        key={key}
                        opened={!key}
                        title={`Order Id - ${order.randomId}`}
                    >
                        <div><FormattedMessage id='OrderPage.ItemsInOrder'/> - {order.itemsCount}</div>
                        <div><FormattedMessage id='OrderPage.PaymentStatus'/> - {order.paymentStatusName === 'NotStarted' ? 'Not Started': order.paymentStatusName}</div>
                        <div><FormattedMessage id='OrderPage.DeliveryAddress'/> - {order.deliveryAddress === null ? 'Not Selected' : order.deliveryAddress.address1}</div>
                        <div><FormattedMessage id='OrderPage.ReturnAddress'/> - {order.returnAddress === null ? 'Not Selected' : order.returnAddress.address1}</div>
                        <h3><div><FormattedMessage id='OrderPage.Total'/> - {order.orderTotal} {currency}</div></h3>
                    </Rollet>
                    <Rollet
                        key={key}
                        opened={!key}
                        title={'Merchant'}
                    >
                        <div><FormattedMessage id='OrderPage.Merchant.FullName'/> - {order.merchantDetails.fullName}</div>
                        <div><FormattedMessage id='OrderPage.Merchant.Email'/> - {order.merchantDetails.email}</div>
                        <div><FormattedMessage id='OrderPage.Merchant.PhoneNumber'/> - {order.merchantDetails.phoneNumber}</div>
                        <div><FormattedMessage id='OrderPage.Merchant.ShopName'/> - {order.merchantDetails.shopName}</div>
                        <div><FormattedMessage id='OrderPage.Merchant.ShopAddress'/> - {order.merchantDetails.shopAddress}</div>
                    </Rollet>
                </div>
            )
        })}
        { Object.keys(details).length === 0 ? null : (
            <div>
                <Rollet
                    opened={true}
                    title='Order Info'
                >
                    <div><FormattedMessage id='OrderPage.DeliveryDate'/> - {details.orderInfo.deliveryDate}</div>
                    <div><FormattedMessage id='OrderPage.DeliveryAddress'/> - {details.orderInfo.deliveryAddress === null ? 'Not Selected' : details.orderInfo.deliveryAddress.address1}</div>
                    <div><FormattedMessage id='OrderPage.ReturnDate'/> - {details.orderInfo.returnDate}</div>
                    <div><FormattedMessage id='OrderPage.ReturnAddress'/> - {details.orderInfo.returnAddress === null ? 'Not Selected' : details.orderInfo.returnAddress.address1}</div>
                    <div><FormattedMessage id='OrderPage.ItemsInOrder'/> - {details.orderInfo.itemsCount}</div>
                    <div><FormattedMessage id='OrderPage.PaymentStatus'/> - {details.orderInfo.paymentStatusName === 'NotStarted' ? 'Not Started': details.orderInfo.paymentStatusName}</div>
                    <h3><div><FormattedMessage id='OrderPage.Total'/> - {details.orderInfo.orderTotal} {currency}</div></h3>
                </Rollet>
                <Rollet
                    opened={true}
                    title='Delivery Job'
                >
                    <div><FormattedMessage id='OrderPage.JobStatus'/> - {details.deliveryJob.jobStatus}</div>
                    <div><FormattedMessage id='OrderPage.DropAddress'/> - {details.deliveryJob.dropAddress === null ? 'Not Selected' : details.deliveryJob.dropAddress}</div>
                    <div><FormattedMessage id='OrderPage.PickUpAddress'/> - {details.deliveryJob.pickUpAddress === null ? 'Not Selected' : details.deliveryJob.pickUpAddress}</div>
                </Rollet>

                <Rollet
                    opened={true}
                    title='Return Job'
                >
                    <div><FormattedMessage id='OrderPage.JobStatus'/> - {details.returnJob.jobStatus}</div>
                    <div><FormattedMessage id='OrderPage.DropAddress'/> - {details.returnJob.dropAddress === null ? 'Not Selected' : details.returnJob.dropAddress }</div>
                    <div><FormattedMessage id='OrderPage.PickUpAddress'/> - {details.returnJob.pickUpAddress === null ? 'Not Selected' : details.returnJob.pickUpAddress}</div>
                </Rollet>
            </div>
        )}      
    </section>
)