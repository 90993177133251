
import React from 'react'
import { Route } from 'react-router-dom'
import { compose, pure, setDisplayName } from 'recompose'
import Page from './Page'

const enhance = compose(
  setDisplayName('views/routes/user/password/reset/Router'),
  pure
)

export default enhance(() =>
  <Route
    component={Page}
    path='/user/password/reset'
  />
)
