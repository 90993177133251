
import { ButtonLess } from 'views/components/buttons'
import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { compose, pure, setDisplayName, setPropTypes, withHandlers, } from 'recompose'
import { DatepickerIconed, DatepickerTo, GeoSuggestInputIconed, SelectIconed } from './components'
import { PROP_TYPES } from './config'
import { handleSelectLookingFor, validate } from './lib'
import { FormattedMessage } from 'react-intl'

const enhance = compose(
  setDisplayName('views/components/forms/SearchExtended'),
  setPropTypes(PROP_TYPES),
  reduxForm({
    form: 'views/component/forms/SearchExtended',
    validate
  }),
  withHandlers({
    handleSelectLookingFor
  }),
  pure
)

export default enhance(({
  handleSelectLookingFor,
  handleSubmit,
  lookFor,
  onSubmit
}) =>
  <form
    className='container f-search-extended'
    onSubmit={handleSubmit(onSubmit)}
  >
    <div className='row f-search-extended--no-mt'>
      <div className='col-12 col-md-4'>
        <Field
          component={GeoSuggestInputIconed}
          name='address'
          placeholder={<FormattedMessage id="Forms.Search.Placeholder"/>}
        />
      </div>
      <div className='col-12 col-sm-6 col-md-4 f-search-extended--mt'>
        <Field
          disableNonUsed
          component={DatepickerIconed}
          name='from'
        />
      </div>
      <div className='col-12 col-sm-6 col-md-4 f-search-extended--mt'>
        <Field
          disableNonUsed
          component={DatepickerTo}
          label={<FormattedMessage id="SearchExtended.Label.To"/>}
          minDate={null}
          name='to'
        />
      </div>
    </div>
    <div className='row'>
      <div className='col-12 col-lg-4'>
        <Field
          component={SelectIconed}
          name='lookingFor'
          onChange={handleSelectLookingFor}
          options={lookFor}
        />
      </div>
    </div>
    <div className='f-search-extended_button'>
      <ButtonLess type='submit'>
        <FormattedMessage id="SearchExtended.Buttons.Search"/>
      </ButtonLess>
    </div>
  </form>
)
