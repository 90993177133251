
import { get } from 'lodash'
import { SEARCH_PRODUCTS } from 'redux/modules/products/constants'
import { getDefaultLocation } from 'helpers/user'

export default props => ({
  ...props,
  search: {
    error: get(props, `asyncState.errors.${SEARCH_PRODUCTS}.message`),
    status: get(props, `asyncState.statuses.${SEARCH_PRODUCTS}`)
  },
  LocationModal: (getDefaultLocation('LocationCookie') === null || getDefaultLocation('LocationCookie') === "") ? true : false,
  StartDefaultDate: get(props,'StartDefaultDate',''),
  EndDefaultDate: get(props,'EndDefaultDate',''),
})
