
import { get } from 'lodash'

export default state => ({
  auth: get(state, 'auth'),
  notifications: get(state, 'notifications'),
  user: get(state, 'user'),
  shoppingCart: get(state, 'shoppingCart.shoppingCardItems', {}),
  lang: state.locale.lang,
  currencies: get(state, 'currency'),
  languages: get(state, 'languages')
})
