
import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { compose, pure, setDisplayName } from 'recompose'

const enhance = compose(
  setDisplayName('views/routes/user/password/Router'),
  pure
)

export default enhance(() =>
  <div>
    <Route
      exact
      path='/user/password'
      render={() => <Redirect to='/user/password/reset' />}
    />
    <Route
      component={require('views/routes/_r/user/_r/password/_r/reset').Router}
      path='/user/password/reset'
    />
  </div>
)
