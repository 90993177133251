
import { createUserManager } from 'redux-oidc'

const scopes = [
  'catalog_api',
  // 'custom.profile',
  'email',
  'openid',
  'order_api',
  'profile',
  'signalr_api',
  // 'stripe_api',
  'user_api',
  'basket_api',
  'storage_api'
]

export default createUserManager({
  authority: process.env.REACT_APP_AUTH_PATH,
  automaticSilentRenew: true,
  client_id: process.env.REACT_APP_CLIENT_ID,
  post_logout_redirect_uri: process.env.REACT_APP_CLIENT_PATH,
  redirect_uri: `${process.env.REACT_APP_CLIENT_PATH}/auth`,
  response_type: 'token id_token',
  scope: scopes.join(' '),
  silent_redirect_uri: `${process.env.REACT_APP_CLIENT_PATH}/auth/silent-renew`,
  acr_values: `lang:${localStorage.lang}`
})
