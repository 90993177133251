
import { Checkbox, ConfirmForm, FieldsSet, FileInput, GeoSuggestInput, Input, Search, Search2, Search3 } from 'views/components/forms'
import { iconed } from 'views/components/forms/hocs'
import faker from 'faker'
import PropTypes from 'prop-types'
import React from 'react'
import { compose, pure, setDisplayName, setPropTypes } from 'recompose'
import uniqid from 'uniqid'
import { Form } from './components'

const enhance = compose(
  setDisplayName('views/routes/_lib/components/Forms'),
  setPropTypes({
    title: PropTypes.string
  }),
  pure
)

export default enhance(({
  title
}) => {
  const IconedGeoInput = iconed({
    icon: {
      alt: '',
      src: require('theme/images/beginner.svg')
    }
  })(GeoSuggestInput)
  const IconedGeoInput2 = iconed({
    clearable: true,
    icon: {
      alt: '',
      src: require('theme/images/beginner.svg')
    }
  })(GeoSuggestInput)

  return (
    <div>
      <h2 style={{ marginTop: 20 }}>
        {title}
      </h2>
      <Form
        form='example-form'
        onSubmit={values => { console.log(values) }}
      />

      <p>
        components/forms/Checkbox
      </p>
      <Checkbox
        onChange={ev => { console.log(ev) }}
      />

      <p>
        components/forms/ConfirmForm
      </p>
      <ConfirmForm
        form={`components/forms/ConfirmForm==${uniqid()}`}
        onSubmit={values => { console.log(values) }}
        products={[
          {
            fields: [
              {
                name: 'field_1_1',
                placeholder: 'Field 1.1'
              },
              {
                name: 'field_1_2',
                placeholder: 'Field 1.2'
              }
            ],
            image: {
              alt: '',
              src: require('theme/images/skies.jpg')
            },
            name: 'Ski'
          },
          {
            fields: [
              {
                name: 'field_2_1',
                placeholder: 'Field 2.1'
              },
              {
                name: 'field_2_2',
                placeholder: 'Field 2.2'
              }
            ],
            image: {
              alt: '',
              src: require('theme/images/skies.jpg')
            },
            name: 'Ski 2'
          }
        ]}
      />

      <p>
        components/forms/FieldsSet
      </p>
      <FieldsSet
        initOpened
        fields={[
          {
            error: 'Error 1',
            name: 'field_1',
            placeholder: 'Field 1',
            tip: {
              label: 'Tip 1',
              onClick: () => { console.log('Click on Tip 1') }
            }
          },
          {
            error: 'Error 2',
            name: 'field_2',
            placeholder: 'Field 2',
            units: {
              name: 'field_2_units',
              options: [
                { label: '1', value: 1 },
                { label: '2', value: 2 },
                { label: '3', value: 3 }
              ],
              placeholder: 'kg'
            }
          },
          {
            error: 'Error 3',
            name: 'field_3',
            placeholder: 'Field 3',
            tip: {
              label: 'Tip 3',
              onClick: () => { console.log('Click on Tip 3') }
            },
            units: {
              name: 'field_3_units',
              options: [
                { label: 'Unit 1', value: 'unit_1' },
                { label: 'Unit 2', value: 'unit_2' },
                { label: 'Unit 3', value: 'unit_3' }
              ],
              placeholder: 'kg'
            },
            options: [
              { label: 'Value 1', value: 'value_1' },
              { label: 'Value 2', value: 'value_2' },
              { label: 'Value 3', value: 'value_3' }
            ]
          },
          {
            checkboxes: [
              { label: 'Chx 1', name: 'chx_1' },
              { label: 'Chx 2', name: 'chx_2' },
              { label: 'Chx 3', name: 'chx_3' }
            ],
            error: 'Error 4',
            tip: {
              label: 'Tip 4',
              onClick: () => { console.log('Click on Tip 4') }
            }
          }
        ]}
        heading='Fields Set Heading'
      />

      <p>
        components/forms/FileInput
      </p>
      <FileInput
        children={
          <img
            alt=''
            src={require('theme/images/boot.jpg')}
            width={500}
          />
        }
        input={{
          onChange: files => { console.log(files) }
        }}
        onChange={files => { console.log(files) }}
      />

      <p>
        components/forms/GeoSuggestInput
      </p>
      <div style={{ margin: '0 2em' }}>
        <GeoSuggestInput
          required
          label='Label'
          meta={{
            error: 'Error',
            touched: true
          }}
          placeholder='Placeholder'
        />
        <GeoSuggestInput
          required
          label='Label mod1'
          mod={1}
          placeholder='Placeholder mod1'
        />
        <IconedGeoInput
          input={{}}
          meta={{
            error: 'Error'
          }}
          placeholder='Geosuggest with an icon'
        />
        <IconedGeoInput2
          input={{}}
          meta={{
            error: 'Error'
          }}
          placeholder='Geosuggest with an icon and a clearfield'
        />
      </div>

      <p>
        components/forms/Input
      </p>
      <Input
        className='form-group'
        label='Test label'
        placeholder='Placeholder'
        tip='Tip'
      />
      <Input
        big
        borderRadius={0}
        className='form-group'
        label='Big input'
        meta={{
          error: 'Error',
          touched: true
        }}
        placeholder='Big input'
        tip='Tip'
      />
      <Input
        small
        borderRadius={0}
        className='form-group'
        label='Small input'
        placeholder='Small input'
        tip='Tip'
      />
      <Input
        required
        className='form-group'
        label='Input mod 1 required'
        mod={1}
        placeholder='Input mod 1 required'
      />

      <p>
        components/forms/Search
      </p>
      <Search
        form={faker.lorem.word()}
        onSubmit={values => { console.log(values) }}
        placeholder='Enter delivery address'
      />

      <p>
        components/forms/Search2
      </p>
      <div style={{
        background: '#00f',
        padding: 10
      }}>
        <Search2 placeholder='Search around me' />
      </div>

      <p>
        components/forms/Search3
      </p>
      <Search3
        form={`${uniqid()}`}
        onSubmit={values => { console.log(values) }}
      />
    </div>
  )
})
