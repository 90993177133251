
import classnames from 'classnames'
import { InnerBanner } from 'views/components'
import { Search2 } from 'views/components/forms'
import { Page } from 'views/pages'
import React from 'react'
import { compose, pure, setDisplayName, setPropTypes } from 'recompose'
import { PROP_TYPES } from './config'
import { FormattedMessage } from 'react-intl'

const enhance = compose(
  setDisplayName('pages/InnerPage'),
  setPropTypes(PROP_TYPES),
  pure
)

export default enhance(({
  children,
  className,
  title,
  user,
  withSearch
}) =>
  <Page user={user}>
    <InnerBanner
      bannerCnt={withSearch ? (
        <div className='inner-page_search'>
          <FormattedMessage id="Forms.Search2.Placeholder">
          {
              (placeholder) => 
              (
                <Search2 placeholder={placeholder} />
              )
          }
          </FormattedMessage>         
        </div>
      ) : null}
      title={title}
    />
    <section className={classnames('container inner-page__cnt', {
      [className]: !!className
    })}>
      {children}
    </section>
  </Page>
)
