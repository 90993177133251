import { createAction } from 'redux-actions'
import * as cx from './constants'

export const getShoppingItems = createAction(cx.GET_SHOPPING_CART_ITEMS)

export const insertShoppingCart = createAction(cx.INSERT_SHOPPING_CART_ITEM)

export const clearShoppingCart = createAction(cx.CLEAR_SHOPPING_CART_ITEMS)

export const deleteShoppingCartItem = createAction(cx.DELETE_SHOPPING_CART_ITEM)

export const updateShoppingCartItem = createAction(cx.UPDATE_SHOPPING_CART_ITEM)

export const addShoppingCartItemDetails = createAction(cx.ADD_SHOPPING_CART_ITEM_DETAILS)

export const getRelatedProducts = createAction(cx.GET_RELATED_PRODUCTS, productId => ({ productId }))

export const addShoppingCartItem = createAction(cx.ADD_SHOPPING_CART_ITEM)