
import { getUserProfile } from 'helpers/auth'
import { get, pick } from 'lodash'
import { GET_USER_NOTIFICATIONS_PAGE } from 'redux/modules/notifications/constants'

export default ({
  asyncState,
  auth,
  notifications,
  ...props
}) => ({
  getUserNotificationsPageStatus: get(asyncState, `statuses.${GET_USER_NOTIFICATIONS_PAGE}`, ''),
  notificationsList: get(notifications, 'notifications', []),
  userId: +get(getUserProfile(auth), 'id', 0),
  ...pick(notifications, ['pageIndex', 'totalPages']),
  ...props
})
