import { getAvaibleCurrencies } from 'redux/modules/currency/actions'
import { getAvaibleLanguages } from 'redux/modules/languages/actions'

export default props => {
  const loaders = [
    {
      action: getAvaibleCurrencies
    }
  ]

  loaders.push({
    action: getAvaibleLanguages
  })
  return loaders
}