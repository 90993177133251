
import { apiCall } from 'helpers'
import { makeLogic } from 'helpers/redux'
import * as cx from './constants'
import { API_DOMAINS } from 'data/constants'

const getProductData = makeLogic({
  calls: [
    {
      method: apiCall.get,
      path: `${API_DOMAINS.CATALOG}/GetProductCategoryAttributes`,
      payload: ({ payload }) => ({ params: payload })
    },
    {
      method: apiCall.get,
      path: `${API_DOMAINS.CATALOG}/GetProductById`,
      payload: ({ payload }) => ({ params: payload })
    },
    {
      method: apiCall.get,
      path: `${API_DOMAINS.CATALOG}/GetProductPicturesByProductId`,
      payload: ({ payload }) => ({ params: payload })
    }
  ],
  latest: true,
  processResult: res => res.map(r => r.data),
  type: cx.GET_PRODUCT_DATA
})

const getProductAttributeIdByValues = makeLogic({
  httpMethod: apiCall.get,
  latest: true,
  path: `${API_DOMAINS.CATALOG}/GetProductAttributeIdByValues`,
  payload: ({ payload }) => ({ params: payload }),
  processError: err => {
    return err
  },
  processResult: res => res.data,
  type: cx.GET_PRODUCT_ATTRIBUTE_ID_BY_VALUES
})

export default [
  getProductData,
  getProductAttributeIdByValues
]
