
import { Status } from 'views/components/forms/components'
import React from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { compose, mapProps, defaultProps, pure, setDisplayName, setPropTypes, withHandlers } from 'recompose'
import { DEFAULT_PROPS, PROP_TYPES } from './config'
import { handleBlur, handleOnInputChange, propsMapper, mapState } from './lib'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'

const enhance = compose(
  setDisplayName('views/components/forms/Datepicker'),
  setPropTypes(PROP_TYPES),
  defaultProps(DEFAULT_PROPS),
  connect(mapState),
  mapProps(propsMapper),
  withHandlers({
    handleBlur,
    handleOnInputChange
  }),
  reduxForm({
    form: 'views/component/forms/DatePicker',
  }),
  pure
)

export default enhance(({
  disableNonUsed,
  error,
  handleBlur,
  handleOnInputChange,
  input,
  inputValue,
  onInputChange,
  placeholder,
  showError,
  ...props
}) =>
  <div className='datepicker'>
    <DatePicker
      className='form-control'
      onChange={handleOnInputChange}
      placeholderText={placeholder}
      showDisabledMonthNavigation={disableNonUsed}
      value={inputValue}
      {...input}
      {...props}
      onBlur={handleBlur}
    />
    {showError ? (
      <Status
        error
        message={error}
      />
    ) : null}
  </div>
)
