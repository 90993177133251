
import PropTypes from 'prop-types'

export const DEFAULT_PROPS = {
  options: []
}

export const PROP_TYPES = {
  description: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  offer: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  options: PropTypes.arrayOf(PropTypes.shape({
    emphasized: PropTypes.bool,
    label: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.element
    ])
  }))
}
