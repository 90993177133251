
import { success } from 'helpers/redux/actionStateTypes'
import { combineActions, handleActions } from 'redux-actions'
import * as cx from './constants'

const initialState = {
  unreadQuantity: 0
}

const types = [
  success(cx.COUNT_UNREAD_NOTIFICATIONS),
  success(cx.GET_USER_NOTIFICATIONS_PAGE)
]

export default handleActions({
  [combineActions(...types)]: (state, action) => {
    return {
      ...state,
      ...action.payload
    }
  }
}, initialState)
