
import classnames from 'classnames'
import React from 'react'
import { compose, pure, setDisplayName, setPropTypes, withHandlers } from 'recompose'
import { PROP_TYPES } from './config'
import { handleChange } from './lib'

const enhance = compose(
  setDisplayName('views/components/forms/FileInput'),
  setPropTypes(PROP_TYPES),
  withHandlers({
    handleChange
  }),
  pure
)

export default enhance(({
  children,
  handleChange,
  ...props
}) =>
  <div className={classnames('fileInput', {
    fileInput_disabled: props.disabled
  })}>
    {children}
    <div className='fileInput_input'>
      <input
        className='fileInput_input_field'
        onChange={handleChange}
        type='file'
        {...props}
      />
    </div>
  </div>
)
