import { methodFromProps } from 'helpers'
import { isAuthorized } from 'helpers/auth'
import { userManager } from 'data'

export default props => () => {
    const authorized = isAuthorized(props.auth)
    if (!authorized) {
        const signinRedirect = methodFromProps('signinRedirect', userManager)
        signinRedirect.call(userManager, {
            state: { loggedInFromPath: '/cart' }
        })
    }
    else {
        props.history.push('/checkout');
    }
}
