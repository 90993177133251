
import React from 'react'
import { compose, pure, setDisplayName } from 'recompose'
import { processSilentRenew } from 'redux-oidc'

const enhance = compose(
  setDisplayName('views/routes/auth/silent-renew/Page'),
  pure
)

export default enhance(() => {
  processSilentRenew()
  return <div />
})
