
import { methodFromProps } from 'helpers'
import { get } from 'lodash'

export default props => data => () => {
  const fetchGoods = methodFromProps('fetchGoods', props)
  const state = props.store.getState()
  const merchants = get(state, 'products.merchants', [])

  merchants[data.itemKey] = {
    ...merchants[data.itemKey], selected: !data.selected
  }

  var merchantsIds = merchants.filter(x => x.selected === true).map(x => x.value)

  fetchGoods({
    formValues: get(props, 'searchFormValues'),
    selectedMerchants: merchantsIds,
    searchRadius: get(props, 'merchantRadius'),
  })
}