
import faker from 'faker'
import { Factory } from 'rosie'

export default Factory.define('CheckListItemFactory')
  .attr('checked', faker.random.boolean)
  .attr('icon', {
    alt: faker.lorem.word(),
    src: faker.lorem.word()
  })
  .attr('label', faker.lorem.word)
  .attr('name', faker.lorem.word)
  .attr('toggleCheck', () => jest.fn())
