
import { MainOffer } from 'views/blocks'
import { CardForm, HeadedForm, UserProfile } from 'views/blocks/forms'
import { ProductItem } from 'views/components'
import { Delivery } from 'views/components/forms'
import React from 'react'
import { compose, pure, setDisplayName } from 'recompose'
import uniqid from 'uniqid'

const enhance = compose(
  setDisplayName('views/routes/_lib/blocks/Page'),
  pure
)

export default enhance(() =>
  <div>
    <p>
      blocks/forms/CardForm
    </p>
    <CardForm
      isWide
      form='views/blocks/forms/CardForm'
      onSubmit={values => { console.log(values) }}
    />

    <p>
      blocks/forms/HeadedForm
    </p>
    <HeadedForm
      fields={[
        {
          name: 'oldPassword',
          placeholder: 'Old Password'
        },
        {
          name: 'newPassword',
          placeholder: 'New Password'
        },
        {
          name: 'confirmPassword',
          placeholder: 'Confirm Password'
        }
      ]}
      form='views/blocks/forms/HeadedForm'
      onSubmit={values => { console.log(values) }}
      title='HeadedForm title'
    />

    <p>
      blocks/forms/UserProfile
    </p>
    <UserProfile
      avatar={require('theme/images/grupo-ski.jpg')}
      form='views/routes/_lib/blocks/Page--UserProfile'
      onSubmit={values => { console.log(values) }}
    />

    <p>
      blocks/MainOffer
    </p>
    <MainOffer
      submitSearch={() => { console.log('submitSearch') }}
    />

    <p>
      components/forms/Delivery
    </p>
    <Delivery
      form={`components/forms/Delivery--${uniqid()}`}
      onSubmit={values => { console.log(values) }}
    />

    <p>
      components/ProductItem
    </p>
    <ProductItem
      describe={{
        for: 'Male',
        level: 'Intermediate',
        shop: 'Shop (3 km)'
      }}
      image={{
        alt: '',
        src: require('theme/images/skies.jpg')
      }}
      price={33}
      title='Burton Progression'
    />
  </div>
)
