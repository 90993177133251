
import { push } from 'connected-react-router'
import { apiCall } from 'helpers'
import { format } from 'helpers/dateTime'
import { makeLogic } from 'helpers/redux'
import { get } from 'lodash'
import * as cx from './constants'
import { API_DOMAINS } from 'data/constants'

const searchProducts = makeLogic({
  httpMethod: apiCall.get,
  latest: true,
  onSuccess: ({ dispatch }) => {
    dispatch(push('/search/products'))
  },
  path: `${API_DOMAINS.CATALOG}/SearchProducts`,
  payload: ({ payload }) => ({
    params: {
      CategoryIds: get(payload, 'category'),
      CustomerAddress: get(payload, 'location.label', ''),
      FromDate: format(payload.fromDate) || cx.SEARCH_PRODUCTS_DEFAULT_PARAMS.FROM_DATE,
      Page: payload.page || cx.SEARCH_PRODUCTS_DEFAULT_PARAMS.PAGE,
      PageSize: payload.pageSize || cx.SEARCH_PRODUCTS_DEFAULT_PARAMS.PAGE_SIZE,
      ProductAttributeValueIds: get(payload, 'productAttributeValueIds'),
      SortBy: payload.sortBy || cx.SEARCH_PRODUCTS_DEFAULT_PARAMS.SORT_BY,
      ToDate: format(payload.toDate) || cx.SEARCH_PRODUCTS_DEFAULT_PARAMS.TO_DATE,
      ProductSearchText: get(payload, 'searchText', null),
      MerchantIds: get(payload, 'merchantsIds'),
      MerchantDistance: get(payload, 'merchantDistance', null),
    }
  }),
  processResult: res => res.data,
  type: cx.SEARCH_PRODUCTS
})

export default [ searchProducts ]
