
import { ButtonPlus } from 'views/components/buttons'
import React from 'react'
import { Icon } from 'react-icons-kit'
import { checkmarkRound } from 'react-icons-kit/ionicons/checkmarkRound'
import { compose, defaultProps, pure, setDisplayName, setPropTypes, withHandlers } from 'recompose'
import { DEFAULT_PROPS, PROP_TYPES } from './config'
import { handleItemClick } from './lib'

const enhance = compose(
  setDisplayName('views/components/lists/PlusList'),
  setPropTypes(PROP_TYPES),
  defaultProps(DEFAULT_PROPS),
  withHandlers({
    handleItemClick
  }),
  pure
)

export default enhance(({
  handleItemClick,
  items
}) =>
  <ul className='plus-list'>
    {items.map((item, key) =>
      <li key={key}>
        <div className='plus-list_icon'>
          {item.chosen ? (
            <Icon
              icon={checkmarkRound}
              size={30}
              style={{ color: '#01a7e7' }}
            />
          ) : (
            <ButtonPlus
              onClick={handleItemClick(key)}
            />
          )}
        </div>
        {item.name}
      </li>
    )}
  </ul>
)
