
import { handleActions } from 'redux-actions'
import { mapDropStatus, mapSetStatus } from './lib'
import * as cx from '../constants'

const initialState = {
  errors: null,
  statuses: null
}

export default handleActions({
  [cx.DROP_STATUS]: mapDropStatus,
  [cx.SET_STATUS]: mapSetStatus
}, initialState)
