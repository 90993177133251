
import PropTypes from 'prop-types'

export const PROP_TYPES = {
  accept: PropTypes.string,
  children: PropTypes.any,
  disabled: PropTypes.bool,
  input: PropTypes.shape({
    onChange: PropTypes.func
  }),
  multiple: PropTypes.bool
}
