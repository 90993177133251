
import { getTokens } from 'helpers/auth'
import React, { PureComponent } from 'react'

const { signalR } = window

export default config => Cmp => {
  class SignalR extends PureComponent {
    constructor (props) {
      super(props)
      this.state = { connection: null }
      this.connectToHub = this.connectToHub.bind(this)
    }

    connectToHub (accessToken) {
      if (signalR) {
        this.setState({
          hubConnection: new signalR.HubConnectionBuilder()
            .withUrl('https://notifications.dev.deliquity.com/notificationHub', {
              accessTokenFactory: () => accessToken,
              transport: signalR.HttpTransportType.LongPolling
            })
            .build()
        }, () => {
          const { hubConnection } = this.state
          hubConnection
            .on('ReceiveUserNotification', (message, unc) => {
              this.props.setUnreadNotifications(unc)
            })
          hubConnection
            .start()
            .catch(err => { console.error(err) })
          hubConnection
            .onclose(async () => {
              await hubConnection.start()
            })
        })
      }
    }

    componentDidMount () {
      const { accessToken } = getTokens(this.props.auth)
      if (accessToken) {
        this.connectToHub(accessToken)
      }
    }

    componentDidUpdate (prev) {
      const { accessToken } = getTokens(this.props.auth)
      const prevAccessToken = getTokens(prev.auth).accessToken
      if (accessToken && (accessToken !== prevAccessToken)) {
        this.connectToHub(accessToken)
      }
    }

    render () {
      return (
        <Cmp
          {...this.props}
          {...this.state}
        />
      )
    }
  }
  return SignalR
}
