
import { Breadcrumbs } from 'views/components'
import { ConfirmForm } from 'views/components/forms'
import { ProductPage } from 'views/pages'
import React from 'react'
import { compose, mapProps, pure, setDisplayName, withHandlers } from 'recompose'
import uniqid from 'uniqid'
import { connect } from 'react-redux'
import { handleConfirmFormSubmit, propsMapper, mapState, dataLoader } from './lib'
import { ACTIONS } from './config'
import { dataLoad } from 'helpers/redux'

const enhance = compose(
  setDisplayName('views/routes/confirm/Page'),
  dataLoad(dataLoader),
  connect(mapState, ACTIONS),
  mapProps(propsMapper),
  withHandlers({
    handleConfirmFormSubmit
  }),
  pure
)

export default enhance(({
  breadcrumbs,
  cartQuantity,
  productMeta,
  products,
  otherProducts,
  customersName
}) =>
  <ProductPage
    cartQuantity={cartQuantity}
    className='pageConfirm'
    productMeta={productMeta}
  >
    <Breadcrumbs
      items={breadcrumbs}
    />
    <ConfirmForm
      form={`routes/confirm/Page-ConfirmForm-${uniqid()}`}
      products={products}
      otherProducts={otherProducts}
      customers={customersName}
    />     
  </ProductPage>
)
