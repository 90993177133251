
import { getCurrentRate } from 'redux/modules/currency/actions'
import PropTypes from 'prop-types'

export const PROP_TYPES = {
  currencies: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    onClick: PropTypes.func,
  }))
}

export const ACTIONS = {
  getCurrentRate
}