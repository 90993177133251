
import classnames from 'classnames'
import React from 'react'
import { compose, defaultProps, pure, setDisplayName, setPropTypes, withHandlers, withState } from 'recompose'
import { DEFAULT_PROPS, PROP_TYPES } from './config'
import { toggleHovered } from './lib'

const enhance = compose(
  setDisplayName('views/components/buttons/Button'),
  setPropTypes(PROP_TYPES),
  defaultProps(DEFAULT_PROPS),
  withState('hovered', 'hover', props => !!props.isDark),
  withHandlers({
    toggleHovered
  }),
  pure
)

export default enhance(({
  hovered,
  label,
  onClick,
  toggleHovered,
  type,
}) =>
  <button
    className={classnames('button', {
      'button__dark': hovered
    })}
    onClick={onClick}
    onMouseOut={toggleHovered}
    onMouseOver={toggleHovered}
    type={type}
  >
    {label}
  </button>
)
