
import classnames from 'classnames'
import { Status } from 'views/components'
import { ButtonLess } from 'views/components/buttons'
import { Input } from 'views/components/forms'
import React from 'react'
import { Else, If, Then, When } from 'react-if'
import { connect } from 'react-redux'
import { injectStripe } from 'react-stripe-elements'
import { Field, reduxForm } from 'redux-form'
import { compose, mapProps, pure, setDisplayName, setPropTypes, withHandlers, withState } from 'recompose'
import { FIELDS, PROP_TYPES } from './config'
import { handleFormSubmit, handleResetForm, mapState, propsMapper, validate } from './lib'

const enhance = compose(
  setDisplayName('views/blocks/forms/CardForm'),
  setPropTypes(PROP_TYPES),
  injectStripe,
  connect(mapState),
  reduxForm({
    form: 'views/component/forms/CardForm',
    validate
  }),
  withState('reactStripeEls', 'showReactStripeEls', true),
  mapProps(propsMapper),
  withHandlers({
    handleFormSubmit,
    handleResetForm
  }),
  pure
)

export default enhance(({
  handleFormSubmit,
  handleResetForm,
  handleSubmit,
  isPending,
  isWide,
  onSubmit,
  reactStripeEls,
  ...props
}) =>
  <form
    className='cardForm'
    onSubmit={handleSubmit(handleFormSubmit)}
  >
    <div className='row'>
      {FIELDS(props).map((field, key) => {
        const fieldCmp = (
          <Field
            component={Input}
            {...field}
          />
        )
        const isCol6 = key === 1 || key === 2 || key === 7 || key === 8
        const isFullWidth = !isCol6 && key !== 2

        return (
          <div
            className={classnames('cardForm_field', {
              // eslint-disable-next-line
              ['col-sm-3']: (key === 1 || key === 2) && isWide,
              // eslint-disable-next-line
              ['col-6']: isCol6,
              // eslint-disable-next-line
              ['col-sm-6']: key === 0 && isWide,
              // eslint-disable-next-line
              ['col-12']: isFullWidth
            })}
            key={key}
          >
            <If condition={!!field.reactStripe}>
              <Then>
                <When condition={reactStripeEls}>
                  {fieldCmp}
                </When>
              </Then>
              <Else>
                {fieldCmp}
              </Else>
            </If>
          </div>
        )
      })}
    </div>
    <div className='cardForm_bottom'>
      <ButtonLess
        children='Submit'
        disabled={isPending}
        type='submit'
      />
      <ButtonLess
        backgroundColor='#444443'
        children='Reset'
        color='#fff'
        disabled={isPending}
        onClick={handleResetForm}
      />
      <When condition={isPending}>
        <Status
          className='cardForm_status'
          size={40}
        />
      </When>
    </div>
  </form>
)
