
import { omit } from 'lodash'
import { PROP_TYPES as PAGE_PROP_TYPES } from 'views/pages/Page/config'
import PropTypes from 'prop-types'

export const PROP_TYPES = {
  children: PropTypes.any,
  className: PropTypes.string,
  title: PropTypes.string,
  withSearch: PropTypes.bool,
  ...omit(PAGE_PROP_TYPES, ['children', 'className'])
}
