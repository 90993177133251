import classnames from 'classnames';
import { Status } from 'views/components/forms/components';
import { get, omit } from 'lodash';
import React, { PureComponent, createRef } from 'react';
import { When } from 'react-if';
import { compose, defaultProps, mapProps, setDisplayName, setPropTypes, withHandlers } from 'recompose';
import uniqid from 'uniqid';
import { DEFAULT_PROPS, PROP_TYPES } from './config';
import { handleSuggestSelect, propsMapper } from './lib';
import { addressByLatLong } from 'helpers/geo';
import Geosuggest from '@ubilabs/react-geosuggest';

const enhance = compose(
  setDisplayName('views/components/forms/GeoSuggestInput'),
  setPropTypes(PROP_TYPES),
  defaultProps(DEFAULT_PROPS),
  mapProps(propsMapper),
  withHandlers({
    handleSuggestSelect,
  })
);

class GeoSuggestInput extends PureComponent {
  _gs = createRef();

  handleStatusClick = () => {
    this._gs.current.focus();
  };

  componentDidUpdate(prevProps) {
    const prevValue = get(prevProps, 'input.value');
    const thisValue = get(this.props, 'input.value');
    if (prevValue !== thisValue && !thisValue) {
      this._gs.current.clear();
    }
  }

  render() {
    const {
      borderRadius,
      error,
      handleSuggestSelect,
      input,
      inputClassName,
      label,
      mod,
      noBorder,
      required,
      touched,
      ...props
    } = this.props;
    const style = { input: {} };
    const uid = uniqid();
    addressByLatLong();

    if (borderRadius) {
      style.input.borderRadius = borderRadius;
    }
    if (noBorder) {
      style.input.border = '0 none';
    }

    return (
      <div className={classnames('geo-suggest-input', {
        [`mod${mod}`]: !!mod,
      })}>
        <When condition={!!label}>
          <label htmlFor={uid}>
            {label}
            <When condition={required}>
              <span className='input-text_required'>*</span>
            </When>
          </label>
        </When>
        <Geosuggest
          id={uid}
          inputClassName={classnames('form-control', {
            [inputClassName]: !!inputClassName,
          })}
          onSuggestSelect={handleSuggestSelect}
          style={style}
          {...omit(input, ['onBlur'])}
          {...omit(props, ['meta'])}
          ref={this._gs}
        />
        {error && touched ? (
          <Status error message={error} onClick={this.handleStatusClick} />
        ) : null}
      </div>
    );
  }
}

export default enhance(GeoSuggestInput);