
import { get } from 'lodash'
import React from 'react'
import { compose, pure, setDisplayName, setPropTypes } from 'recompose'
import { PROP_TYPES } from './config'

const enhance = compose(
  setDisplayName('views/components/Service'),
  setPropTypes(PROP_TYPES),
  pure
)

export default enhance(({
  description,
  heading,
  image,
  number
}) =>
  <div className='service'>
    <div className='service-circle'>
      <span className='service-number'>
        {number}
      </span>
      <img
        alt={get(image, 'alt', '')}
        className='service-image'
        src={get(image, 'path')}
      />
    </div>
    <p className='service-heading'>
      {heading}
    </p>
    <p className='service-description'>
      {description}
    </p>
  </div>
)
