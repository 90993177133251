
import { methodFromProps } from 'helpers'
import { get } from 'lodash'
import moment from 'moment'
import { SEARCH_PRODUCTS_DEFAULT_PARAMS } from 'redux/modules/products/constants'

export default props => data => {
  const fetchGoods = methodFromProps('fetchGoods', props)
  const productMeta = get(props, 'productMeta')
  fetchGoods({
    attributes: get(props, 'categoryAttributes.allByCategory', []),
    formValues: {
      address: get(productMeta, 'deliveryAddress'),
      from: moment(get(productMeta, 'date.from')),
      lookingFor: get(productMeta, `categories.[${get(productMeta, 'categoryIdx')}]`),
      page: props.pageIndex,
      pageSize: props.pageSize,
      to: moment(get(productMeta, 'date.to')),
      searchText: get(props, 'searchInResultsTerm', null)
    },
    sortBy: get(data, 'value') || SEARCH_PRODUCTS_DEFAULT_PARAMS.SORT_BY,
    selectedMerchants: get(props, 'merchantsState', []).filter(x => x.selected === true).map(x => x.value),
    searchRadius: get(props, 'merchantRadius'),
  })
}
