import { createOrdersFromShoppingCart } from 'redux/modules/orders/actions'
import { getProductSettings } from 'redux/modules/productSettings/actions'
import { addShoppingCartItemDetails, updateShoppingCartItem } from 'redux/modules/shoppingCart/actions'

  export const ACTIONS = {
    createOrdersFromShoppingCart,
    getProductSettings,
    addShoppingCartItemDetails,
    updateShoppingCartItem
  }
  