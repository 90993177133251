
import PropTypes from 'prop-types'

export const DEFAULT_PROPS = {
  clearText: 'Clear'
}

export const PROP_TYPES = {
  children: PropTypes.any,
  clearText: PropTypes.string,
  onClear: PropTypes.func
}
