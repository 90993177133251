import { apiCall } from 'helpers'
import { makeLogic } from 'helpers/redux'
import * as cx from './constants'
import { API_DOMAINS } from 'data/constants'

const getAvaibleCurrencies = makeLogic({
    httpMethod: apiCall.get,
    latest: true,
    path: `${API_DOMAINS.CATALOG}/GetAvaibleCurrencies`,
    processError: err => {
      return err
    },
    processResult: res => res.data,
    type: cx.GET_AVAIBLE_CURRENCIES
  })

  const getCurrentRate = makeLogic({
    httpMethod: apiCall.get,
    latest: true,
    path: `${API_DOMAINS.CATALOG}/GetCurrentRate`,
    payload: ({ payload }) => ({ params: payload }),
    processError: err => {
      return err
    },
    processResult: res => res.data,
    type: cx.GET_CURRENT_RATE
  })

  export default [ 
    getAvaibleCurrencies,
    getCurrentRate
  ]