
import { Checkbox, Datepicker, Select } from 'views/components/forms'
import { iconed } from 'views/components/forms/hocs'
import faker from 'faker'
import { random, range } from 'lodash'
import React from 'react'
import { compose, pure, setDisplayName, setPropTypes } from 'recompose'
import { reduxForm, Field } from 'redux-form'
import { PROP_TYPES } from './config'

const enhance = compose(
  setDisplayName('views/routes/_lib/Forms/Form'),
  setPropTypes(PROP_TYPES),
  reduxForm({
    form: 'views/component/Forms/Form',
    validate: values => {
      const errors = {}
      if (!values.date) {
        errors.date = 'When?'
      }
      if (!values.dateIconed) {
        errors.dateIconed = 'When?'
      }
      if (!values.select) {
        errors.select = 'Error'
      }
      return errors
    }
  }),
  pure
)
const DatepickerIconed = iconed({
  clearable: true,
  icon: {
    alt: '',
    src: require('theme/images/beginner.svg')
  },
  label: 'Iconed datepicker'
})(Datepicker)

export default enhance(({
  handleSubmit,
  onSubmit
}) =>
  <form
    onSubmit={handleSubmit(onSubmit)}
    style={{ padding: '0 2em' }}
  >
    <Field
      component={Checkbox}
      label='Checkbox in the form'
      name='checkbox'
    />
    <Field
      disableNonUsed
      component={Datepicker}
      dateFormat='DD/MM/YYYY'
      name='date'
    />
    <Field
      disableNonUsed
      timeOnly
      component={Datepicker}
      name='time'
      placeholder='Select time'
    />
    <Field
      disableNonUsed
      component={DatepickerIconed}
      dateFormat='DD/MM/YYYY'
      name='dateIconed'
    />
    <Field
      component={Select}
      name='select'
      options={range(1, random(5, 9)).map(() => {
        const word = faker.lorem.word()
        return {
          label: word,
          value: word
        }
      })}
      placeholder='Select'
    />
    <Field
      component={Select}
      modify='1'
      name='selectMod1'
      options={range(1, random(5, 9)).map(() => {
        const word = faker.lorem.word()
        return {
          label: word,
          value: word
        }
      })}
      placeholder='Select Modify 1'
    />
    <button type='submit'>
      Submit
    </button>
  </form>
)
