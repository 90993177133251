import { getAllCategories } from 'redux/modules/categories/actions'
import { searchProducts } from 'redux/modules/products/actions'
import moment from 'moment'
import queryString from 'query-string'
export default props => {
  var params = queryString.parse(props.location.search)
  const loaders = [{
    action: getAllCategories,
  }]
  if (Object.keys(params).length !== 0) {
    loaders.push({
      action: searchProducts,
      payload: {
        fromDate: moment(params.fromDate),
        toDate: moment(params.toDate),
        location: {
          label: params.location
        },
        category: params.category,
        page: params.page,
        pageSize: params.pageSize,
        sortBy: params.sortBy,
        productAttributeValueIds: params.productAttributeValueIds
      }
    })
  }
  return loaders
}