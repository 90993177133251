
import { API_DOMAINS, NOTIFICATIONS } from 'data/constants'
import { apiCall } from 'helpers'
import { makeLogic } from 'helpers/redux'
import * as cx from './constants'

const baseUrl = API_DOMAINS.NOTIFICATIONS

const countUnreadNotifications = makeLogic({
  httpMethod: apiCall.get,
  latest: true,
  path: `${baseUrl}/management/count-user-unread-notifications`,
  processError: ({ error }) => {
    console.log(error)
  },
  processResult: ({ data }) => ({ unreadQuantity: data }),
  type: cx.COUNT_UNREAD_NOTIFICATIONS
})

const getUserNotificationsPage = makeLogic({
  httpMethod: apiCall.get,
  latest: true,
  path: `${baseUrl}/management/get-user-notifications-page`,
  payload: ({ payload }) => ({
    params: {
      Page: payload?.page || 1,
      PageSize: payload?.pageSize || NOTIFICATIONS.PAGE_SIZE,
    }
  }),
  processError: ({ error }) => {
    console.log(error)
  },
  processResult: res => res.data,
  type: cx.GET_USER_NOTIFICATIONS_PAGE
})

const markNotificationsReadByPage = makeLogic({
  httpMethod: apiCall.post,
  path: `${baseUrl}/management/mark-notifications-as-read-by-page`,
  processError: ({ error }) => {
    console.log(error)
  },
  processResult: res => {
    console.log(res)
  },
  type: cx.MARK_NOTIFICATIONS_READ_BY_PAGE
})

export default [
  countUnreadNotifications,
  getUserNotificationsPage,
  markNotificationsReadByPage
]
