
import PropTypes from 'prop-types'

export const DEFAULT_PROPS = {
  style: {},
  type: 'button'
}

export const PROP_TYPES = {
  children: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  hoverStyle: PropTypes.object,
  mod: PropTypes.oneOf(['1', '2', '3']),
  onClick: PropTypes.func,
  style: PropTypes.object,
  type: PropTypes.string
}
