
import { getCategoryById } from 'helpers/categories'
import { get } from 'lodash'

export default props => {
  const allCategories = get(props, 'categories.all', [])
  const categoryId = get(props, 'products.category')
  const category = getCategoryById(allCategories, categoryId)
  const products = get(props, 'products')
  const searchFormValues = get(props, 'searchFormValues')

  const resultAddress = get(searchFormValues, 'address') || get(products, 'location')
  const resultFrom = get(searchFormValues, 'from') || get(products, 'fromDate')
  const resultLookingFor = get(searchFormValues, 'lookingFor') || {
    label: get(category, 'name'),
    value: get(category, 'id'),
    parentCategoryId: get(category, 'parentCategoryId')
  }
  const resultTo = get(searchFormValues, 'to') || get(products, 'toDate')

  return props ? {
    address: resultAddress ? { ...resultAddress } : null,
    from: resultFrom ? { ...resultFrom } : null,
    lookingFor: category ? { ...resultLookingFor } : null,
    to: resultTo ? { ...resultTo } : null
  } : {}
}
