
import PropTypes from 'prop-types'

export const CAROUSEL_IMAGES = [
  {
    alt: '',
    src: require('theme/images/slider_img1.jpg')
  },
  {
    alt: '',
    src: require('theme/images/slider_img2.jpg')
  },
  {
    alt: '',
    src: require('theme/images/slider_img3.jpg')
  }
]

export const PROP_TYPES = {
  clickSearchWarning: PropTypes.func,
  search: PropTypes.shape({
    error: PropTypes.string,
    status: PropTypes.string
  }),
  submitSearch: PropTypes.func.isRequired
}
