
import classnames from 'classnames'
import React from 'react'
import { compose, pure, setDisplayName, setPropTypes } from 'recompose'
import { PROP_TYPES } from './config'

const enhance = compose(
  setDisplayName('views/components/forms/components/Status'),
  setPropTypes(PROP_TYPES),
  pure
)

export default enhance(({
  className,
  error,
  message,
  onClick
}) =>
  <div
    className={classnames('form-control-status', {
      error,
      [className]: !!className
    })}
    onClick={onClick}
  >
    {message}
  </div>
)
