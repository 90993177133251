
import { FORM_NAME } from 'views/components/ManageResults/config'
import { FORM_NAME as FORM_SEARCH_NAME } from 'views/components/ProductMeta/config'
import { get } from 'lodash'
import { formValueSelector } from 'redux-form'

const filterFormSelector = formValueSelector(FORM_NAME)
const searchFormSelector = formValueSelector(FORM_SEARCH_NAME)

export default state => ({
  asyncState: get(state, 'asyncState', {}),
  categories: get(state, 'categories', {}),
  categoryAttributes: get(state, 'categoryAttributes', {}),
  products: get(state, 'products', {}),
  searchFormValues: searchFormSelector(state, 'address', 'from', 'to', 'lookingFor'),
  searchInResultsTerm: filterFormSelector(state, 'searchTerm'),
  relatedProducts: get(state, 'shoppingCart.relatedProducts', {}),
  merchantsState: get(state, 'products.merchants', [])
})
