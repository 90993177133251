
import { methodFromProps } from 'helpers'
import { get } from 'lodash'

export default props => data => () => {
  const fetchGoods = methodFromProps('fetchGoods', props)
  const state = props.store.getState()
  const allCategoryAttrs = get(state, 'products.categoriesAttributes', [])
  const updateCategoryAttributeValue = methodFromProps('updateCategoryAttributeValue', props)
  updateCategoryAttributeValue({
    attributeIdx: data.attrKey,
    newAttributeValue: { selected: !data.selected }, 
    valueIdx: data.itemKey
  })
  allCategoryAttrs[data.attrKey].categoryAttributeValues[data.itemKey] = {
    ...allCategoryAttrs[data.attrKey].categoryAttributeValues[data.itemKey],
    selected: !data.selected
  }
  fetchGoods({
    attributes: allCategoryAttrs,
    formValues: get(props, 'searchFormValues')
  })
}
