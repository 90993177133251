
import { createAction } from 'redux-actions'
import * as cx from './constants'

export const allCategoryAttributesByCategory = createAction(cx.ALL_CATEGORY_ATTRIBUTES_BY_CATEGORY)

export const clearAttributesByCategory = createAction(cx.CLEAR_ATTRIBUTES_BY_CATEGORY, () => ([]))

export const clearAttributeValues = createAction(cx.CLEAR_ATTRIBUTE_VALUES, attrKey => ({ attrKey }))

export const updateCategoryAttributeValue = createAction(cx.UPDATE_CATEGORY_ATTRIBUTE_VALUE, ({
  attributeIdx,
  newAttributeValue,
  valueIdx
}) => ({
  attributeIdx,
  newAttributeValue,
  valueIdx
}))

export const getAllAttributesByCategories = createAction(cx.GET_ALL_ATTRIBUTES_BY_CATEGORIES)
