
import { methodFromProps } from 'helpers'

export default props => files => {
  const file = files[0]
  const fReader = new FileReader()
  const previewUserProfilePicture = methodFromProps('previewUserProfilePicture', props)
  const updateUserProfilePicture = methodFromProps('updateUserProfilePicture', props)

  fReader.readAsDataURL(file)
  fReader.onload = e => {
    const { result } = e.target
    if (result.indexOf('base64') > -1) {
      previewUserProfilePicture({
        alt: file.name,
        src: result
      })
      fReader.readAsBinaryString(file)
    } else {
      // TODO: POST the file to the server, when the API emthod is ready
      updateUserProfilePicture(result)
    }
  }
}
