
import { GeoSuggestInput } from 'views/components/forms'
import { iconed } from 'views/components/forms/hocs'
import React from 'react'
import { Icon } from 'react-icons-kit'
import { mapMarker } from 'react-icons-kit/fa/mapMarker'

export default iconed({
  clearable: true,
  icon: <Icon icon={mapMarker} />,
  label: 'Delivery Address'
})(GeoSuggestInput)
