
import { Status } from 'views/components'
import { userManager } from 'data'
import React from 'react'
import { compose, pure, setDisplayName, withHandlers } from 'recompose'
import { CallbackComponent } from 'redux-oidc'
import { successClb } from './lib'

const enhance = compose(
  setDisplayName('views/routes/auth/Page'),
  withHandlers({
    successClb
  }),
  pure
)

export default enhance(({
  successClb
}) =>
  <CallbackComponent
    errorCallback={err => { console.log(err) }}
    successCallback={successClb}
    userManager={userManager}
  >
    <div className='container' style={{ padding: '1px 0' }}>
      <p className='text-center'>
        Authorization in process...
      </p>
      <Status />
    </div>
  </CallbackComponent>
)
