
import { SEARCH_PRODUCTS_DEFAULTS, SEARCH_PRODUCTS_SORT_BY } from 'data/constants'
import { typeGen } from 'helpers/redux'

const makeType = typeGen('products')

export const SEARCH_PRODUCTS = makeType('searchProducts')
export const SEARCH_PRODUCTS_DEFAULT_PARAMS = {
  SORT_BY: SEARCH_PRODUCTS_SORT_BY.NONE,
  ...SEARCH_PRODUCTS_DEFAULTS
}
export const SET_PRODUCTS_CATEGORY = makeType('setProductsCategory')
