
import PropTypes from 'prop-types'

export const DEFAULT_PROPS = {
  items: []
}

export const PROP_TYPES = {
  items: PropTypes.arrayOf(PropTypes.shape({
    href: PropTypes.string,
    icon: PropTypes.object.isRequired,
    targetBlank: PropTypes.bool
  }))
}
