
import { dropStatus } from 'redux/modules/asyncState/actions'
import { searchProducts } from 'redux/modules/products/actions'
import { saveUserDefaultSearch } from 'redux/modules/user/actions'
import { FormattedMessage } from 'react-intl'
import React from 'react'

export const ACTIONS = {
  dropStatus,
  searchProducts,
  saveUserDefaultSearch
}

export const PRODUCTS = [
  {
    image: {
      alt: 'Skis & boards',
      src: require('theme/images/product-1.jpg')
    },
    name: <FormattedMessage id="ProductsList.SkisAndBoards"/>,
  },
  {
    image: {
      alt: 'Boots',
      src: require('theme/images/product-2.jpg')
    },
    name: <FormattedMessage id="ProductsList.Boots"/>,
  },
  {
    image: {
      alt: 'Accessories',
      src: require('theme/images/product-3.jpg')
    },
    name: <FormattedMessage id="ProductsList.Accessories"/>,
  }
]

export const WORK_FLOAT_LIST = [
  {
    description: <FormattedMessage id="WorkFloatList.Search.Description"/>,
    heading: <FormattedMessage id="WorkFloatList.Search"/>,
    image: {
      alt: 'Search',
      path: require('theme/images/search.png')
    }
  },
  {
    description: <FormattedMessage id="WorkFloatList.Rent.Description"/>,
    heading: <FormattedMessage id="WorkFloatList.Rent"/>,
    image: {
      alt: 'Rent',
      path: require('theme/images/shopping-cart.png')
    }
  },
  {
    description: <FormattedMessage id="WorkFloatList.Receive.Description"/>,
    heading: <FormattedMessage id="WorkFloatList.Receive"/>,
    image: {
      alt: 'Receive',
      path: require('theme/images/skiing.png')
    }
  }
]
