import { apiCall } from 'helpers'
import { makeLogic } from 'helpers/redux'
import * as cx from './constants'
import { API_DOMAINS } from 'data/constants'

const getAvaibleLanguages = makeLogic({
    httpMethod: apiCall.get,
    latest: true,
    path: `${API_DOMAINS.CATALOG}/get-active-languages`,
    processError: err => {
      return err
    },
    processResult: res => res.data,
    type: cx.GET_AVAIBLE_LANGUAGES
  })

  export default [ 
    getAvaibleLanguages
  ]