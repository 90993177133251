
import axios from 'axios'
import { get } from 'lodash'
import { getClientProfileData, getUserCardInfo } from 'redux/modules/user/actions'

export default () => {
  const authHeader = get(axios, 'defaults.headers.common.Authorization')
  const reqs = []
  if (authHeader) {
    reqs.push({ action: getClientProfileData })
    reqs.push({ action: getUserCardInfo })
  }
  return reqs
}
