
import { pluralFromNumber } from 'helpers/text'

export default ({
  products,
  users,
  ...props
}) => ({
  ...props,
  cartUsers: users + ' ' + pluralFromNumber({
    number: users,
    word: 'user'
  }),
  productsString: products + ' ' + pluralFromNumber({
    number: products,
    word: 'product'
  }),
  currency: localStorage.currency
})
