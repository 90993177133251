
import React from 'react'
import { Icon } from 'react-icons-kit'
import withSizes from 'react-sizes'
import { compose, pure, setDisplayName, setPropTypes } from 'recompose'
import { PROP_TYPES } from './config'
import { mapWindowSizes } from './lib'

const enhance = compose(
  setDisplayName('views/components/SocialBtn'),
  setPropTypes(PROP_TYPES),
  withSizes(mapWindowSizes),
  pure
)

export default enhance(({
  icon,
  isSmall
}) =>
  <div className='social-btn'>
    <Icon
      icon={icon}
      size={isSmall ? 25 : 35}
    />
  </div>
)
