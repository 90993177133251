
import React from 'react'
import { Link } from 'react-router-dom'
import { compose, defaultProps, pure, setDisplayName, setPropTypes } from 'recompose'
import { DEFAULT_PROPS, PROP_TYPES } from './config'

const enhance = compose(
  setDisplayName('views/components/lists/InfoList'),
  setPropTypes(PROP_TYPES),
  defaultProps(DEFAULT_PROPS),
  pure
)

export default enhance(({
  items
}) =>
  <ul className='info-list'>
    {items.map((item, key) =>
      <li key={key}>
        {item.href
          ? <Link
            target={`_${item.targetBlank ? 'blank' : 'self'}`}
            to={item.href}
          >
            {item.label}
          </Link>
          : item.label
        }
      </li>
    )}
  </ul>
)
