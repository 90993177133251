
import { methodFromProps } from 'helpers'

export default props => data => {
  const fields = { ...props.productSettingValues.fields }
  const setProductSettingValue = methodFromProps('setProductSettingValue', props)

  if (data.data === null) {
    delete fields[data.name]
  } else {
    fields[data.name] = data.data
  }

  setProductSettingValue({ fields })
}
