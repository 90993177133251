
import { methodFromProps } from 'helpers'
import { get } from 'lodash'

export default props => values => {
  const clickSubmit = methodFromProps('clickSubmit', props)
  const createToken = methodFromProps('stripe.createToken', props)
  clickSubmit()
  createToken({
    name: values.name
  }).then(res => {
    const onSubmit = methodFromProps('onSubmit', props)
    const token = get(res, 'token.id')
    if (token) {
      onSubmit({ token })
    }
  })
}
