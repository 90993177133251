
import { get } from 'lodash'
import { UPDATE_USER_PASSWORD } from 'redux/modules/user/constants'

export default ({
  asyncState,
  ...props
}) => ({
  ...props,
  updateUserPasswordStatus: get(asyncState, `statuses.${UPDATE_USER_PASSWORD}`, '')
})
