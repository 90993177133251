
import PropTypes from 'prop-types'

export const PROP_TYPES = {
  children: PropTypes.element,
  childrenPosition: PropTypes.shape({
    left: PropTypes.number,
    right: PropTypes.number
  }),
  toggler: PropTypes.element.isRequired
}
