
import React from 'react'
import CurrencyInput from 'react-currency-input-formatter'
import { compose, defaultProps, pure, setDisplayName, setPropTypes, mapProps } from 'recompose'
import { DEFAULT_PROPS, PROP_TYPES } from './config'
import { FormattedMessage } from 'react-intl'
import { mapState, propsMapper } from './lib'
import { connect } from 'react-redux'

const enhance = compose(
  setDisplayName('views/components/texts/Price'),
  setPropTypes(PROP_TYPES),
  defaultProps(DEFAULT_PROPS),
  connect(mapState),
  mapProps(propsMapper),
  pure
)

export default enhance(({
  align,
  currency,
  value
}) =>
  <p
    className='price'
    style={{
      textAlign: align
    }}
  >
    <FormattedMessage id="Products.Product.Price" /> <span className='price_value'>
      <CurrencyInput
        value={value}
        prefix={currency}
        decimalSeparator="."
        thousandSeparator=","
        precision="2"
      />
    </span>
  </p>
)
