import { get } from 'lodash'
import { GetOrderStatus, GetOrderDeliveryType, GetOrderLink } from 'helpers/orders'
import moment from 'moment'
import { FULL_DATE_FORMAT } from 'data/constants'
import { useToasts } from 'react-toast-notifications'


export default ({
  ...props
}) => {
  const { addToast } = useToasts();
  const ordersPage = get(props, 'orders.ordersPage.orders', [])
  const error = get(props, 'orders.error')
  
  if (error !== undefined && error.message !== undefined) {
    addToast(error.message, { appearance: 'error', autoDismiss: true })
  }

  var columnsArr = []
  var dataOrders = []
  var loaded = false
  var includeColumns = [
    ['randomOrderId', 'Random Id'],
    //['numberOfItems', 'Items'],
    ['merchantName', 'Merchant'],
    ['orderStatus', 'Status'],
    ['orderTotal', 'Total'],
    //['paymentStatus', 'Payment Status'],
    //['shippingStatus', 'Shipping Status'],
    ['orderDeliveryType', 'Delivery Type'],
    ['deliveryAddress', 'Delivery Address'],
    ['deliveryTime', 'Delivery Date'],  
    ['returnAddress', 'Return Address'],
    //['returnStatus', 'Return Status'],
    ['returnTime', 'Return Time'],
  ]

  if (typeof ordersPage !== undefined && ordersPage.length > 0) {
    loaded = true

    for (const key in includeColumns) {
      var column = {
        Header: includeColumns[key][1],
        accessor: includeColumns[key][0],
      }

      columnsArr.push(column)
    }

    dataOrders = ordersPage.map((order, key) => {
      var item = {}
      for (const i in includeColumns) {
        if (includeColumns[i][0] === 'orderStatus') item[includeColumns[i][0]] = GetOrderStatus(order[includeColumns[i][0]])
        else if (includeColumns[i][0] === 'orderDeliveryType') item[includeColumns[i][0]] = GetOrderDeliveryType(order[includeColumns[i][0]])
        else if (includeColumns[i][0] === 'deliveryTime') item[includeColumns[i][0]] = order[includeColumns[i][0]] ? moment(order[includeColumns[i][0]]).format(FULL_DATE_FORMAT) : ''
        else if (includeColumns[i][0] === 'returnTime') item[includeColumns[i][0]] = order[includeColumns[i][0]] ? moment(order[includeColumns[i][0]]).format(FULL_DATE_FORMAT) : ''
        else if (includeColumns[i][0] === 'randomOrderId') item[includeColumns[i][0]] = GetOrderLink(order[includeColumns[i][0]])
        else item[includeColumns[i][0]] = order[includeColumns[i][0]]
      }
      return item
    })
  }
  else {
    loaded = false
  }

  var tableHeader = [{
    Header: 'Orders',
    columns: columnsArr,
  }]

  return {
    ...props,
    headers: tableHeader,
    data: dataOrders,
    isLoaded: loaded,
    page: get(props, 'orders.ordersPage.pageIndex', 1),
    pages: get(props, 'orders.ordersPage.totalPages', 1),
  }
}
