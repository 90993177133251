
import { Button, ButtonDark, ButtonLess, ButtonPlus, ButtonSharp } from 'views/components/buttons'
import PropTypes from 'prop-types'
import React from 'react'
import { compose, pure, setDisplayName, setPropTypes } from 'recompose'

const enhance = compose(
  setDisplayName('views/routes/_r/_lib/components/Buttons'),
  setPropTypes({
    heading: PropTypes.string
  }),
  pure
)

export default enhance(({
  heading
}) =>
  <div>
    <h2 style={{ marginTop: 20 }}>
      {heading}
    </h2>
    <Button label='Button (light)' />
    <br />
    <Button
      isDark
      label='Button (dark)'
    />
    <br />
    <button className='buttonBig'>
      Button big
    </button>
    <br />
    <ButtonLess>
      ButtonLess
    </ButtonLess>
    <br />
    <ButtonLess
      backgroundColor='#444443'
      color='#fff'
    >
      ButtonLess 2
    </ButtonLess>
    <br />
    <ButtonSharp hoverStyle={{ color: '#0f0' }}>
      ButtonSharp
    </ButtonSharp>
    <br />
    <ButtonSharp mod='1'>
      ButtonSharp mod 1
    </ButtonSharp>
    <br />
    <ButtonSharp mod='2'>
      ButtonSharp mod 2
    </ButtonSharp>
    <br />
    <ButtonSharp mod='3'>
      ButtonSharp mod 3
    </ButtonSharp>
    <br />
    <ButtonDark children='ButtonDark' />
    <br />
    <ButtonDark
      big
      children='ButtonDark (big)'
    />
    <br />
    <ButtonDark
      children='ButtonDark mod 1'
      mod={1}
    />
    <br />
    <ButtonPlus
      onClick={() => { console.log('Click button plus') }}
    />
  </div>
)
