
import { Point } from 'views/components'
import React from 'react'
import { compose, defaultProps, pure, setDisplayName, setPropTypes } from 'recompose'
import { DEFAULT_PROPS, PROP_TYPES } from './config'

const enhance = compose(
  setDisplayName('views/components/lists/PointsList'),
  setPropTypes(PROP_TYPES),
  defaultProps(DEFAULT_PROPS),
  pure
)

export default enhance(({
  items
}) =>
  <div className='container point-list'>
    <ul className='row'>
      {items.map((item, key) =>
        <li
          className='col-md-4 col-12'
          key={key}
        >
          <Point {...item} />
        </li>
      )}
    </ul>
  </div>
)
