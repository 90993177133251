
import { get } from 'lodash'
/**
 * @param {Object} products
 * @param {Moment} products.fromDate
 * @param {Moment} products.toDate
 */
export default products => {
  const res = {}
  if (get(products, 'fromDate')) {
    // res.from = products.fromDate.toDate()
    res.from = products.fromDate._d
  }
  if (get(products, 'toDate')) {
    // res.to = products.toDate.toDate()
    res.to = products.toDate._d
  }
  return res
}
