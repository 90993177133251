
import { API_DOMAINS } from 'data/constants'
import { apiCall } from 'helpers'
import { makeLogic } from 'helpers/redux'
import { forOwn, upperFirst } from 'lodash'
import { getUserCardInfo as getUserCardInfoAction } from './actions'
import * as cx from './constants'

const changeCustomerCard = makeLogic({
  httpMethod: apiCall.post,
  latest: true,
  onSuccess: ({ dispatch }) => {
    dispatch(getUserCardInfoAction())
  },
  path: `${API_DOMAINS.STRIPE}/change-customer-card`,
  processError: err => {
    return err
  },
  processResult: res => res.data,
  type: cx.CHANGE_CUSTOMER_CARD
})

const getClientProfileData = makeLogic({
  httpMethod: apiCall.post,
  latest: true,
  path: `${API_DOMAINS.USER}/get-client-profile-data`,
  processError: err => {
    return err
  },
  processResult: res => res.data,
  type: cx.GET_CLIENT_PROFILE_DATA
})

const getUserCardInfo = makeLogic({
  httpMethod: apiCall.get,
  latest: true,
  path: `${API_DOMAINS.USER}/get-user-card-information`,
  processError: err => {
    return err
  },
  processResult: res => res.data,
  type: cx.GET_USER_CARD_INFO
})

const updateClientProfile = makeLogic({
  httpMethod: apiCall.post,
  latest: true,
  path: `${API_DOMAINS.USER}/update-client-profile`,
  payload: ({ payload }) => ({
    ...forOwn(payload, (v, k, o) => {
      delete o[k]
      o[upperFirst(k)] = v
    })
  }),
  processError: err => {
    return err
  },
  type: cx.UPDATE_CLIENT_PROFILE
})

const updateUserPassword = makeLogic({
  httpMethod: apiCall.post,
  path: `${API_DOMAINS.USER}/update-user-password`,
  processError: err => {
    return err
  },
  processResult: res => res.data,
  type: cx.UPDATE_USER_PASSWORD
})

const updateUserProfilePicture = makeLogic({
  httpMethod: apiCall.post,
  latest: true,
  path: `${API_DOMAINS.USER}/update-user-profile-picture`,
  processError: err => {
    return err
  },
  processResult: res => res.data,
  type: cx.UPDATE_USER_PROFILE_PICTURE
})

const updateUserCard = makeLogic({
  httpMethod: apiCall.post,
  path: `${API_DOMAINS.USER}/update-user-card`, // TODO on API
  processError: err => {
    return err
  },
  processResult: res => res.data,
  type: cx.UPDATE_USER_CARD
})

const saveUserDefaultSearch = makeLogic({
  httpMethod: apiCall.post,
  path: `${API_DOMAINS.USER}/save-user-default-search`,
  processError: err => {
    return err
  },
  processResult: res => res.data,
  type: cx.SAVE_USER_DEFAULT_SEARCH
})

export default [
  changeCustomerCard,
  getClientProfileData,
  getUserCardInfo,
  updateClientProfile,
  updateUserCard,
  updateUserPassword,
  updateUserProfilePicture,
  saveUserDefaultSearch
]
