
import { createAction } from 'redux-actions'
import * as cx from './constants'

/**
 * @param {Object} payload
 * @param {String} payload.token
*/
export const changeCustomerCard = createAction(cx.CHANGE_CUSTOMER_CARD)
export const getClientProfileData = createAction(cx.GET_CLIENT_PROFILE_DATA)
export const getUserCardInfo = createAction(cx.GET_USER_CARD_INFO)

/**
 * @param {Object} payload
 * @param {String} payload.alt - file name
 * @param {String} payload.src - data URL of the file
*/
export const previewUserProfilePicture = createAction(cx.PREVIEW_USER_PROFILE_PICTURE)

export const updateClientProfile = createAction(cx.UPDATE_CLIENT_PROFILE)
export const updateUserCard = createAction(cx.UPDATE_USER_CARD)
export const updateUserPassword = createAction(cx.UPDATE_USER_PASSWORD)
/**
 * @param {String} fileName
*/
export const updateUserProfilePicture = createAction(cx.UPDATE_USER_PROFILE_PICTURE, fileName => ({ fileName }))

export const saveUserDefaultSearch = createAction(cx.SAVE_USER_DEFAULT_SEARCH)
