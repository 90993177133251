
import { methodFromProps } from 'helpers'

export default props => data => {
  const getUserNotificationsPage = methodFromProps('getUserNotificationsPage', props)
  const payload = {
    page: data.selected + 1,
    pageSize: 10
  }
  getUserNotificationsPage(payload)
}
