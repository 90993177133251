
import { Tip } from 'views/components'
import Range from 'rc-slider/lib/Range'
import React from 'react'
import { compose, mapProps, pure, setDisplayName, setPropTypes } from 'recompose'
import { PROP_TYPES } from './config'
import { propsMapper } from './lib'
import 'rc-slider/assets/index.css'

const enhance = compose(
  setDisplayName('views/components/Ranger'),
  setPropTypes(PROP_TYPES),
  mapProps(propsMapper),
  pure
)

export default enhance(({
  defaultValue,
  limitBottom,
  limitBottomUnits,
  limitTop,
  limitTopUnits,
  onChange,
  range,
  tipRange,
  value
}) =>
  <div className='ranger'>
    <p className='ranger_limit'>
      {limitBottomUnits}
    </p>
    <Tip text={tipRange}>
      <Range
        defaultValue={defaultValue}
        max={limitTop}
        min={limitBottom}
        onChange={onChange}
        value={value}
      />
    </Tip>
    <p className='ranger_limit'>
      {limitTopUnits}
    </p>
    <p className='ranger_range'>
      {range}
    </p>
  </div>
)
