
import {
  allCategoryAttributesByCategory,
  getAllAttributesByCategories,
  clearAttributesByCategory,
  clearAttributeValues,
  updateCategoryAttributeValue,
} from 'redux/modules/categoryAttributes/actions'
import { searchProducts, setProductsCategory } from 'redux/modules/products/actions'
import { insertShoppingCart, getRelatedProducts } from 'redux/modules/shoppingCart/actions'

export const ACTIONS = {
  allCategoryAttributesByCategory,
  getAllAttributesByCategories,
  clearAttributesByCategory,
  clearAttributeValues,
  searchProducts,
  setProductsCategory,
  updateCategoryAttributeValue,
  insertShoppingCart,
  getRelatedProducts
}