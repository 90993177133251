

import classnames from 'classnames'
import { ALIGN } from 'data/constants'
import React from 'react'
import { When } from 'react-if'
import { compose, defaultProps, pure, setDisplayName, setPropTypes } from 'recompose'
import { DEFAULT_PROPS, PROP_TYPES } from './config'
import Spinner from 'theme/images/spinner.svg'

const enhance = compose(
    setDisplayName('views/components/Loader'),
    setPropTypes(PROP_TYPES),
    defaultProps(DEFAULT_PROPS),
    pure
  )

const Message = props => (
    <When condition={!!props.message}>
      <p className='status_msg'>
        {props.message}
      </p>
    </When>
  )

  export default enhance(({
    className,
    message,
    position,
    size,
    isLoaded
  }) =>
    <div className={classnames('status', {
      [className]: !!className
    })}>
      <When condition={!isLoaded}>
        <When condition={position === ALIGN.TOP}>
            <Message message={message} />
        </When>
        <img
            alt=''
            height={size}
            src={Spinner}
            width={size}
        />
        <When condition={position === ALIGN.BOTTOM}>
            <Message message={message} />
        </When>
      </When>
    </div>
  )