
import axios from 'axios'

/* TODO: Uncomment when CORS is solved on the server
axios.defaults.baseURL = process.env.REACT_APP_API_PATH
*/
axios.defaults.withCredentials = true
axios.defaults.headers.common['Accept-Language'] = localStorage.lang === undefined ? "en-us" : localStorage.lang
axios.defaults.headers.common['Accept-Currency'] = localStorage.currency
//axios.defaults.paramsSerializer = params => qs.stringify(params)

export default axios
