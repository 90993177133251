
import { Overlay } from 'views/components'
import { Modal } from 'views/components/modals'
import React from 'react'
import { compose, pure, setDisplayName, withState } from 'recompose'

const enhance = compose(
  setDisplayName('views/routes/_lib/components/Modals'),
  withState('showOverlay', 'toggleOverlay', false),
  withState('showModal', 'toggleModal', false),
  pure
)

export default enhance(({
  showModal,
  showOverlay,
  title,
  toggleModal,
  toggleOverlay
}) =>
  <section>
    <h1>
      {title}
    </h1>

    <p>
      components/Overlay
    </p>
    <button onClick={() => { toggleOverlay(true) }}>
      Show Overlay
    </button>
    {showOverlay ? (
      <Overlay
        visible
        children={
          <span>
            Overlay
          </span>
        }
        clickCurtain={() => { toggleOverlay(false) }}
      />
    ) : null}

    <p>
      components/modals/Modal
    </p>
    <button onClick={() => { toggleModal(true) }}>
      Modal
    </button>
    {showModal ? (
      <Modal
        children={
          <div>
            Some Modal
          </div>
        }
        onClose={() => { console.log('Close modal') }}
      />
    ) : null}
  </section>
)
