
import countryRegionData from 'country-region-data/dist/data-umd'
import { zeroLeading } from 'helpers/dateTime'
import { countryByCode } from 'helpers/geo'
import { findIndex, get, pick } from 'lodash'

export default data => {
  const addressZip = get(data, 'addressZip')
  const country = get(data, 'country')
  const countryFound = countryByCode(country)
  const expirationMonth = get(data, 'expirationMonth')
  const expirationYear = get(data, 'expirationYear')
  const last4 = get(data, 'last4')
  const name = get(data, 'name')
  const res = {
    ...pick(data, ['name'])
  }

  if (last4) {
    res.cardNumber = {
      placeholder: `**** **** **** ${last4}`
    }
  }
  if (addressZip) {
    res.zip = addressZip
  }
  if (country) {
    res.country = {
      label: countryFound.countryName,
      order: findIndex(countryRegionData, c => c.countryShortCode === country),
      value: country
    }
  }
  if (expirationMonth && expirationYear) {
    res.expirationDate = {
      placeholder: `${zeroLeading(expirationMonth)} / ${expirationYear.slice(2)}`
    }
  }
  if (!name) {
    delete res.name
  }
  return res
}
