import { methodFromProps } from 'helpers'

export default props => id => {
  const setSelectedProductId = methodFromProps('setSelectedProductId', props)
  const getRelatedProducts = methodFromProps('getRelatedProducts', props)
  getRelatedProducts(id)
  setSelectedProductId(id)
  const toggleUpShoppingCardModal = methodFromProps('toggleUpShoppingCardModal', props)
  !props.shoppingCardModal && toggleUpShoppingCardModal(true)
}
