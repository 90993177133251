
import PropTypes from 'prop-types'

export const PROP_TYPES = {
  description: PropTypes.element,
  heading: PropTypes.element,
  image: PropTypes.shape({
    alt: PropTypes.string,
    path: PropTypes.string
  }),
  number: PropTypes.number
}
