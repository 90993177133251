
import { format } from 'helpers/dateTime'
import moment from 'moment'

export default data => {
  if (!data) {
    return ''
  }
  const formatDate = 'DD/MM/YYYY'
  const from = format(data.from, formatDate)
  const to = format(data.to, formatDate)
  const diff = moment(moment(data.to)).diff(moment(data.from), 'days')
  return `${from} to ${to} (${diff}d)`
}
