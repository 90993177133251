
import classnames from 'classnames'
import { ButtonSharp } from 'views/components/buttons'
import { HdTitle2, Price } from 'views/components/texts'
import { ALIGN } from 'data/constants'
import { get } from 'lodash'
import React from 'react'
import ReactResizeDetector from 'react-resize-detector'
import { When } from 'react-if'
import { compose, defaultProps, pure, setDisplayName, setPropTypes, withHandlers } from 'recompose'
import { DEFAULT_PROPS, PROP_TYPES } from './config'
import { handleBookClick, handleImageClick, handleTitleClick } from './lib'
import { FormattedMessage } from 'react-intl'

const enhance = compose(
  setDisplayName('views/components/ProductItem'),
  setPropTypes(PROP_TYPES),
  defaultProps(DEFAULT_PROPS),
  withHandlers({
    handleBookClick,
    handleImageClick,
    handleTitleClick
  }),
  pure
)

export default enhance(({
  bookButton,
  currency,
  describe,
  handleBookClick,
  handleImageClick,
  handleTitleClick,
  image,
  price,
  title
}) =>
  <article className='productItem'>
    <ReactResizeDetector handleWidth>
      {({ width }) => {
        const isInline = width > 450

        return (
          <div className={classnames('productItem_inner bordered', {
            // eslint-disable-next-line
            ['productItem_inner--bordered']: isInline
          })}>
            <figure className={`col-${isInline ? 5 : 12}`}>
              <img
                alt={get(image, 'alt', '')}
                className='productItem_image'
                onClick={handleImageClick}
                src={get(image, 'src', '')}
              />
            </figure>
            <div className={classnames(`col-${isInline ? 7 : 12}`, {
              // eslint-disable-next-line
              ['productItem_prodInfo--bordered']: !isInline
            })}>
              <HdTitle2>
                <span
                  className='productItem_hd'
                  onClick={handleTitleClick}
                >
                  {title}
                </span>
              </HdTitle2>
              <ul className='productItem_desc'>
                <li>
                  <FormattedMessage id="ProductPage.Content.For" /> {get(describe, 'for', '')}
                </li>
                <li>
                  <FormattedMessage id="ProductPage.Content.Level" /> {get(describe, 'level', '')}
                </li>
                <li>
                  <FormattedMessage id="ProductPage.Content.Shop" /> {get(describe, 'shop', '')}
                </li>
              </ul>
              <div className={classnames('productItem_bottom', {
                // eslint-disable-next-line
                ['productItem_bottom--priceOnly']: !bookButton
              })}>
                <Price
                  align={ALIGN[isInline ? 'RIGHT' : 'LEFT']}
                  currency={currency}
                  value={price}
                />
                <When condition={bookButton}>
                  <ButtonSharp
                    children={<FormattedMessage id="Products.Product.Book" />}
                    mod='1'
                    onClick={handleBookClick}
                  />
                </When>
              </div>
            </div>
          </div>
        )
      }}
    </ReactResizeDetector>
  </article>
)
