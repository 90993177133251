
import { methodFromProps } from 'helpers'
import { get } from 'lodash'
import moment from 'moment'

export default props => values => {
  const searchProducts = methodFromProps('searchProducts', props)
  searchProducts({
    fromDate: moment(),
    location: get(values, 'place', {}),
    toDate: moment().add(1, 'day')
  })
}
