
import PropTypes from 'prop-types'

export const DEFAULT_PROPS = {
  type: 'button'
}

export const PROP_TYPES = {
  isDark: PropTypes.bool,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  onClick: PropTypes.func,
  type: PropTypes.string
}
