
import React from 'react'
import { Link } from 'react-router-dom'
import { compose, pure, setDisplayName, setPropTypes } from 'recompose'
import { PROP_TYPES } from './config'

const enhance = compose(
  setDisplayName('views/routes/_lib/components/LibMenu'),
  setPropTypes(PROP_TYPES),
  pure
)

export default enhance(({
  items
}) =>
  <ul>
    {items.map((item, key) =>
      <li key={key}>
        <Link to={`/_lib${item.path}`}>
          {item.label}
        </Link>
      </li>
    )}
  </ul>
)
