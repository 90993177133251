
import { PROP_TYPES as FIELDS_PROP_TYPES } from 'views/components/forms/FieldsSet/config'
import PropTypes from 'prop-types'
import { updateShoppingCartItem, addShoppingCartItemDetails, insertShoppingCart } from 'redux/modules/shoppingCart/actions'

  export const ACTIONS = {
    updateShoppingCartItem,
    addShoppingCartItemDetails,
    insertShoppingCart
  }
  

export const DEFAULT_PROPS = {
  products: []
}

export const PROP_TYPES = {
  form: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  products: PropTypes.arrayOf(PropTypes.shape({
    fields: FIELDS_PROP_TYPES.fields,
    image: PropTypes.shape({
      alt: PropTypes.string,
      src: PropTypes.string
    }),
    inReduxForm: FIELDS_PROP_TYPES.inReduxForm,
    name: PropTypes.string,
    id:  PropTypes.number
  })),
  otherProducts: PropTypes.arrayOf(PropTypes.shape({
    image: PropTypes.shape({
      alt: PropTypes.string,
      src: PropTypes.string
    }),
    name: PropTypes.string
  })),
  customers: FIELDS_PROP_TYPES.fields
}
