
import { get } from 'lodash'
import { getAllCategories } from 'redux/modules/categories/actions'
import { getProductData } from 'redux/modules/product/actions'
import { getRelatedProducts } from 'redux/modules/shoppingCart/actions'

export default props => {
  const productId = +get(props, 'match.params.product')
  const state = props.store.getState()
  const loaders = [
    {
      action: getProductData,
      payload: productId
    }
  ]

  if (get(state, 'categories.all.length', 0) === 0) {
    loaders.push({
      action: getAllCategories,
      payload: {}
    })
  }

  loaders.push({
    action: getRelatedProducts,
    payload: productId
  })

  return loaders
}
