
import { methodFromProps } from 'helpers'

export default props => field => data => {
  const onChange = methodFromProps('onChange', props)
  onChange({
    data,
    isField: true,
    name: field.name
  })
}
