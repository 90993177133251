
import React from 'react'
import { compose, pure, setDisplayName, setPropTypes } from 'recompose'
import { Item } from './components'
import { PROP_TYPES } from './config'

const enhance = compose(
  setDisplayName('views/components/lists/CheckList'),
  setPropTypes(PROP_TYPES),
  pure
)

export default enhance(({
  items
}) =>
  <ul className='checklist'>
    {items.map((item, key) =>
      <Item
        key={key}
        {...item}
      />
    )}
  </ul>
)
