
import { success, error } from 'helpers/redux/actionStateTypes'
import { get } from 'lodash'
import { combineActions, handleActions } from 'redux-actions'
import * as cx from './constants'

const initialState = {
  allByCategory: [],
  allAttributesByCategories: [],
  error: {}
}
const types = [
  success(cx.ALL_CATEGORY_ATTRIBUTES_BY_CATEGORY),
  cx.CLEAR_ATTRIBUTES_BY_CATEGORY
]

export default handleActions({
  [combineActions(...types)]: (state, action) => ({
    ...state,
    allByCategory: action.payload
  }),
  [cx.CLEAR_ATTRIBUTE_VALUES]: (state, action) => {
    const { attrKey } = action.payload
    const allByCategory = [ ...state.allByCategory ]
    const categoryAttributeValues = get(allByCategory, `[${attrKey}].categoryAttributeValues`, []).map(cav => ({
      ...cav,
      selected: false
    }))
    allByCategory[attrKey].categoryAttributeValues = categoryAttributeValues
    return { ...state, allByCategory }
  },
  [cx.UPDATE_CATEGORY_ATTRIBUTE_VALUE]: (state, action) => {
    const { attributeIdx, newAttributeValue, valueIdx } = action.payload
    const allByCategory = [ ...state.allByCategory ]
    allByCategory[attributeIdx] = {
      ...state.allByCategory[attributeIdx]
    }
    allByCategory[attributeIdx].categoryAttributeValues[valueIdx] = {
      ...state.allByCategory[attributeIdx].categoryAttributeValues[valueIdx],
      ...newAttributeValue
    }
    return { ...state, allByCategory }
  },
  [success(cx.GET_ALL_ATTRIBUTES_BY_CATEGORIES)]: (state, action) => ({
    ...state,
    allAttributesByCategories: get(action, 'payload', [])
  }),
  [error(cx.GET_ALL_ATTRIBUTES_BY_CATEGORIES)]: (state, action) => ({
    ...state,
    error: get(action, 'payload', [])
  }),
}, initialState)
