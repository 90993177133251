
import React from 'react'
import { Route } from 'react-router-dom'
import { compose, pure, setDisplayName } from 'recompose'
import Page from './Page'

const enhance = compose(
  setDisplayName('views/routes/user/Router'),
  pure
)

export default enhance(() =>
  <div>
    <Route
      exact
      component={Page}
      path='/user'
    />
    <Route
      component={require('views/routes/_r/user/_r/password').Router}
      path='/user/password'
    />
  </div>
)
