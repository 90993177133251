
import { get } from 'lodash'

export default props => {
  return {
    ...props,
    error: get(props, 'meta.error', ''),
    touched: get(props, 'meta.touched', '')
  }
}
