
import PropTypes from 'prop-types'

export const PROP_TYPES = {
  items: PropTypes.arrayOf(PropTypes.shape({
    checked: PropTypes.bool,
    colorDot: PropTypes.string,
    icon: PropTypes.shape({
      alt: PropTypes.string,
      src: PropTypes.string
    }),
    label: PropTypes.string,
    name: PropTypes.string,
    toggleCheck: PropTypes.func
  }))
}
