
import { Checkbox } from 'views/components/forms'
import React from 'react'
import { compose, pure, setDisplayName, setPropTypes, withHandlers } from 'recompose'
import { PROP_TYPES } from './config'
import { handleChange } from './lib'

const enhance = compose(
  setDisplayName('views/components/lists/CheckList/Item'),
  setPropTypes(PROP_TYPES),
  withHandlers({
    handleChange
  }),
  pure
)

export default enhance(({
  checked,
  colorDot,
  handleChange,
  icon,
  label,
  name
}) =>
  <li className='checklist_item'>
    <label>
      <Checkbox
        checked={checked}
        name={name}
        onChange={handleChange}
      />
      {icon ? (
        <img
          alt={icon.alt}
          className='checklist_item_img'
          src={icon.src}
        />
      ) : colorDot ? (
        <span
          className='checklist_item_color'
          style={{ backgroundColor: colorDot }}
        />
      ) : null}
      <span className='checklist_item_label'>
        {label}
      </span>
    </label>
  </li>
)
