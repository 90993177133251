
import classnames from 'classnames'
import { Status } from 'views/components/forms/components'
import { methodFromProps } from 'helpers'
import { omit, uniqueId } from 'lodash'
import React, { PureComponent } from 'react'
import { Else, If, Then, When } from 'react-if'
import { CardNumberElement } from 'react-stripe-elements'
import { compose, defaultProps, mapProps, setDisplayName, setPropTypes, withHandlers } from 'recompose'
import { DEFAULT_PROPS, PROP_TYPES } from './config'
import { handleChange, propsMapper } from './lib'

const enhance = compose(
  setDisplayName('views/components/forms/Input'),
  setPropTypes(PROP_TYPES),
  defaultProps(DEFAULT_PROPS),
  mapProps(propsMapper),
  withHandlers({
    handleChange
  })
)

class Input extends PureComponent {
  _inputEl = null

  handleStatusClick = () => {
    const focus = methodFromProps('_inputEl.focus', this)
    focus()
  }

  render () {
    const {
      big,
      borderRadius,
      className,
      error,
      errorAtBottom,
      handleChange,
      input,
      inputClassName,
      label,
      mod,
      noBorder,
      required,
      small,
      stripeElement,
      style,
      tip,
      touched,
      ...props
    } = this.props
    const classNameCombined = classnames('form-control', {
      // eslint-disable-next-line
      ['form-control-sm']: small,
      [inputClassName]: !!inputClassName
    })
    const StripeCmp = stripeElement || CardNumberElement
    const uid = uniqueId('input_')
    const newStyle = {
      borderRadius,
      fontSize: `${small ? 0.8125 : (big ? 1.5 : 1)}em`,
      paddingBottom: small ? '0.4em' : '',
      ...style
    }
    if (noBorder) {
      newStyle.border = '0 none'
    }

    return (
      <div className={classnames('input-text', {
        [className]: !!className,
        // eslint-disable-next-line
        [`input-text--mod${mod}`]: mod
      })}>
        <When condition={!!label}>
          <label htmlFor={uid}>
            {label}
            <When condition={required}>
              <span className='input-text_required'>
                *
              </span>
            </When>
          </label>
        </When>
        <div className='input-text_cnt'>
          <If condition={!!stripeElement}>
            <Then>
              <StripeCmp
                className={classNameCombined}
                onChange={handleChange}
                onReady={el => { this._inputEl = el }}
                placeholder={props.placeholder}
                style={{
                  base: {
                    fontFamily: 'Montserrat, sans-serif',
                    fontSize: '16px'
                  }
                }}
              />
            </Then>
            <Else>
              <input
                className={classNameCombined}
                id={uid}
                onChange={handleChange}
                ref={el => { this._inputEl = el }}
                style={newStyle}
                {...omit(input, ['onChange'])}
                {...omit(props, ['id', 'meta', 'onChange', 'ref'])}
              />
            </Else>
          </If>
          {error && touched
            ? (
            <Status
              error
              className={errorAtBottom ? 'bottom' : ''}
              message={error}
              onClick={this.handleStatusClick}
            />
          ) : null}
        </div>
        {tip
          ? (
            <small className='form-text text-muted'>
              {tip}
            </small>
          )
          : null
        }
      </div>
    )
  }
}

export default enhance(Input)
