
import classnames from 'classnames'
import { Search3 } from 'views/components/forms'
import React from 'react'
import { When } from 'react-if'
import { compose, defaultProps, mapProps, pure, setDisplayName, setPropTypes, withHandlers } from 'recompose'
import { DEFAULT_PROPS, FORM_NAME, PROP_TYPES } from './config'
import { handleFilterClick, handleFormSubmit, propsMapper } from './lib'
import { FormattedMessage } from 'react-intl'

const enhance = compose(
  setDisplayName('views/components/ManageResults'),
  setPropTypes(PROP_TYPES),
  defaultProps(DEFAULT_PROPS),
  mapProps(propsMapper),
  withHandlers({
    handleFilterClick,
    handleFormSubmit
  }),
  pure
)

export default enhance(({
  filters,
  handleFilterClick,
  handleFormSubmit,
  resultString,
  showFilter
}) =>
  <div className='manageResults'>
    <div className='row'>
      <div className='col-12 col-sm-3'>
        {resultString}
      </div>
      <div className={classnames(`col-12 col-sm-${showFilter ? 6 : 9} manageResults_centre`, {
        // eslint-disable-next-line
        ['manageResults_centre--noFilter']: !showFilter
      })}>
        <Search3
          form={FORM_NAME}
          onSubmit={handleFormSubmit}
        />
      </div>
      <When condition={showFilter}>
        <div className='col-12 col-sm-3'>
          <span
            className='manageResults_filters'
            onClick={handleFilterClick}
          >
            <FormattedMessage id="Components.ManageResult.Filter"/> ({filters}) <FormattedMessage id="Components.ManageResult.Filter.Sumbol"/>
          </span>
        </div>
      </When>
    </div>
  </div>
)
