
import React, { Children, cloneElement } from 'react'
import { compose, defaultProps, pure, setDisplayName, setPropTypes, withHandlers } from 'recompose'
import { DEFAULT_PROPS, PROP_TYPES } from './config'
import { handleClearClick } from './lib'

const enhance = compose(
  setDisplayName('views/components/Clearer'),
  setPropTypes(PROP_TYPES),
  defaultProps(DEFAULT_PROPS),
  withHandlers({
    handleClearClick
  }),
  pure
)

export default enhance(({
  children,
  clearText,
  handleClearClick,
  ...props
}) =>
  <div className='clearer'>
    <span
      className='clearer_clear'
      onClick={handleClearClick}
    >
      {clearText}
    </span>
    {Children.map(children, child => cloneElement(
      child,
      props
    ))}
  </div>
)
