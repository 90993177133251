
import { Breadcrumbs, Loader } from 'views/components'
import { ButtonDark, ButtonSharp } from 'views/components/buttons'
import { FieldsSet } from 'views/components/forms'
import { PlusList } from 'views/components/lists'
import { Modal } from 'views/components/modals'
import { Carousel } from 'views/components/sliders'
import { HdTitle2, NotFound, Price } from 'views/components/texts'
import { ALIGN } from 'data/constants'
import { fetchGoods } from 'helpers/products'
import { dataLoad } from 'helpers/redux'
import { get } from 'lodash'
import { ProductPage } from 'views/pages'
import React from 'react'
import CurrencyInput from 'react-currency-input-formatter'
import { Else, If, Then, When } from 'react-if'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { compose, mapProps, pure, setDisplayName, withHandlers, withState } from 'recompose'
import { ACTIONS } from './config'
import { dataLoader, handleCategoryAttrs, handleCloseUpsellModal, handlePlusClick, mapState, propsMapper, handleAddProductToShoppingCart } from './lib'
import { FormattedMessage } from 'react-intl'

const enhance = compose(
  setDisplayName('views/routes/product/Page'),
  dataLoad(dataLoader),
  connect(mapState, ACTIONS),
  withHandlers({ fetchGoods }),
  withState('categoryAttrValues', 'setCategoryAttrValue', {}),
  withState('upsellModal', 'toggleUpsellModal', false),
  mapProps(propsMapper),
  withHandlers({
    handleCategoryAttrs,
    handleCloseUpsellModal,
    handlePlusClick,
    handleAddProductToShoppingCart
  }),
  pure
)

export default enhance(({
  cartQuantity,
  categoryAttributesFields,
  currency,
  description,
  forWhom,
  handleCategoryAttrs,
  handleCloseUpsellModal,
  handlePlusClick,
  handleBookClick,
  handleAddProductToShoppingCart,
  images,
  level,
  orderTotal,
  otherProducts,
  price,
  productMeta,
  shop,
  title,
  upsellModal,
  isLoaded
}) =>
  <ProductPage
    cartQuantity={cartQuantity}
    className='productPage'
    productMeta={productMeta}
  >
    <Breadcrumbs
      items={[
        { label: 'Go back', path: '/search/products' }
      ]}
    />
    <HdTitle2 align={ALIGN.CENTER}>
      {title}
    </HdTitle2>
    <div className='productPage_data row'>
      <div className='col-12 col-sm-5'>
        <If condition={!!images.length}>
          <Then>
            <Carousel
              images={images}
              modification={1}
            />
          </Then>
          <Else>
            <FormattedMessage id="ProductPage.Content.NotFound">
              {
                (msg) => (
                  <NotFound text={msg} />
                )
              }
            </FormattedMessage>
          </Else>
        </If>
      </div>
      <div className='col-12 col-sm-7 productPage_content'>
        <p>
          <FormattedMessage id="ProductPage.Content.For" /> {forWhom}
          <br />
          <FormattedMessage id="ProductPage.Content.Level" /> {level}
          <br />
          <FormattedMessage id="ProductPage.Content.Shop" /> {shop}
        </p>
        <p>
          <FormattedMessage id="ProductPage.Content.Description" />
          <br />
          {description}
        </p>
        <Price
          currency={currency}
          value={price}
        />
        <Loader message={<FormattedMessage id="Product.Loading" />} isLoaded={isLoaded} />
        <When condition={get(categoryAttributesFields, 'length', 0) > 0}>
          <div className='productPage_button'>
            <FieldsSet
              hideControl
              fields={categoryAttributesFields}
              onChange={handleCategoryAttrs}
            />
          </div>
        </When>
      </div>
    </div>
    <section className='productPage_bottom'>
      <Link to='/confirm'>
        <ButtonSharp
          children={<FormattedMessage id="Products.Product.Book" />}
          mod='1'
          onClick={handleAddProductToShoppingCart}
        />
      </Link>
      {/* <ButtonPlus onClick={handlePlusClick} /> */}
    </section>
    {upsellModal ? (
      <Modal
        children={
          <article className='productPage_modal'>
            <FormattedMessage id="ProductPage.Content.Modal.OrderTotal" /> <span className='productPage_modal_price'>
              <CurrencyInput
                value={orderTotal}
                prefix={currency}
                decimalSeparator="."
                thousandSeparator=","
                precision="2"
              />
            </span>
            <br />
            <FormattedMessage id="ProductPage.Content.Modal.AddProducts" />
            <PlusList
              clickItem={(item, i) => { console.log(item, i) }}
              items={otherProducts}
            />
            <footer className='productPage_modal_footer'>
              <Link to='/confirm'>
                <FormattedMessage id="ProductPage.Content.Modal.Buttons.Checkout">
                  {
                    (msg) => (
                      <ButtonDark children={msg} />
                    )
                  }
                </FormattedMessage>
              </Link>
            </footer>
          </article>
        }
        onClose={handleCloseUpsellModal}
      />
    ) : null}
  </ProductPage>
)
