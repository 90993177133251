
import { methodFromProps } from 'helpers'
import { get } from 'lodash'

export default props => values => {
  const fetchGoods = methodFromProps('fetchGoods', props)
  values && fetchGoods({
    attributes: get(props, 'categoryAttributes.allByCategory', []),
    formValues: values
  })
}
