
import { ASYNC_STATUSES } from 'data/constants'

export default ({
  errorMessage,
  successMessage,
  ...props
}) => {
  let messageOnComplete
  switch (props.status) {
    case ASYNC_STATUSES.ERROR:
      messageOnComplete = errorMessage
      break

    case ASYNC_STATUSES.SUCCESS:
      messageOnComplete = successMessage
      break

    default:
      messageOnComplete = ''
  }

  return {
    ...props,
    isPending: props.status === ASYNC_STATUSES.PENDING,
    messageOnComplete
  }
}
