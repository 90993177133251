
import { AUTH_REDIRECTS } from 'data/constants'
import { methodFromProps } from 'helpers'
import { get } from 'lodash'
import React, { PureComponent } from 'react'
import { compose, setDisplayName } from 'recompose'

const enhance = compose(
  setDisplayName('helpers/router/authOnly')
)

/**
 * @param {Object} config
 * @param {Boolean} config.authorized
 * @param {String} config.pathOut - where to redirect if user is not authorized
*/
export default config => Cmp => {
  class AuthOnly extends PureComponent {
    componentDidMount () {
      const authorized = get(config, 'authorized')
      const pathOut = get(config, 'pathOut')
      const push = methodFromProps('history.push', this.props)

      if (!authorized) {
        push(pathOut || AUTH_REDIRECTS.IN)
      }
    }

    render () {
      return (
        <Cmp {...this.props} />
      )
    }
  }

  return enhance(AuthOnly)
}
