
import classnames from 'classnames'
import { ProductMeta } from 'views/components'
import { Page } from 'views/pages'
import React from 'react'
import { compose, pure, setDisplayName, setPropTypes } from 'recompose'
import { PROP_TYPES } from './config'

const enhance = compose(
  setDisplayName('pages/ProductPage'),
  setPropTypes(PROP_TYPES),
  pure
)

export default enhance(({
  cartQuantity,
  children,
  className,
  productMeta
}) =>
  <Page
    cartQuantity={cartQuantity}
    className={classnames({
      [className]: !!className
    })}
  >
    <ProductMeta
      {...productMeta}
    />
    <section className='container'>
      {children}
    </section>
  </Page>
)
