
import { omit } from 'lodash'
import Checkbox from 'rc-checkbox'
import React from 'react'
import { compose, pure, setDisplayName, setPropTypes } from 'recompose'
import uniqid from 'uniqid'
import { PROP_TYPES } from './config'

const enhance = compose(
  setDisplayName('views/components/forms/Checkbox'),
  setPropTypes(PROP_TYPES),
  pure
)

export default enhance(({
  input,
  label,
  labelStyle,
  small,
  ...props
}) => {
  const id = uniqid()

  return (
    <span className='checkbox'>
      <Checkbox
        id={id}
        {...input}
        {...omit(props, ['meta'])}
      />
      {label ? (
        <label
          className='checkbox_label'
          htmlFor={id}
          style={{
            fontSize: `${small ? 0.8125 : 1}em`,
            ...labelStyle
          }}
        >
          {label}
        </label>
      ) : null}
    </span>
  )
})
