import { getClientOrders, getOrderByRandomId } from 'redux/modules/orders/actions'
import { get } from 'lodash'

export default props => {
  var loaders = []

  if (props.store !== undefined) {
    const state = props.store.getState()
    const Ids = get(state, 'orders.orderIds.data')

    if (orderId !== null && orderId !== undefined) {
      loaders = [
        {
          action: getClientOrders,
          payload: Ids
        }
      ]

      return loaders
    }
  }

  const orderId = get(props, 'match.params.order')
    loaders = [
      {
        action: getOrderByRandomId,
        payload: orderId
      }
    ]
 
  return loaders
}