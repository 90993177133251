
import PropTypes from 'prop-types'

export const PROP_TYPES = {
  date: (props, propName) => {
    const template = /\d{2}\/\d{2}\/\d{4}/
    const date = props[propName]
    if (date.length !== 10 || !template.test(date)) {
      return new Error(`The date value "${date}" should have format "DD/MM/YYYY"`)
    }
  },
  message: PropTypes.string,
  time: (props, propName) => {
    const template = /\d{2}:\d{2}:\d{2}/
    const time = props[propName]
    if (time.length !== 8 || !template.test(time)) {
      return new Error(`The time value "${time}" should have format "HH:mm:ss"`)
    }
  }
}
