
import { Service } from 'views/components'
import React from 'react'
import { compose, defaultProps, pure, setDisplayName, setPropTypes } from 'recompose'
import { DEFAULT_PROPS, PROP_TYPES } from './config'

const enhance = compose(
  setDisplayName('views/components/lists/WorkFloatList'),
  setPropTypes(PROP_TYPES),
  defaultProps(DEFAULT_PROPS),
  pure
)

export default enhance(({
  items
}) =>
  <div className='workfloat-list container'>
    <ul className='row'>
      {items.map((item, key) =>
        <li
          className='col-12 col-md-4'
          key={key}
        >
          <Service
            {...item}
            number={key + 1}
          />
        </li>
      )}
    </ul>
  </div>
)
