
import PropTypes from 'prop-types'
import { ALIGN } from 'data/constants'
import { pick, values } from 'lodash'

export const DEFAULT_PROPS = {
  message: '',
  position: ALIGN.BOTTOM
}

export const PROP_TYPES = {
  className: PropTypes.string,
  message: PropTypes.object,
  position: PropTypes.oneOf(values(pick(ALIGN, ['BOTTOM', 'TOP']))),
  size: PropTypes.number,
  isLoaded: PropTypes.bool
}
