
import { SearchExtended } from 'views/components/forms'
import { get } from 'lodash'
import React from 'react'
import { Icon } from 'react-icons-kit'
import { androidClose } from 'react-icons-kit/ionicons/androidClose'
import { androidCreate } from 'react-icons-kit/ionicons/androidCreate'
import { compose, mapProps, pure, setDisplayName, setPropTypes, withHandlers, withState } from 'recompose'
import { FORM_NAME, PROP_TYPES } from './config'
import { handleEditClick, handleSubmitForm, propsMapper } from './lib'
import { FormattedMessage } from 'react-intl'

const enhance = compose(
  setDisplayName('views/components/ProductMeta'),
  setPropTypes(PROP_TYPES),
  withState('opened', 'open', false),
  mapProps(propsMapper),
  withHandlers({
    handleEditClick,
    handleSubmitForm
  }),
  pure
)

export default enhance(({
  categories,
  category,
  dateString,
  deliveryAddress,
  handleEditClick,
  handleSubmitForm,
  initialFormValues,
  opened,
  selectLookingFor
}) =>
  <div className='productMeta'>
    <div className='productMeta_top'>
      <div className='container'>
        <div className='productMeta_content'>
          <FormattedMessage id="ProductMeta.Category"/> { 
            // eslint-disable-next-line
            typeof category === 'string' ? category.toString().replace(/\-/g,'') : category 
          }
          <br />
          <FormattedMessage id="ProductMeta.DeliverTo"/> {get(deliveryAddress, 'label', '')}
          <br />
          <FormattedMessage id="ProductMeta.Date"/> {dateString}
          <div className='productMeta_edit'>
            <Icon
              className='productMeta_edit_icon'
              icon={opened ? androidClose : androidCreate}
              onClick={handleEditClick}
              size={35}
            />
          </div>
        </div>
      </div>
    </div>
    {opened ? (
      <div className='productMeta_flat'>
        <div className='container'>
          <SearchExtended
            form={FORM_NAME}
            initialValues={initialFormValues}
            lookFor={categories}
            onSubmit={handleSubmitForm}
            selectLookingFor={selectLookingFor}
          />
        </div>
      </div>
    ) : null}
  </div>
)
