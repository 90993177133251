import { handleActions } from 'redux-actions'
import { success, error } from 'helpers/redux/actionStateTypes'
import * as cx from './constants'
import { get } from 'lodash'

const initialState = {
  ordersPage: [],
  clientOrders: [],
  orderIds: [],
  orderDetails: {},
  error: {}
}

export default handleActions({
  [success(cx.GET_ORDERS_PAGE_FOR_CLIENT)]: (state, action) => ({
    ...state,
    ordersPage: get(action, 'payload', [])
  }),
  [error(cx.GET_ORDERS_PAGE_FOR_CLIENT)]: (state, action) => ({
    ...state,
    error: get(action, 'payload', [])
  }),
  [success(cx.GET_CLIENT_ORDERS)]: (state, action) => ({
    ...state,
    clientOrders: get(action, 'payload', [])
  }),
  [error(cx.GET_CLIENT_ORDERS)]: (state, action) => ({
    ...state,
    error: get(action, 'payload', [])
  }),
  [success(cx.CREATE_ORDERS_FROM_SHOPPING_CART)]: (state, action) => ({
    ...state,
    orderIds: get(action, 'payload', [])
  }),
  [error(cx.CREATE_ORDERS_FROM_SHOPPING_CART)]: (state, action) => ({
    ...state,
    error: get(action, 'payload', [])
  }),
  [success(cx.GET_ORDER_BY_RANDOM_ID)]: (state, action) => ({
    ...state,
    orderDetails: get(action, 'payload', [])
  }),
  [error(cx.GET_ORDER_BY_RANDOM_ID)]: (state, action) => ({
    ...state,
    error: get(action, 'payload', [])
  }),
}, initialState)