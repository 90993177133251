
import emailValidator from 'email-validator'
import { checker } from 'factories/AddressGoogle'
import { FORM_ERRORS } from 'data/constants'

export default values => {
  const errors = {}
  if (values) {
    if (!values.address) {
      errors.address = FORM_ERRORS.REQUIRED
    } else if (!checker(values.address)) {
      errors.address = FORM_ERRORS.SELECT_FROM_LIST
    }

    if (!values.countryPrefix) {
      errors.countryPrefix = FORM_ERRORS.REQUIRED
    }

    if (!values.email) {
      errors.email = FORM_ERRORS.REQUIRED
    } else if (!emailValidator.validate(values.email)) {
      errors.email = FORM_ERRORS.INCORRECT_VALUE
    }

    if (!values.firstName) {
      errors.firstName = FORM_ERRORS.REQUIRED
    }
    if (!values.lastName) {
      errors.lastName = FORM_ERRORS.REQUIRED
    }
    if (!values.phoneNumber) {
      errors.phoneNumber = FORM_ERRORS.REQUIRED
    }
  }
  return errors
}
