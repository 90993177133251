
import { FORM_ERRORS } from 'data/constants'

export default values => {
  const errors = {}
  if (!values.address) {
    errors.address = FORM_ERRORS.REQUIRED
  } else if (typeof values.address === 'string') {
    errors.address = FORM_ERRORS.SELECT_FROM_LIST
  }
  if (!values.from) {
    errors.from = FORM_ERRORS.REQUIRED
  }
  if (!values.to) {
    errors.to = FORM_ERRORS.REQUIRED
  }
  return errors
}
