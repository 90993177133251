
import { methodFromProps } from 'helpers'
import { getSelectedFromAttrByIdx } from 'helpers/categoryAttributes'
import { cloneDeep, get } from 'lodash'

export default props => data => () => {
  const attributes = cloneDeep(get(props, 'categoryAttributes.allByCategory', []))
  const clearAttributeValues = methodFromProps('clearAttributeValues', props)
  const fetchGoods = methodFromProps('fetchGoods', props)
  const selectedInAttr = getSelectedFromAttrByIdx(attributes, data.attrKey)
  if (selectedInAttr.length) {
    clearAttributeValues(data.attrKey)
    fetchGoods({
      attributes: attributes.map((attr, attrIdx) => {
        if (attrIdx === data.attrKey) {
          get(attr, 'categoryAttributeValues', []).forEach(cav => {
            cav.selected = false
          })
        }
        return attr
      }),
      formValues: cloneDeep(props.searchFormValues)
    })
  }
}
