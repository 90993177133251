
import PropTypes from 'prop-types'

export const DEFAULT_PROPS = {
  items: []
}

export const PROP_TYPES = {
  clickItem: PropTypes.func,
  items: PropTypes.arrayOf(PropTypes.shape({
    chosen: PropTypes.bool,
    label: PropTypes.string
  }))
}
