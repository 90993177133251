import React from 'react'
import { compose, pure, setDisplayName, withHandlers, setPropTypes } from 'recompose'
import { handleCurrencyChange, mapState } from './lib'
import { Dropdown, DropdownToggler, ImageRound } from 'views/components'
import { Menu } from 'views/components/lists'
import { If, Then } from 'react-if'
import { PROP_TYPES, ACTIONS } from './config'
import { connect } from 'react-redux'

const enhance = compose(
  setDisplayName('views/components/Currencies'),
  setPropTypes(PROP_TYPES),
  connect(mapState, ACTIONS),
  withHandlers({
    handleCurrencyChange
  }),
  pure
)

export default enhance(({
  currencies,
  handleCurrencyChange,
  ...props
}) =>
    <Dropdown
      childrenPosition={{ right: 0 }}
      toggler=
        {
          <DropdownToggler>
            <If condition={localStorage.currency === 'EUR'}>
              <Then>
                <ImageRound
                  size={46}
                  src={require('theme/images/euro-icon.png')}
                />
              </Then>
            </If>
            <If condition={localStorage.currency === 'USD' || localStorage.currency === undefined}>
              <Then>
                <ImageRound
                  size={46}
                  src={require('theme/images/dollar-icon.png')}
                />
              </Then>
            </If>
            <If condition={localStorage.currency === 'GBP'}>
              <Then>
                <ImageRound
                  size={46}
                  src={require('theme/images/pound-icon.png')}
                />
              </Then>
            </If>           
          </DropdownToggler>
        }
     >
       <Menu items={currencies.map((currency, key) => ({
                      label: currency.name,
                      onClick: handleCurrencyChange
                    }))}
       />         
     </Dropdown>
  )