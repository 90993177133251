import { currencies } from 'data'

export default props => (currency) => {
    const currencyItems = props.currencies
    const value = currency.target.text
    const currencyCode = currencyItems.find(i => {return i.name === value})
    if (currencyCode === 'undefined') {
        localStorage.currency = currencies.USD
    }
    else {
        localStorage.currency = currencyCode.code
    }
    window.location.reload()
}