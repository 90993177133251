
import { Basket, Logo } from 'views/components'
import React from 'react'
import { compose, pure, setDisplayName } from 'recompose'
import { Blocks, Buttons, Forms, Lists, Modals, Sliders, Texts } from './components'

const enhance = compose(
  setDisplayName('views/routes/_lib/Page'),
  pure
)

export default enhance(() =>
  <div>
    <Basket />
    <br />
    <Logo alt='Logo alt' />
    <br />
    <Blocks heading='Blocks:' />

    <Buttons heading='Buttons:' />

    <Forms title='Forms:' />

    <Lists title='Lists:' />

    <Modals title='Modals:' />

    <Sliders title='Sliders:' />

    <Texts heading='Text elements:' />
  </div>
)
