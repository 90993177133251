
import { ALIGN } from 'data/constants'
import { values } from 'lodash'
import PropTypes from 'prop-types'

export const DEFAULT_PROPS = {
  align: ALIGN.LEFT
}

export const PROP_TYPES = {
  align: PropTypes.oneOf(values(ALIGN)),
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string
  ])
}
