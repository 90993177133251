
import { methodFromProps } from 'helpers'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

/**
 * @param {(Object[]|Function)} config - must return an array if it is a function
 * @param {Function} config[].action - action creator
 * @param {Object} config[].payload - payload for the action creator
 * @param {string} config[].type - action type
*/
export default config => Component => {
  class DataLoad extends PureComponent {
    componentDidMount() {
      let configRes = []
      const dispatch = methodFromProps('dispatch', this.props)

      if (config instanceof Array) {
        configRes = config
      }
      if (config instanceof Function) {
        configRes = config(this.props)
      }

      configRes.forEach(cfg => {
        const { action, payload, type } = cfg
        const toDispatch = typeof action === 'function'
          ? action(payload)
          : { type, payload }

        dispatch(toDispatch)
      })
    }

    render() {
      return (
        <Component {...this.props} />
      )
    }
  }

  return connect(null)(DataLoad)
}
