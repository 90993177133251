
import classnames from 'classnames'
import { Checkbox, Input, Select } from 'views/components/forms'
import { methodFromProps } from 'helpers'
import { get } from 'lodash'
import React from 'react'
import { Icon } from 'react-icons-kit'
import { minus } from 'react-icons-kit/fa/minus'
import { plus } from 'react-icons-kit/fa/plus'
import { Else, If, Then, Unless, When } from 'react-if'
import { compose, defaultProps, mapProps, pure, setDisplayName, setPropTypes, withHandlers, withState } from 'recompose'
import { Field } from 'redux-form'
import { DEFAULT_PROPS, PROP_TYPES } from './config'
import { commonProps, handleMainControlChange, handleOpenClick, propsMapper } from './lib'

const enhance = compose(
  setDisplayName('views/components/forms/FieldsSet'),
  setPropTypes(PROP_TYPES),
  defaultProps(DEFAULT_PROPS),
  withState('opened', 'open', props => props.initOpened),
  mapProps(propsMapper),
  withHandlers({
    handleMainControlChange,
    handleOpenClick
  }),
  pure
)

export default enhance(({
  checkboxLabelStyle,
  fields,
  handleMainControlChange,
  handleOpenClick,
  heading,
  hideControl,
  inReduxForm,
  opened
}) =>
  <article className='fields_set'>
    <When condition={!!heading}>
      <h1 className='fields_set_hd'>
        {heading}
      </h1>
    </When>
    <When condition={fields.length > 0}>
      <div className='fields_set_fields'>
        <Unless condition={hideControl}>
          <div className='fields_set_fields_toggle'>
            <Icon
              icon={opened ? minus : plus}
              onClick={handleOpenClick}
              style={{
                cursor: 'pointer',
                float: 'right'
              }}
            />
          </div>
        </Unless>
        <When condition={hideControl || opened}>
          <ul className='fields_set_fields_ul'>
            {fields.map((field, key) => {
              const MainControl = field.options ? Select : Input
              const tipLabel = get(field.tip, 'label', '')

              return (
                <li
                  className={classnames({
                    has_tip: !!tipLabel
                  })}
                  key={key}
                >
                  <If condition={get(field, 'checkboxes.length', 0) > 0}>
                    <Then>
                      <ul className='fields_set_fields_checkboxes'>
                        {get(field, 'checkboxes', []).map((checkbox, key) =>
                          <li key={key}>
                            <If condition={inReduxForm}>
                              <Then>
                                <Field
                                  component={Checkbox}
                                  {...commonProps(checkbox)}
                                  labelStyle={checkboxLabelStyle}
                                />
                              </Then>
                              <Else>
                                <Checkbox
                                  {...commonProps(checkbox)}
                                  labelStyle={checkboxLabelStyle}
                                />
                              </Else>
                            </If>
                          </li>
                        )}
                      </ul>
                    </Then>
                    <Else>
                      <div className={classnames('fields_set_fields_controls', {
                        has_units: !!field.units
                      })}>
                        <div className='fields_set_fields_controls_input'>
                          <If condition={inReduxForm}>
                            <Then>
                              <Field
                                component={MainControl}
                                {...commonProps(field)}
                              />
                            </Then>
                            <Else>
                              <MainControl
                                {...commonProps(field)}
                                onChange={handleMainControlChange(field)}
                                value={field.value}
                              />
                            </Else>
                          </If>
                        </div>
                        <When condition={!!field.units}>
                          <div className='fields_set_fields_controls_units'>
                            <div className='fields_set_fields_controls_units_in'>
                              <If condition={inReduxForm}>
                                <Then>
                                  <Field
                                    component={Select}
                                    {...commonProps(field.units)}
                                  />
                                </Then>
                                <Else>
                                  <Select {...commonProps(field.units)} />
                                </Else>
                              </If>
                            </div>
                          </div>
                        </When>
                      </div>
                    </Else>
                  </If>
                  <When condition={!!field.tip}>
                    <p className='fields_set_fields_tip'>
                      <span
                        className='fields_set_fields_tip_txt'
                        onClick={methodFromProps('onClick', field.tip)}
                      >
                        {tipLabel}
                      </span>
                    </p>
                  </When>
                  <When condition={!!field.error}>
                    <span
                      className='fields_set_fields_error'
                      style={{
                        bottom: (field.tip && field.checkboxes)
                          ? 12
                          : field.tip
                          ? 5
                          : field.checkboxes
                          ? -7
                          : -16
                      }}
                    >
                      {field.error}
                    </span>
                  </When>
                </li>
              )
            })}
          </ul>
        </When>
      </div>
    </When>
  </article>
)
