
import { get } from 'lodash'
import React from 'react'
import { Icon } from 'react-icons-kit'
import { chevronDown } from 'react-icons-kit/fa/chevronDown'
import { chevronUp } from 'react-icons-kit/fa/chevronUp'
import { compose, pure, setDisplayName, setPropTypes } from 'recompose'
import { PROP_TYPES } from './config'

const enhance = compose(
  setDisplayName('views/components/DropdownToggler'),
  setPropTypes(PROP_TYPES),
  pure
)

export default enhance(({
  children,
  dropdown
}) =>
  <div className='dropdownToggler'>
    {children}
    <figure className='dropdownToggler_icon'>
      <Icon
        className='dropdownToggler_icon_img'
        icon={get(dropdown, 'opened') ? chevronUp : chevronDown}
        size={16}
      />
    </figure>
  </div>
)
