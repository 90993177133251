
import { createAction } from 'redux-actions'
import * as cx from './constants'

/**
 * @param {Number} productId - the product ID
 * @param {Array} values - the category attribute values
 */
export const getProductData = createAction(cx.GET_PRODUCT_DATA, productId => ({ productId }))

export const getProductAttributeIdByValues = createAction(cx.GET_PRODUCT_ATTRIBUTE_ID_BY_VALUES, ({
    productId,
    values
  }) => ({
    productId,
    values
  }))
