import { FormattedMessage } from 'react-intl'
import React from 'react'
import { Icon } from 'react-icons-kit'
import { androidPin } from 'react-icons-kit/ionicons/androidPin'
import { compose, pure, setDisplayName, setPropTypes } from 'recompose'
import { PROP_TYPES } from './config'

const enhance = compose(
  setDisplayName('views/components/DeliverTo'),
  setPropTypes(PROP_TYPES),
  pure
)

export default enhance(({
  address
}) =>
  <div className='deliverTo'>
    <div className='container deliverTo_cnt'>
      <Icon
        icon={androidPin}
        size={25}
      />
      <FormattedMessage id="ProductMeta.DeliverTo"/> {address}
    </div>
  </div>
)
