
import PropTypes from 'prop-types'

export const DEFAULT_PROPS = { items: [] }

export const PROP_TYPES = {
  items: PropTypes.arrayOf(PropTypes.shape({
    description: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.element
    ]),
    heading: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.element
    ]),
    image: PropTypes.shape({
      alt: PropTypes.string,
      path: PropTypes.string
    })
  }))
}
