
import { get } from 'lodash'
import React from 'react'
import { Link } from 'react-router-dom'
import { compose, pure, setDisplayName, setPropTypes } from 'recompose'
import { PROP_TYPES } from './config'

const enhance = compose(
  setDisplayName('views/components/Breadcrumbs'),
  setPropTypes(PROP_TYPES),
  pure
)

export default enhance(({
  items
}) =>
  <ul className='breadcrumbs'>
    {items.map((item, key) =>
      <li key={key}>
        <Link to={get(item, 'path', '')}>
          {get(item, 'label', '')}
        </Link>
      </li>
    )}
  </ul>
)
