
import { ASYNC_STATUSES } from 'data/constants'
import { omit } from 'lodash'
import { createAction } from 'redux-actions'
import * as cx from './constants'

const setStatus = createAction(
  cx.SET_STATUS,
  payload => omit(payload, ['meta']),
  ({ meta }) => meta
)

export const dropStatus = createAction(cx.DROP_STATUS)

export const error = (type, meta) => setStatus({
  meta,
  status: ASYNC_STATUSES.ERROR,
  type
})

export const pending = type => setStatus({
  status: ASYNC_STATUSES.PENDING,
  type
})

export const success = type => setStatus({
  status: ASYNC_STATUSES.SUCCESS,
  type
})
