export default {
        "Forms.Search2.Placeholder": "Search around me",
        "InfoList.AboutUs": "About us",
        "InfoList.Faq": "Faq",
        "InfoList.TermsAndConditions": "Terms & Conditions",
        "Lists.Menu.WithoutPath": "Without path",
        "Lists.Menu.InsidePath": "Inside path",
        "Lists.Menu.OutsidePath": "Outside path",
        "Lists.Menu.OpenPage": "Opens in a new page",
        "UserMenu.ShopNow": "Shop Now",
        "UserMenu.OrderHistory": "Order History",
        "UserMenu.Profile": "Profile",
        "UserMenu.ResetPassword": "Reset Password",
        "UserMenu.Logout": "Logout",
        "UserMenu.Buttons.Label.Login": "Login",
        "Header.WorkWithUs": "Work With Us",
        "Footer.GetInTouch": "Get In Touch",
        "Footer.AllRightsReserved": "All rights Reserved 2017",
        "ProductsList.SkisAndBoards": "Skis & boards",
        "ProductsList.Boots": "Boots",
        "ProductsList.Accessories": "Accessories",
        "WorkFloatList.Search.Description": "Explore skis up to 10km around you!",
        "WorkFloatList.Rent.Description": "Book your favorite skis at the lowest prices!",
        "WorkFloatList.Receive.Description": "Meet our driver at your favorite location and enjoy your skis!",
        "WorkFloatList.Search": "Search",
        "WorkFloatList.Rent": "Rent",
        "WorkFloatList.Receive": "Receive",
        "App.Loading": "Loading the application...",
        "FormErrors.IncorrectValue:": "Incorrect value",
        "FormErrors.NewPassword": "Must match new password",
        "FormErrors.Required": "*Required",
        "FormErrors.SelectFromList": "Select from list",
        "FormErrors.SpecialCharacters": "Must contain special characters",
        "NotFound.Default": "Sorry, we did not find any results matching your search criteria",
        "PointItems.ChooseShopsAround.Description": "Ability To Explore And Choose Skis And Products From Dozen Of Shops Up To 10km Around",
        "PointItems.ComparePriceAndProducts.Description": "Price And Products Comparison Among Different Shops",
        "PointItems.NoMoreQueueing.Description": "No More Queueing In Over-Crowded Shops Thanks To On-Demand Drivers That Will Deliver Skis & Accessories When And Where The Skiers Want!",
        "Fields.CardName.Placeholder": "Cardholder name",
        "PasswordChange.ErrorMessage": "Password change failed. Try again",
        "PasswordChanged.SuccessMessage": "Password changed successfully",
        "HeadedForm.Buttons.ResetPassword": "Reset password",
        "Fields.FirstName": "First Name",
        "Fields.LastName": "Last Name",
        "Fields.Email": "Email",
        "Fields.CountryPrefix": "Country Prefix",
        "Fields.PhoneNumber": "Phone Number",
        "Fields.HomeAddress": "Home Address",
        "Fields.HomeAddress.Placeholder": "Enter a location",
        "UserProfile.Buttons.UpdateCard": "Update Card",
        "UserProfile.Header.UpdateCard": "Update card",
        "UserProfile.Buttons.SaveChanges": "Save Changes",
        "UserProfile.Buttons.Cancel": "Cancel",
        "MainOffer.SkiHire.Description": "Delivered Where You Need",
        "MainOffer.SkiHire": "Ski Hire",
        "MainOffer.SkiHire.Label": "Book Your Favorite Ski",
        "MainOffer.SkiHire.Label2": "Delivery Service",
        "Forms.Search.Placeholder": "Enter delivery address",
        "Components.Card.Error.DateFormat":'The date value should have format "DD/MM/YYYY"',
        "Components.Card.Error.TimeFormat":'The time value should have format "HH:mm:ss"',
        "CartSummary.Cart": " Cart:",
        "CartSummary.Total": "Total:",
        "CartSummary.Buttons.ClearCart": "clear cart",
        "ProductMeta.Category":"Category:",
        "ProductMeta.DeliverTo": "Deliver to",
        "ProductMeta.Date": "Date:",
        "Fields.CustomerName.Placeholder": "Customer name",
        "Forms.Delivery.DeliveryTime.Label": "Delivery Time",
        "Forms.Delivery.DeliveryTime.Placeholder": "Select a delivery time",
        "Forms.Delivery.ReturnAddress.Label": "Return address",
        "Forms.Delivery.ReturnAddress.Placeholder": "Enter return address",
        "Forms.Delivery.ReturnTime.Label": "Return time",       
        "Forms.Delivery.ReturnTime.Placeholder": "Select a return time",
        "SearchExtended.GeoSuggestInputIconed.Label": "Delivery Address",
        "SearchExtended.SelectIconed.Label": "Looking for?",
        "SearchExtended.Buttons.Search": "Search",
        "SearchExtended.Label.To": "To",
        "Components.LookingIn": "You are looking in",
        "Components.ManageResult.Filter": "Filter",
        "Components.ManageResult.Filter.Sumbol": ">>",
        "Components.Paginator.Page": "Page",
        "Components.Paginator.Page.Of": "of",
        "ProductCart.Content.Edit": "edit",
        "ProductCart.Content.ProductSettings": "Product Settings",
        "ProductCart.Content.Quantity": "Quantity",
        "ProductPage.Content.For": "For:",
        "ProductPage.Content.Level": "Level:",
        "ProductPage.Content.Shop": "Shop:",
        "ProductPage.Content.Description": "Description:",
        "Components.Sorter": "Sort by",
        "WorkFloatList.HowItWorks": "How It Works",
        "ProductsPage.Content.OurProducts": "Our Products",
        "HeadedForm.Fields.OldPassword.Placeholder": "Old Password",
        "HeadedForm.Fields.NewPassword.Placeholder": "New Password",
        "HeadedForm.Fields.ConfirmPassword.Placeholder": "Confirm Password",
        "Components.Blocks.InnerBanner.Title": "Banner title",
        "Components.Blocks.ProductMeta.DeliveryAddress.Label": "Street, House, City, State",
        "Components.Blocks.ProductInCard.Title": "Product title",
        "Components.Blocks.ProductSec.Name": "Product Name",
        "Components.Blocks.Rollet.Title": "Rollet",
        "Components.Blocks.Scrolled.Title": "Scrolled",
        "AboutPage.Content.Decription": "Deliquity is an innovative platform that allows skiers to explore and rent skis and accessories from Shops within 10Km around and have them delivered, by on-demand drivers, at skiers&#39; favorite location at a specific date and time!",
        "AboutPage.Content.Invite": "Join deliquity today or for any further information don&#39;t hesitate to contact us at",
        "AboutPage.Content.JoinUs": "Join us",
        "LogOutPage.Content.Process": "Logout in process...",
        "AuthPage.Content.Process": "Authorization in process...",
        "CardPage.Content.ExtraProduct": "+Add extra product for",
        "CardPage.Content.Rollet.Title": "Delivery and Return Time",
        "CardPage.Content.Buttons.ContinueShopping": "Continue shopping",
        "CardPage.Content.Buttons.Checkout": "Checkout!",
        "ProductPage.Content.Breadcrumbs.Label": "Go back",
        "NotificationPage.Content.Name": "Notifications",
        "NotificationPage.Content.NotFound": "No notification found",
        "OrdersPage.Content.Name": "Orders Page",
        "ProductPage.Content.NotFound": "This product has no images yet",
        "ProductPage.Content.Modal.OrderTotal": "Order total:",
        "ProductPage.Content.Modal.AddProducts": "Do you want to add any of the following products?",
        "ProductPage.Content.Modal.Buttons.Checkout": "NO, bring me to checkout",
        "Search.Products.MoreResult": "OH...WE HAVE MORE RESULTS",
        "TermsPage.Title": "Welcome to Deliquity!",
        "TermsPage.Header": "Take the Terms of Use text from the database.",
        "FormErrors.MinLenght": "Password must have 8 characters or more",
        "UserPage.Content.SuccessUpdate": "Personal Info updated succesfully",
        "UserPage.Content.Title": "Personal Info",
        "Products.Product.Description": "Product description",
        "Products.Buttons.Delete": "Delete",
        "Products.Buttons.Location": "Location",
        "Products.Result":"Result",
        "Products.NotDefined": "Not Defined",
        "Select.None": "None",
        "Select.LowerPrice": "Lower Price",
        "Select.HigherPrice": "Higher Price",
        "Products.Product.Price": "Price :",
        "Products.Product.Book": "Book Now",
        "Customer.Button.Confirm": "Confirm customer details",
        "Customer.Name.Placeholder":"Customer name",
        "CheckoutPage.Buttons.PlaceOrder": "Place Order",
        "CheckoutPage.Rollet.Title": "Order Details",
        "Forms.Delivery.DeliveryAddress.Label": "Delivery address",
        "Forms.Delivery.DeliveryAddress.Placeholder": "Enter delivery address",
        "Forms.Delivery.SameAddress.Title": "Use same address for delivery and return",
        "Forms.Delivery.DeliveryDate": "Delivery Date",
        "Forms.Delivery.ReturnDate": "Return Date",
        "CheckoutPage.TotalPrice":"Total Price",
        "Forms.Delivery.CouponCode.Label": "Coupon Code",
        "Forms.Delivery.CouponCode.Placeholder": "Enter Coupon Code",
        "OrderPage.ItemsInOrder": "Items In Order",
        "OrderPage.PaymentStatus": "Payment Status",
        "OrderPage.DeliveryAddress": "Delivery Address",
        "OrderPage.ReturnAddress": "Return Address",
        "OrderPage.Total": "Total Price",
        "Product.Loading": "Product details is loading...",
        "ShoppingCart.Loading": "Shopping cart items is loading...",
        "Order.Loading": "Order is creating...",
        "Components.Table.Page": "Page",
        "Components.Table.Of": "of",
        "Components.Table.GoToPage": "Go to page",
        "Components.Table.Show": "Show",
        "Orders.Loading":"Orders is loading...",
        "OrderStatus.Pending": "Pending",
        "OrderStatus.Canceled": "Canceled",
        "OrderStatus.Active": "Active",
        "OrderStatus.Completed": "Completed",
        "OrderStatus.NotStarted": "Not Started",
        "OrderStatus.Blocked": "Blocked",
        "OrderStatus.UnBlocked": "UnBlocked",
        "OrderStatus.CancelledByUser": "Cancelled By User",
        "OrderStatus.CancelledByMerchant": "Cancelled By Merchant",
        "OrderStatus.CancelledByAdmin": "Cancelled By Admin",
        "OrderStatus.Delayed": "Delayed",
        "OrderStatus.Returned": "Returned",
        "OrderStatus.SelfReturn": "Self Return",
        "OrderStatus.SelfReturnedInProgress": "Self Return In Progress",
        "OrderStatus.ReturnInProgress": "Return In Progress",
        "OrderStatus.AwaitingForReturnPickUp": "Awaiting For Return Pick Up",
        "OrderStatus.AwaitingForReturnAcceptance": "Awaiting For Return Acceptance",
        "OrderStatus.Delivered": "Delivered",
        "OrderStatus.AwaitingForPickUp": "Awaiting For Pick Up",
        "OrderStatus.AwaitingForAcceptance": "Awaiting For Acceptance",
        "OrderStatus.DeliveryInProgress": "Delivery In Progress",
        "OrderDeliveryType.OnlyDelivery": "Only Delivery (Self Return)",
        "OrderDeliveryType.OnlyReturn": "Only Return (Take Away)",
        "OrderDeliveryType.DeliveryAndReturn": "Delivery & Return",
        "OrderDeliveryType.None": "Take Away & Self Return",
        "JobStatus.Accepted":"Accepted",
        "JobStatus.NotAssigned":"Not Assigned",
        "JobStatus.Assigned":"Assigned",
        "JobStatus.Completed":"Completed",
        "JobStatus.Cancelled":"Cancelled",
        "JobStatus.Collected":"Collected",
        "JobStatus.TakeAway":"Take Away",
        "JobStatus.SelfReturn":"Self Return",
        "JobStatus.Emergency":"Emergency",
        "JobStatus.Blocked":"Blocked",
        "JobStatus.Unblocked":"Unblocked",
        "OrderPage.DeliveryDate": "Delivery Date",
        "OrderPage.ReturnDate":"Return Date",
        "OrderPage.JobStatus":"Job Status",
        "OrderPage.DropAddress":"Drop Address",
        "OrderPage.PickUpAddress":"PickUp Address",
        "OrderDetails.Loading":"Order details is loading...",
        "OrderPage.OrderCreateSuccess":"Order is successfully create!",
        "OrderPage.Merchant.FullName":"Full Name",
        "OrderPage.Merchant.Email":"Email",
        "OrderPage.Merchant.PhoneNumber":"Phone Number",
        "OrderPage.Merchant.ShopName":"Shop Name",
        "OrderPage.Merchant.ShopAddress":"Shop Address",
        "ProductsPage.Modal.Buttons.ShoppingCart": "Basket",
        "ProductsPage.Modal.Title":"Add product to shopping cart",
        "ProductsPage.Modal.ProductName":"Product name:",
        "ProductsPage.Modal.Quantity":"Quantity:",
        "ProductsPage.Modal.RelatedProducts":"Related Products:",
        "LandingPage.LocationModal.Title": "Please choose location:",
        "LandingPage.LocationModal.ButtonSave": "Save",
        "LandingPage.LocationModal.DateTitle": "Select Dates:",
        "ProductsPage.Radius.Label": "Select radius of Merchant distance in km"
}