import { apiCall } from 'helpers'
import { makeLogic } from 'helpers/redux'
import * as cx from './constants'
import { API_DOMAINS } from 'data/constants'

const createOrdersFromShoppingCart = makeLogic({
    httpMethod: apiCall.post,
    path: `${API_DOMAINS.ORDER}/CreateOrdersFromShoppingCart`,
    processError: err => {
      return err
    },
    processResult: res => res.data,
    type: cx.CREATE_ORDERS_FROM_SHOPPING_CART
  })

  const getOrdersPageForClient = makeLogic({
    httpMethod: apiCall.get,
    latest: true,
    path: `${API_DOMAINS.ORDER}/get-orders-page-for-client`,
    payload: ({ payload }) => ({
      params: {
        Page: payload.page || cx.ORDERS_PAGE_DEFAULT_PARAMS.PAGE,
        PageSize: payload.pageSize || cx.ORDERS_PAGE_DEFAULT_PARAMS.PAGE_SIZE,
      }
    }),
    processError: err => {
      return err
    },
    processResult: res => res.data,
    type: cx.GET_ORDERS_PAGE_FOR_CLIENT
  })

  const getOrderItemsByOrderId = makeLogic({
    httpMethod: apiCall.get,
    latest: true,
    path: `${API_DOMAINS.ORDER}/get-order-items-by-order-id`,
    processError: err => {
      return err
    },
    processResult: res => res.data,
    type: cx.GET_ORDER_ITEMS_BY_ORDER_ID
  })

  const getClientOrders = makeLogic({
    httpMethod: apiCall.get,
    latest: true,
    path: `${API_DOMAINS.ORDER}/get-client-orders-by-Ids`,
    payload: ({ payload }) => ({ params: payload }),
    processError: err => {
      return err
    },
    processResult: res => res.data,
    type: cx.GET_CLIENT_ORDERS
  })

  const getOrderByRandomId = makeLogic({
    httpMethod: apiCall.get,
    latest: true,
    path: `${API_DOMAINS.ORDER}/get-merchant-order-details-by-random-order-id`,
    payload: ({ payload }) => ({ params: payload }),
    processError: err => {
      return err
    },
    processResult: res => res.data,
    type: cx.GET_ORDER_BY_RANDOM_ID
  })

  export default [ 
    createOrdersFromShoppingCart,
    getOrdersPageForClient,
    getOrderItemsByOrderId,
    getClientOrders,
    getOrderByRandomId
  ]