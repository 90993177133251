
import PropTypes from 'prop-types'

export const PROP_TYPES = {
  description: PropTypes.string,
  image: PropTypes.shape({
    alt: PropTypes.string,
    src: PropTypes.string
  })
}
