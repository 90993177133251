
import PropTypes from 'prop-types'

export const PROP_TYPES = {
  form: PropTypes.string.isRequired,
  lookFor: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string
    ])
  })),
  onSubmit: PropTypes.func.isRequired,
  selectLookingFor: PropTypes.func
}
