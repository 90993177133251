
import PropTypes from 'prop-types'
import { deleteShoppingCartItem } from 'redux/modules/shoppingCart/actions'

export const PROP_TYPES = {
  id: PropTypes.number,
  description: PropTypes.string,
  image: PropTypes.shape({
    alt: PropTypes.string,
    src: PropTypes.string
  }),
  price: PropTypes.number,
  quantity: PropTypes.number,
  title: PropTypes.string,
  itemSettings: PropTypes.arrayOf(PropTypes.shape({
    categoryAttributeId: PropTypes.number,
    categoryAttributeMultipleValues: PropTypes.string,
    categoryAttributeName: PropTypes.string,
    categoryAttributeSingleValue: PropTypes.string,
    controlType: PropTypes.number
  })),
}

export const ACTIONS = {
  deleteShoppingCartItem
}
