
import { methodFromProps } from 'helpers'
import { get, omit } from 'lodash'

export default props => values => {
  const updateClientProfile = methodFromProps('updateClientProfile', props)
  updateClientProfile({
    ...omit(values, ['address', 'countryPrefix']),
    address: get(values, 'address.label', ''),
    phoneCountryCode: values.countryPrefix
  })
}
