
import classnames from 'classnames'
import { Basket, Dropdown, DropdownToggler, ImageRound, Logo, Notifications, Multilanguage, Currencies } from 'views/components'
import { Button } from 'views/components/buttons'
import { InfoList, ListSocial, Menu } from 'views/components/lists'
import React from 'react'
import { Else, If, Then } from 'react-if'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { compose, defaultProps, getContext, mapProps, pure, setDisplayName, setPropTypes, withHandlers } from 'recompose'
import { CONTEXT_TYPES, DEFAULT_PROPS, INFO_OPS, PROP_TYPES, SOCIAL_OPS } from './config'
import { clickLogin, mapState, propsMapper, clickWorkWithUs } from './lib'
import { FormattedMessage } from 'react-intl'

const enhance = compose(
  setDisplayName('pages/Page'),
  setPropTypes(PROP_TYPES),
  defaultProps(DEFAULT_PROPS),
  getContext(CONTEXT_TYPES),
  connect(mapState),
  mapProps(propsMapper),
  withHandlers({
    clickLogin,
    clickWorkWithUs
  }),
  pure
)

export default enhance(({
  avatar,
  cartQuantity,
  children,
  className,
  clickLogin,
  clickWorkWithUs,
  unreadNotifications,
  user,
  userMenuItems,
  currencies,
  languages
}) =>
  <div className={classnames('page', {
    [className]: !!className
  })}>
    <div className='page-content'>
      <header className='header container'>
        <div className='header-cnt'>
          <Link to='/'>
            <Logo />
          </Link>
          <div className='header-info'>
          <div className='header-info-basket'>
              <Currencies currencies={currencies}/>
            </div>
            <div className='header-info-basket'>
              <Multilanguage languages={languages}/>
            </div>
            <div className='header-info-basket'>
              <Link to='/cart'>
                <Basket quantity={cartQuantity} />
              </Link>
            </div>
            <div className='header-info-btn'>
              <If condition={!!user}>
                <Then>
                  <Link to='/notifications'>
                    <Notifications quantity={unreadNotifications} />
                  </Link>
                </Then>
                <Else>
                  <Button
                    isDark
                    label={<FormattedMessage id="UserMenu.Buttons.Label.Login"/>}
                    onClick={clickLogin}
                  />
                </Else>
              </If>
            </div>
            <div className={classnames('header-info-btn', {
              // eslint-disable-next-line
              ['header-info-btn--loggedIn']: user
            })}>
              <If condition={!!user}>
                <Then>
                  <Dropdown
                    childrenPosition={{ right: 0 }}
                    toggler={
                      <DropdownToggler>
                        <ImageRound
                          size={46}
                          src={avatar || require('theme/images/default-user.jpg')}
                        />
                      </DropdownToggler>
                    }
                  >
                    <Menu items={userMenuItems} />
                  </Dropdown>
                </Then>
                <Else>
                  <Button label={<FormattedMessage id="Header.WorkWithUs"/>} onClick={clickWorkWithUs}/>
                </Else>
              </If>
            </div>
          </div>
        </div>
      </header>
      {children}
    </div>
    <footer className='footer'>
      <div className='container footer-top'>
        <div className='row'>
          <div className='col-12'>
            <h2 className='hd-mountain hd-mountain-dark'>
              <FormattedMessage id="Footer.GetInTouch"/>
            </h2>
            <ListSocial items={SOCIAL_OPS} />
            <InfoList items={INFO_OPS} />
          </div>
        </div>
      </div>
      <div className='footer-bottom'>
        <FormattedMessage id="Footer.AllRightsReserved"/> <Link to='/'>deliquity.com</Link>
      </div>
    </footer>
  </div>
)
