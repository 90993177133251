
import { get, has } from 'lodash'
import React, { cloneElement } from 'react'
import { When } from 'react-if'
import { compose, pure, setDisplayName, setPropTypes, withHandlers, withState } from 'recompose'
import { Child } from './components'
import { PROP_TYPES } from './config'
import { handleChildOutsideClick, handleChildrenClick, handleTogglerClick } from './lib'

const enhance = compose(
  setDisplayName('views/components/Dropdown'),
  setPropTypes(PROP_TYPES),
  withState('opened', 'open', false),
  withHandlers({
    handleChildOutsideClick,
    handleChildrenClick,
    handleTogglerClick
  }),
  pure
)

export default enhance(({
  children,
  childrenPosition,
  handleChildOutsideClick,
  handleChildrenClick,
  handleTogglerClick,
  opened,
  toggler
}) =>
  <div className='dropdown'>
    <div
      className='dropdown_toggler ignore-react-onclickoutside'
      onClick={handleTogglerClick}
    >
      {cloneElement(toggler, { dropdown: { opened } })}
    </div>
    <When condition={opened}>
      <Child
        children={children}
        onClick={handleChildrenClick}
        onClickOutside={handleChildOutsideClick}
        style={{
          left: has(childrenPosition, 'right') ? 'auto' : get(childrenPosition, 'left'),
          right: has(childrenPosition, 'left') ? 'auto' : get(childrenPosition, 'right')
        }}
      />
    </When>
  </div>
)
