import { iconed } from 'views/components/forms/hocs'
import Datepicker from 'views/components/forms/Datepicker'
import GeoSuggestInput from 'views/components/forms/GeoSuggestInput'
import React from 'react'
import { Icon } from 'react-icons-kit'
import { history } from 'react-icons-kit/fa/history'
import { androidPin } from 'react-icons-kit/ionicons/androidPin'
import { Field, reduxForm } from 'redux-form'
import { compose, pure, setDisplayName, setPropTypes, mapProps, withHandlers, withState } from 'recompose'
import { PROP_TYPES, ACTIONS } from './config'
import { FormattedMessage } from 'react-intl'
import { ButtonDark } from 'views/components/buttons'
import { mapState, propsMapper, handleSubmitDelivery, validate, handleSetSameAddress } from './lib'
import { connect } from 'react-redux'
import { Checkbox } from 'views/components/forms'
import { When } from 'react-if'
import { Loader } from 'views/components'

const DeliveryTime = iconed({
  icon: <Icon icon={history} size={20} />,
  //label: <FormattedMessage id="Forms.Delivery.DeliveryTime.Label"/>
})(Datepicker)
const ReturnAddress = iconed({
  icon: <Icon icon={androidPin} />,
  label: <FormattedMessage id="Forms.Delivery.ReturnAddress.Label"/>
})(GeoSuggestInput)
const DeliveryAddress = iconed({
  icon: <Icon icon={androidPin} />,
  label: <FormattedMessage id="Forms.Delivery.DeliveryAddress.Label"/>
})(GeoSuggestInput)
const ReturnTime = iconed({
  icon: <Icon icon={history} size={20} />,
  //label: <FormattedMessage id="Forms.Delivery.ReturnTime.Label"/>
})(Datepicker)

const enhance = compose(
  setDisplayName('views/components/forms/Delivery'),
  setPropTypes(PROP_TYPES),
  reduxForm({
    form: 'views/component/forms/Delivery',
    validate
  }),
  connect(mapState, ACTIONS),
  withState('isSameAddress', 'displayReturnAddress', false),
  withState('isLoaded', 'displayLoader', true),
  withState('isPlaceOrderEnabled', 'enablePlaseOrder', true),
  mapProps(propsMapper),
  withHandlers({
    handleSubmitDelivery,
    handleSetSameAddress
  }),
  pure
)

export default enhance(({
  handleSubmitDelivery,
  handleSetSameAddress,
  deliveryAddress,
  isSameAddress,
  deliveryDate,
  returnDate,
  totalPrice,
  currency,
  isLoaded,
  isPlaceOrderEnabled,
  ...props
}) =>
  <form
    className='fDelivery'
    onSubmit={handleSubmitDelivery}
  >
    <Loader message={<FormattedMessage id="Order.Loading"/>} isLoaded={isLoaded}/>
    <div className='row mb-3'>
      <div className='col-12 col-sm-6'>
        <Checkbox
          name='withTakeAway'
          label='With Take Away'
        />
      </div>
      <div className='col-12 col-sm-6'>
        <Checkbox
          name='withSelfReturn'
          label='With Self Return'
        />
      </div>
    </div>
    <div className='row mb-3'>
      <div className='col-12 col-sm-6'>
        <FormattedMessage id="Forms.Delivery.DeliveryAddress.Placeholder">
          {
            (msg) => (
              <Field
                required
                component={DeliveryAddress}
                name='deliveryAddress'
                placeholder={msg}
              />
            )
          }
        </FormattedMessage>   
      </div>
      <div className='col-12 col-sm-6 m-auto'>    
        <FormattedMessage id="Forms.Delivery.SameAddress.Title">
          {
            (msg) => (
              <Checkbox
                name='useSameAddress'
                label={msg}
                onChange={handleSetSameAddress}
              />
            )
          }
        </FormattedMessage>        
      </div>
      <When condition={!isSameAddress}>
        <div className='col-12 col-sm-6 mt-2'>
          <FormattedMessage id="Forms.Delivery.ReturnAddress.Placeholder">
            {
              (msg) => (
                <Field
                  required
                  component={ReturnAddress}
                  name='returnAddress'
                  placeholder={msg}
                />
              )
            }
          </FormattedMessage>   
        </div>
      </When>     
    </div>
    <div className='row'>
      <div className='col-12 col-sm-6'>
        <FormattedMessage id="Forms.Delivery.DeliveryDate"/> - {deliveryDate}     
        <FormattedMessage id="Forms.Delivery.DeliveryTime.Placeholder">
          {
            (msg) => (
              <Field
                required
                timeOnly
                component={DeliveryTime}
                name='deliveryTime'
                placeholder={msg}
                timeIntervals={15}
                autoComplete="off"
              />
            )
          }
        </FormattedMessage>
      </div>
      <div className='col-12 col-sm-6'>
        <FormattedMessage id="Forms.Delivery.ReturnDate"/> - {returnDate}   
        <FormattedMessage id="Forms.Delivery.ReturnTime.Placeholder">
            {
              (msg) => (
                <Field
                  required
                  timeOnly
                  component={ReturnTime}
                  name='returnTime'
                  placeholder={msg}
                  timeIntervals={15}
                  autoComplete="off"
                />
              )
            }
          </FormattedMessage>        
      </div>
    </div>
    <div className='row mt-3'>
      <div className='col-12 col-sm-12 text-center'>
          <h2><FormattedMessage id="CheckoutPage.TotalPrice"/> - {`${totalPrice} ${currency}`} </h2>
      </div>
    </div>
    <div className='cartPage_buttons container'>
        <div className='row'>
          <div className='col-12 col-sm-12 text-center'>
            <ButtonDark type='submit' disabled={!isPlaceOrderEnabled}>
              <FormattedMessage id="CheckoutPage.Buttons.PlaceOrder"/>
            </ButtonDark>
          </div>
        </div>
      </div>
  </form>
)
