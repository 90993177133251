
import { FORM_ERRORS } from 'data/constants'
import { get } from 'lodash'

export default (values, props) => {
  const errors = {}
  const products = get(props, 'products', [])
  if (values) {
    if (!values.customerName) {
      errors.customerName = FORM_ERRORS.REQUIRED
    }
    if (props) {
      products.forEach(product => {
        get(product, 'fields', []).forEach(field => {
          if (!values[field.name]) {
            errors[field.name] = FORM_ERRORS.REQUIRED
          }
          if (field.units && !values[field.units.name]) {
            errors[field.units.name] = FORM_ERRORS.REQUIRED
          }
        })
      })
    }
  }
  return errors
}
