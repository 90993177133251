
import classnames from 'classnames'
import { Status } from 'views/components'
import { Input } from 'views/components/forms'
import { ASYNC_STATUSES } from 'data/constants'
import { omit } from 'lodash'
import React, { PureComponent } from 'react'
import { When } from 'react-if'
import { Field, reduxForm } from 'redux-form'
import { compose, defaultProps, mapProps, setDisplayName, setPropTypes, withState } from 'recompose'
import { DEFAULT_PROPS, PROP_TYPES } from './config'
import { propsMapper } from './lib'
import { FormattedMessage } from 'react-intl'

const enhance = compose(
  setDisplayName('views/blocks/forms/HeadedForm'),
  setPropTypes(PROP_TYPES),
  defaultProps(DEFAULT_PROPS),
  reduxForm({
    form: 'views/component/forms/HeadedForm',
  }),
  withState('statusMsg', 'showStatusMsg', false),
  mapProps(propsMapper)
)

class HeadedForm extends PureComponent {
  _timer = null

  componentDidUpdate (prev) {
    const {
      reset,
      showStatusMsg,
      status,
      statusShowDuration
    } = this.props

    if (prev.status === ASYNC_STATUSES.PENDING) {
      if (status === ASYNC_STATUSES.SUCCESS) {
        reset()
      }
      if (status === ASYNC_STATUSES.ERROR || status === ASYNC_STATUSES.SUCCESS) {
        showStatusMsg(true)
        this._timer = setTimeout(() => {
          showStatusMsg(false)
        }, statusShowDuration)
      }
    }
  }

  render () {
    const {
      fields,
      handleSubmit,
      isPending,
      messageOnComplete,
      onSubmit,
      status,
      statusMsg,
      title
    } = this.props

    return (
      <form
        className='headedForm'
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className='headedForm_title'>
          {title}
        </div>
        {fields.map((field, key) => (
          <Field
            errorAtBottom
            className='headedForm_field'
            component={field.component || Input}
            key={key}
            {...omit(field, ['component'])}
          />
        ))}
        <div className='headedForm_btn'>
          <button
            disabled={isPending}
            className='buttonBig'
            type='submit'
          >
            <FormattedMessage id="HeadedForm.Buttons.ResetPassword"/>
          </button>
          <When condition={isPending}>
            <Status size={50} />
          </When>
          <When condition={statusMsg}>
            <p className={classnames('headedForm_statusMsg', {
              headedForm_statusMsg_error: status === ASYNC_STATUSES.ERROR,
              headedForm_statusMsg_success: status === ASYNC_STATUSES.SUCCESS
            })}>
              {messageOnComplete}
            </p>
          </When>
        </div>
      </form>
    )
  }

  componentWillUnmount () {
    clearTimeout(this._timer)
  }
}

export default enhance(HeadedForm)
