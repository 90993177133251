
import PropTypes from 'prop-types'

export const DEFAULT_PROPS = {
  type: 'button'
}

export const PROP_TYPES = {
  backgroundColor: PropTypes.string,
  children: PropTypes.string,
  color: PropTypes.string,
  type: PropTypes.string
}
