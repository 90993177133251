
import classnames from 'classnames'
import { Anchor } from 'views/components/texts'
import React from 'react'
import { Else, If, Then } from 'react-if'
import { compose, defaultProps, pure, setDisplayName, setPropTypes } from 'recompose'
import { DEFAULT_TYPES, PROP_TYPES } from './config'

const enhance = compose(
  setDisplayName('views/components/lists/Menu'),
  setPropTypes(PROP_TYPES),
  defaultProps(DEFAULT_TYPES),
  pure
)

export default enhance(({
  items
}) =>
  <ul className='menu'>
    {items.map((item, key) => {
      const { chosen, lighted, label, path, target, onClick } = item

      return (
        <li
          className={classnames({
            menu_item_chosen: chosen,
            menu_item_lighted: lighted,
            menu_item_noAncor: !path,
          })}
          key={key}
        >
          <If condition={!!path && !chosen}>
            <Then>
              <Anchor                
                href={path}
                target={target}
              >
                {label}
              </Anchor>
            </Then>
            <Else>
              <Anchor
                onClick={onClick}
                href={window.location.pathname}
              >
                {label}
              </Anchor>
            </Else>
          </If>
        </li>
      )
    })}
  </ul>
)
