
import PropTypes from 'prop-types'

export const PROP_TYPES = {
    data: PropTypes.object,
    pageIndex: PropTypes.number,
    pageSize: PropTypes.number,
    canPreviousPage: PropTypes.bool,
    pageCount: PropTypes.number,
    canNextPage: PropTypes.bool,
    columns: PropTypes.shape({
        Header: PropTypes.string,
        columns: PropTypes.arrayOf(PropTypes.shape({
            Header: PropTypes.string,
            accessor: PropTypes.string,
        }))
    })
}