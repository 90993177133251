
import classnames from 'classnames'
import { Status } from 'views/components/forms/components'
import React, { PureComponent } from 'react'
import Select from 'react-select'
import { compose, mapProps, setDisplayName, setPropTypes, withHandlers } from 'recompose'
import { PROP_TYPES } from './config'
import { handleBlur, handleChange, propsMapper } from './lib'

const enhance = compose(
  setDisplayName('views/components/forms/Select'),
  setPropTypes(PROP_TYPES),
  mapProps(propsMapper),
  withHandlers({
    handleBlur,
    handleChange
  })
)

class SelectControl extends PureComponent {
  _select = null

  handleStatusClick = () => {
    this._select.focus()
  }

  render () {
    const {
      borderRadius,
      error,
      handleBlur,
      handleChange,
      input,
      modify,
      showError,
      small,
      ...props
    } = this.props
    const newStyle = {
      borderRadius
    }

    return (
      <div className={classnames('select', {
        mod1: modify === '1',
        small
      })}>
        <Select
          {...input}
          onBlur={handleBlur}
          onChange={handleChange}
          optionClassName={classnames({
            // eslint-disable-next-line
            ['select--mod1--option']: modify === '1'
          })}
          {...props}
          ref={el => { this._select = el }}
          style={newStyle}
        />
        {showError ? (
          <Status
            error
            message={error}
            onClick={this.handleStatusClick}
          />
        ) : null}
      </div>
    )
  }
}

export default enhance(SelectControl)
