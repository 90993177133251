
import { GeoSuggestInput } from 'views/components/forms'
import React from 'react'
import { Icon } from 'react-icons-kit'
import { androidSearch } from 'react-icons-kit/ionicons/androidSearch'
import { compose, mapProps, pure, setDisplayName, setPropTypes, withHandlers } from 'recompose'
import { Field, reduxForm } from 'redux-form'
import { PROP_TYPES } from 'prop-types'
import { handleWarnClick, propsMapper, validate } from './lib'

const enhance = compose(
  setDisplayName('views/components/forms/Search'),
  setPropTypes(PROP_TYPES),
  reduxForm({
    form: 'views/component/forms/Search',
    validate
  }),
  mapProps(propsMapper),
  withHandlers({
    handleWarnClick
  }),
  pure
)

export default enhance(({
  formWarn,
  handleSubmit,
  handleWarnClick,
  isPending,
  onSubmit,
  placeholder
}) =>
  <form
    autoComplete='off'
    className='f-search-main'
    onSubmit={handleSubmit(onSubmit)}
  >
    <Field
      autoComplete='off'
      component={GeoSuggestInput}
      inputClassName='f-search-main__input'
      name='place'
      placeholder={placeholder}
    />
    <button
      className='f-search-main__button'
      disabled={isPending}
      type='submit'
    >
      {isPending ? (
        <img
          alt=''
          className='f-search-main__img'
          src={require('theme/images/spinner.svg')}
        />
      ) : (
        <Icon
          icon={androidSearch}
          size={27}
          style={{ color: '#01a7e7' }}
        />
      )}
    </button>
    {formWarn ? (
      <div
        className='f-search-main__warn'
        onClick={handleWarnClick}
      >
        {formWarn}
      </div>
    ) : null}
  </form>
)
