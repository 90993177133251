
import PropTypes from 'prop-types'

export const DEFAULT_PROPS = {
  items: []
}

export const PROP_TYPES = {
  items: PropTypes.arrayOf(PropTypes.shape({
    description: PropTypes.string,
    image: PropTypes.shape({
      alt: PropTypes.string,
      src: PropTypes.string
    })
  }))
}
