
import { MainOffer } from 'views/components'
import { Search } from 'views/components/forms'
import { Carousel } from 'views/components/sliders'
import { get } from 'lodash'
import React from 'react'
import { compose, pure, setDisplayName, setPropTypes } from 'recompose'
import { CAROUSEL_IMAGES, PROP_TYPES } from './config'
import { FormattedMessage } from 'react-intl'

const enhance = compose(
  setDisplayName('views/blocks/MainOffer'),
  setPropTypes(PROP_TYPES),
  pure
)

export default enhance(({
  clickSearchWarning,
  search,
  submitSearch
}) =>
  <section className='mob'>
    <Carousel images={CAROUSEL_IMAGES} />
    <div className='mob_info'>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-12 col-sm-10 col-md-8'>
            <MainOffer
              description={<FormattedMessage id="MainOffer.SkiHire.Description"/>}
              offer={<FormattedMessage id="MainOffer.SkiHire"/>}
              options={[
                {
                  emphasized: true,
                  label: <FormattedMessage id="MainOffer.SkiHire.Label"/>
                },
                { label: <FormattedMessage id="MainOffer.SkiHire.Label2"/> }
              ]}
            />
            <FormattedMessage id="Forms.Search.Placeholder">
              {
                (msg) => (
                  <Search
                    clickWarning={clickSearchWarning}
                    form='views/blocks/MainOffer-search'
                    formError={get(search, 'error')}
                    onSubmit={submitSearch}
                    placeholder={msg[0]}
                    status={get(search, 'status')}
                  />
                )
              }
            </FormattedMessage>
          </div>
        </div>
      </div>
    </div>
  </section>
)
