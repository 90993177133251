
import { apiCall } from 'helpers'
import { makeLogic } from 'helpers/redux'
import { get } from 'lodash'
import * as cx from './constants'
import { API_DOMAINS } from 'data/constants'

const getAllCategories = makeLogic({
  httpMethod: apiCall.get,
  latest: true,
  path: `${API_DOMAINS.CATALOG}/GetAllCategory`,
  payload: ({ payload }) => ({
    params: {
      showHidden: get(payload, 'showHidden', true)
    }
  }),
  processResult: res => res.data,
  type: cx.GET_ALL_CATEGORIES
})

const getAllCategoriesForClient = makeLogic({
  httpMethod: apiCall.get,
  latest: true,
  path: `${API_DOMAINS.CATALOG}/GetAllCategoryForClient`,
  payload: ({ payload }) => ({
    params: {
      showHidden: get(payload, 'showHidden', true)
    }
  }),
  processResult: res => res.data,
  type: cx.GET_ALL_CATEGORIES_FOR_CLIENT
})

export default [ getAllCategories, getAllCategoriesForClient ]
