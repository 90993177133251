import { methodFromProps } from 'helpers'
import { get } from 'lodash'
import moment from 'moment'

export default props => id => {
  const insertShoppingCart = methodFromProps('insertShoppingCart', props)
  const productId = get(props, 'selectedProductId')
  const quantity = document.getElementById('productQuantity')

  const form = new FormData()
  form.set('productId', productId)
  form.set('rentalStartDateUtc', moment(get(props, 'productMeta.date.from')).format('YYYY-MM-DD HH:mm'))
  form.set('rentalEndDateUtc', moment(get(props, 'productMeta.date.to')).format('YYYY-MM-DD HH:mm'))
  form.set('quantity', isNaN(quantity.valueAsNumber) ? 1 : quantity.valueAsNumber)

  insertShoppingCart(form)
}
