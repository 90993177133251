
import { userManager } from 'data'
import { getUserProfile } from 'helpers/auth'
import { getUrlFromRouter } from 'helpers/router'
import { getAvatarSrc } from 'helpers/user'
import { get } from 'lodash'
import { USER_MENU } from '../config'

export default ({
  auth,
  notifications,
  router,
  user,
  cartQuantity,
  shoppingCart,
  ...props
}) => {
  const currentUrl = getUrlFromRouter(router)
  const currencyItems = get(props, 'currencies.currencies', [])
  const languagesItems = get(props, 'languages.languages', [])
  return {
    ...props,
    avatar: getAvatarSrc(user),
    currentUrl,
    unreadNotifications: get(notifications, 'unreadQuantity'),
    user: getUserProfile(auth),
    userManager,
    cartQuantity: shoppingCart.length,
    userMenuItems: USER_MENU.map(umi => ({
      ...umi,
      chosen: currentUrl === umi.path
    })),
    currencies: currencyItems,
    languages: languagesItems
  }
}
