
import { BUTTON_TYPES } from 'data/constants'
import { values } from 'lodash'
import PropTypes from 'prop-types'

export const DEFAULT_PROPS = {
  type: BUTTON_TYPES.BUTTON
}

export const PROP_TYPES = {
  big: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  mod: PropTypes.number,
  type: PropTypes.oneOf(values(BUTTON_TYPES)),
  onClick: PropTypes.func,
  disabled: PropTypes.bool
}
