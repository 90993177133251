
import { createAction } from 'redux-actions'
import * as cx from './constants'

/**
 * @param {Object} payload
 * @param {boolean} [payload.showHidden=true]
 */
export const getAllCategories = createAction(cx.GET_ALL_CATEGORIES)

export const getAllCategoriesForClient = createAction(cx.GET_ALL_CATEGORIES_FOR_CLIENT)
