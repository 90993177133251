
import { ASYNC_STATUSES } from 'data/constants'
import { get, omit, values } from 'lodash'
import PropTypes from 'prop-types'
import { CardCVCElement, CardExpiryElement, CardNumberElement } from 'react-stripe-elements'

export const FIELDS = props => ([
  {
    name: 'cardNumber',
    placeholder: get(props, 'initialValues.cardNumber.placeholder'),
    reactStripe: true,
    stripeElement: CardNumberElement
  },
  {
    name: 'expirationDate',
    placeholder: get(props, 'initialValues.expirationDate.placeholder'),
    reactStripe: true,
    stripeElement: CardExpiryElement
  },
  {
    name: 'cvc',
    reactStripe: true,
    stripeElement: CardCVCElement
  },
  {
    name: 'name',
    placeholder: 'Cardholder name'
  }
])

export const PROP_TYPES = {
  clickSubmit: PropTypes.func,
  form: PropTypes.string.isRequired,
  isWide: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  status: PropTypes.oneOf(values(omit(ASYNC_STATUSES, ['ONSUCCESS'])))
}
