
import { get } from 'lodash'

export default ({
  limits,
  units,
  values,
  ...props
}) => {
  const limitBottom = get(limits, 'bottom', 0)
  const limitTop = get(limits, 'top', 0)
  const valueBottom = get(values, 'bottom')
  const valueTop = get(values, 'top')

  return {
    ...props,
    defaultValue: [limitBottom, limitTop],
    limitBottom,
    limitBottomUnits: `${limitBottom} ${units}`,
    limitTop,
    limitTopUnits: `${limitTop} ${units}`,
    range: `${valueBottom} - ${valueTop}`,
    tipRange: `${valueBottom} : ${valueTop}`,
    value: (typeof valueBottom === 'number' && typeof valueTop === 'number')
      ? [valueBottom, valueTop]
      : undefined
  }
}
