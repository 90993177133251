
import { get, omit } from 'lodash'

export default data => {
  const address = get(data, 'address')
  const phoneCountryCode = get(data, 'phoneCountryCode')
  const res = { ...omit(data, ['phoneCountryCode']) }
  if (address) {
    res.address = {
      label: `${address}, ${data.city}`
    }
  }
  if (phoneCountryCode) {
    res.countryPrefix = phoneCountryCode
  }
  return res
}
