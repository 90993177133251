
import { get } from 'lodash'

export default auth => {
  const accessToken = get(auth, 'user.access_token')
  const idToken = get(auth, 'user.id_token')
  const tokens = {}
  if (accessToken) {
    tokens.accessToken = accessToken
  }
  if (idToken) {
    tokens.idToken = idToken
  }
  return tokens
}
