
import React from 'react'
import { Route } from 'react-router-dom'
import { compose, pure, setDisplayName } from 'recompose'
import Page from './Page'

const enhance = compose(
  setDisplayName('views/routes/auth/Router'),
  pure
)

export default enhance(() =>
  <div>
    <Route
      exact
      component={Page}
      path='/auth'
    />
    <Route
      component={require('views/routes/_r/auth/_r/logout').Router}
      path='/auth/logout'
    />
    <Route
      component={require('views/routes/_r/auth/_r/silent-renew').Router}
      path='/auth/silent-renew'
    />
  </div>
)
