
import { InnerPage } from 'views/pages'
import React from 'react'
import { compose, pure, setDisplayName } from 'recompose'

const enhance = compose(
  setDisplayName('views/routes/faq/Page'),
  pure
)

export default enhance(() =>
  <InnerPage title='Frequently Asked Questions'>
    FAQ will be here. The page design is to be done.
  </InnerPage>
)
