
import { methodFromProps } from 'helpers'
import { get } from 'lodash'

export default config => (state, props) => {
  const formName = get(props, 'meta.form')
  const formValues = get(state, `form.${formName}.values`)
  const mapFormValues = methodFromProps('mapFormValues', config)
  return (config && state && props) ? mapFormValues(formValues) : {}
}
