
import { SEARCH_PRODUCTS_DEFAULTS } from 'data/constants'
import { SEARCH_PRODUCTS_DEFAULT_PARAMS } from 'redux/modules/products/constants'
import { methodFromProps } from 'helpers'
import { get } from 'lodash'
import moment from 'moment'

export default props => data => {
    const fetchGoods = methodFromProps('fetchGoods', props)
    const productMeta = get(props, 'productMeta')
    const dateFrom = get(productMeta, 'date.from')
    const dateTo = get(productMeta, 'date.to')

    fetchGoods({
      attributes: get(props, 'categoryAttributes.allByCategory', []),
      formValues: {
        address: get(productMeta, 'deliveryAddress'),
        from: dateFrom ? moment(dateFrom) : null,
        lookingFor: get(productMeta, `categories.[${get(productMeta, 'categoryIdx')}]`),
        page: get(data, "selected", 0) + 1,
        pageSize: SEARCH_PRODUCTS_DEFAULTS.PAGE_SIZE,
        to: dateTo ? moment(dateTo) : null,
        searchText: get(data, "searchTerm", null)     
      },
      sortBy: get(props, 'sorter.value.value') || SEARCH_PRODUCTS_DEFAULT_PARAMS.SORT_BY,
      selectedMerchants: get(props, 'merchantsState', []).filter(x => x.selected === true).map(x => x.value),
      searchRadius: get(props, 'merchantRadius'),
    })
}