
import { endsWith } from 'lodash'

export default data => {
  if (!data) {
    return ''
  }

  const { ending, number, word } = data
  let end = ending || 's'
  const noEnding = number === 1 ||
    (endsWith(`${number}`, '1') && !endsWith(`${number}`, '11'))
  if (noEnding) {
    end = ''
  }

  const newWord = word || ''

  return newWord + end
}
