
import Select from 'views/components/forms/Select'
import { iconed } from 'views/components/forms/hocs'
import React from 'react'
import { Icon } from 'react-icons-kit'
import { cogs } from 'react-icons-kit/fa/cogs'

export default iconed({
  icon: <Icon icon={cogs} />,
  label: 'Looking for?'
})(Select)
