
import { ASYNC_STATUSES } from 'data/constants'

export default ({
  disabled,
  status,
  ...props
}) => {
  const isPending = status === ASYNC_STATUSES.PENDING

  return {
    ...props,
    isDisabled: disabled || isPending,
    isPending
  }
}
