
import { apiCall, funcOrArg } from 'helpers'
import { actionStateTypes as ast } from 'helpers/redux'
import { omit } from 'lodash'
import { createLogic } from 'redux-logic'
import * as asa from 'redux/modules/asyncState/actions'

/**
 * @param {Object[]} calls - concurrent API calls settings
 * @param {Any} calls[].method - HTTP method (GET, POST etc)
 * @param {String|Function} calls[].path - to call with method
 * @param {Any|Function} calls[].payload - data to pass with the API call
*/
export default ({
  calls,
  httpMethod,
  onError,
  onSuccess,
  path,
  payload,
  processError,
  processResult,
  type,
  ...props
}) => createLogic({
  process: ({ getState, action }, dispatch, done) => {
    const funcOrAction = func => funcOrArg(func, action)
    const payloadReady = payload ? funcOrAction(payload) : action.payload
    let promisedCall

    dispatch(asa.pending(type))

    if (calls instanceof Array) {
      promisedCall = apiCall.all(calls.map(call => {
        const { method } = call
        return method(call.path, funcOrAction(call.payload))
      }))
    } else if (path) {
      promisedCall = httpMethod(funcOrAction(path), payloadReady)
    }

    promisedCall
      .then(res => {
        dispatch({
          type: ast.success(type),
          payload: funcOrArg(processResult, res)
        })
        dispatch(asa.success(type))
        typeof onSuccess === 'function' && onSuccess({
          dispatch,
          payload: payloadReady,
          res
        })
      })
      .catch(error => {
        dispatch({
          type: ast.error(type),
          payload: funcOrArg(processError, error)
        })
        dispatch(asa.error(type, {
          ...error,
          message: error.message
        }))
        typeof onError === 'function' && onError({ dispatch, error })
      })
      .then(() => done())
  },
  type,
  ...omit(props, ['process'])
})
