
import React from 'react'
import { Icon } from 'react-icons-kit'
import { bellO } from 'react-icons-kit/fa/bellO'
import { When } from 'react-if'
import { compose, pure, setDisplayName, setPropTypes } from 'recompose'
import { PROP_TYPES } from './config'

const enhance = compose(
  setDisplayName('views/components/Notifications'),
  setPropTypes(PROP_TYPES),
  pure
)

export default enhance(({
  quantity
}) =>
  <span className='notifications'>
    <Icon
      className='notifications_icon'
      icon={bellO}
      size={14}
    />
    <When condition={!!quantity}>
      <span className='notifications_quantity'>
        {quantity}
      </span>
    </When>
  </span>
)
