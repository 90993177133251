
import PropTypes from 'prop-types'

export const DEFAULT_PROPS = {
  items: []
}

export const PROP_TYPES = {
  items: PropTypes.arrayOf(PropTypes.shape({
    href: PropTypes.string,
    label: PropTypes.PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.element
    ]),
    targetBlank: PropTypes.bool
  }))
}
