
import classnames from 'classnames'
import { ButtonDark, ButtonPlus } from 'views/components/buttons'
import { get } from 'lodash'
import React from 'react'
import { reduxForm } from 'redux-form'
import { compose, defaultProps, pure, setDisplayName, setPropTypes, withState, withHandlers, mapProps } from 'recompose'
import { DEFAULT_PROPS, PROP_TYPES, ACTIONS} from './config'
import { mapState, validate, handlePlusClick, handleCloseUpsellModal, handleSettingsSet, handleSubmitForm, handleAddAdditionalProduct, propsMapper } from './lib'
import { FormattedMessage } from 'react-intl'
import { Modal } from 'views/components/modals'
import { Link } from 'react-router-dom'
import { PlusList } from 'views/components/lists'
import { connect } from 'react-redux'

const enhance = compose(
  setDisplayName('views/components/forms/ConfirmForm'),
  setPropTypes(PROP_TYPES),
  defaultProps(DEFAULT_PROPS),
  connect(mapState, ACTIONS),
  withState('upsellModal', 'toggleUpsellModal', false),
  withState('productSettingValues', 'setProductSettingValue', {}),
  mapProps(propsMapper),
  reduxForm({
    form: 'views/component/forms/ConfirmForm',
    validate   
  }),
  withHandlers({
    handleCloseUpsellModal,
    handlePlusClick,
    handleSettingsSet,
    handleSubmitForm,
    handleAddAdditionalProduct
  }),
  pure
)

export default enhance(({
  handleSubmitForm,
  products,
  handleCloseUpsellModal,
  handlePlusClick,
  handleAddAdditionalProduct,
  otherProducts,
  upsellModal,
  customers
}) =>
  <form
    className='confirmForm'
    onSubmit={handleSubmitForm}
  >
    <FormattedMessage id="Customer.Name.Placeholder">
            {
              (msg) => (
                <input 
                  type="text" 
                  name="names" 
                  list="customersName" 
                  className='form-control'
                  autoComplete="off"
                  placeholder={msg}
                />
              )
            }
      </FormattedMessage>
    <datalist id="customersName">
      {customers.options.map(item => (
          <option value={item.value}>
              {item.label}
          </option>
      ))}
    </datalist>

    {products.map((product, key) =>
      <article
        className={classnames('confirmForm_product', {
          // eslint-disable-next-line
          ['confirmForm_product--first']: key === 0
        })}
        key={key}
      >
        <div className='row'>
          <div className='confirmForm_product_sec col-12 col-sm-5'>
            <h1 className='confirmForm_product_hd'>
              {product.name}
            </h1>
            <figure className='confirmForm_product_image'>
              <img
                alt={get(product, 'image.alt', '')}
                src={get(product, 'image.src', '')}
              />
            </figure>
          </div>
          <div className='confirmForm_product_sec col-12 col-sm-7'>
          </div>
        </div>
      </article>
    )}
    <ButtonPlus onClick={handlePlusClick} />
    {upsellModal ? (
      <Modal
        children={
          <article className='productPage_modal'>
            <FormattedMessage id="ProductPage.Content.Modal.AddProducts"/>
            <PlusList
              clickItem={handleAddAdditionalProduct}
              items={otherProducts}
            />
            <footer className='productPage_modal_footer'>
              <Link to='/cart'>
                <FormattedMessage id="ProductPage.Content.Modal.Buttons.Checkout">
                  {
                    (msg) => (
                      <ButtonDark children={msg} />
                    )
                  }
                </FormattedMessage>               
              </Link>
            </footer>
          </article>
        }
        onClose={handleCloseUpsellModal}
      />
    ) : null}
    <div className='confirmForm_button'>
      {/* <Link to='/cart'> */}
        <FormattedMessage id="Customer.Button.Confirm">
            {
              (msg) => (
                <ButtonDark
                big
                children={msg}
                type='submit'
              />
              )
            }
        </FormattedMessage>
      {/* </Link> */}
    </div>
  </form>
)
