
import { ATTRIBUTE_CONTROL_TYPE } from 'data/constants'
import { get } from 'lodash'
import { API_DOMAINS } from 'data/constants'

export default ({
  product,
  products,
  productSettings,
  shoppingCart,
  categories,
  ...props 
}) => {

  const address = get(products, 'location')
  const dateFrom = get(products, 'fromDate')
  const dateTo = get(products, 'toDate')
  const cartItems = get(shoppingCart, 'shoppingCardItems', []) 
  const relatedProducts = get(shoppingCart, 'relatedProducts', [])
  const productCategory = categories.all.find(x => {
    return x.id === get(product, 'data.categoryId')
  })

  var productCategoryName = ''
  if (productCategory !== undefined) {
    productCategoryName = productCategory.name
  }
  categories.all.forEach(element => {
    if(typeof element.name !== 'undefined') {
      // eslint-disable-next-line
      element.name = element.name.replace(/\-/g,'')
    }
  })

  const categoriesList = get(categories, 'all', [])
  let sortCategories = [];
  categoriesList.forEach(element => {
    if(element.parentCategoryId === 0){
      sortCategories.push(element)
      let childElements = searchChildElement(element)
      if(childElements !== undefined & childElements.length > 0){                      
        PushArreyToCategories(childElements, 1)
      }
    }
  })

  const settingsFiels = []
  productSettings = get(productSettings, 'productSettings', {})
  if (productSettings != null) {
    productSettings.forEach(element => {
      var controlType = get(element,'categoryAttribute.attributeControlTypeId')
      const attributeValues = get(element,'categoryAttributeValues',{})
      if (controlType === ATTRIBUTE_CONTROL_TYPE.TEXT_BOX) {
        var item = {
          name: get(element,'categoryAttribute.name'),
          placeholder: get(element,'categoryAttribute.name')
        }
        settingsFiels.push(item)
      }
      if (controlType === ATTRIBUTE_CONTROL_TYPE.CHECK_BOX) {
        const checkboxes = []
        if (attributeValues != null) {
          attributeValues.forEach(val => {
            if (!val.disabled){
              const checkbox = {
                label: val.text,
                name: val.value
              }
              checkboxes.push(checkbox)
            }
          })
        }       
        var item2 = {
          checkboxes: checkboxes
        }
        settingsFiels.push(item2)
      }

      if (controlType === ATTRIBUTE_CONTROL_TYPE.DROPDOWN_LIST) {
        const dropdownList = []
        if (attributeValues != null) {
          attributeValues.forEach(val => {
            if (!val.disabled){
              const dropdown = {
                label: val.text,
                value: val.value
              }
              dropdownList.push(dropdown)
            }
          })
        }       
        var item3 = {
          name: get(element,'categoryAttribute.name'),
          placeholder: get(element,'categoryAttribute.name'),
          options: dropdownList
        }
        settingsFiels.push(item3)
      }
    })
  }
  
  function searchChildElement (searchElement) {
    let childCategories = [];
    categoriesList.forEach(element => {
      if(element.parentCategoryId === searchElement.id){
        childCategories.push(element)
        let searchElements = searchChildElement(element)
        childCategories.push(searchElements)
        return childCategories
      }
    })
    return childCategories
  }
  
  function PushArreyToCategories (array, iterationNumber) {
    array.forEach(element => {
      if (typeof element.id !== 'undefined') {
        for(let i = 0; i < iterationNumber; i++){
          element.name = "-" + element.name
        }      
        sortCategories.push(element)
      }
      else{
        iterationNumber++
        PushArreyToCategories(element, iterationNumber)  
      }
    })
  }

  var customersOptions = []
  cartItems.forEach(element => {
    if (element.customerName !== null && element.customerName !== undefined) {
      const nameDropDown = {
        label: element.customerName,
        value: element.customerName
      }
      customersOptions.push(nameDropDown)
    }
  })
  var obj = {};
  for ( var i=0, len=customersOptions.length; i < len; i++ )
      obj[customersOptions[i]['value']] = customersOptions[i];
  
      // eslint-disable-next-line
  customersOptions = new Array();
  for ( var key in obj )
  customersOptions.push(obj[key]);

  return {
    breadcrumbs: [
      {
        label: 'Go back',
        path: `/product/${product.data.id}`
      }
    ],

    cartQuantity: cartItems.length,
    productMeta: {
      id: get(product, 'data.id'),
      categories: sortCategories.map(cat => ({
        label: cat.name,
        value: cat.id,
        parentCategoryId: cat.parentCategoryId
      })),
      category: productCategoryName,
      date: {
        from: dateFrom,
        to: dateTo
      },
      deliveryAddress: address,
      submitForm: values => { console.log(values) }
    },
    products: [
      {
        fields: settingsFiels,
        image: {
          alt: require('theme/images/skies.jpg'),
          src: product.pictures[0].pictureFileName == null ? require('theme/images/thumbnail.svg') : `${API_DOMAINS.STORAGE}/${product.pictures[0].pictureFileName}`,
        },
        name: get(product, 'data.name'),
        id: get(product, 'data.id')
      }
    ],
    otherProducts: relatedProducts.map(item => ({
      chosen: false,
      name: get(item, 'product2Name'),
      id: get(item, 'productId2')
    })),
    customersName: {
      name: 'Customers Name',
      placeholder: 'Customer Name',
      options: customersOptions
    }
  }
}
  

