
import { methodFromProps } from 'helpers'
import { connect } from 'react-redux'
import { compose, setDisplayName } from 'recompose'
import { mapState } from './lib'

/*
  @param config: {
    displayName: string,
    mapFormValues: function(values: object) => mappedValues: object
  }
*/
export default config => Component => {
  const mapFormValues = methodFromProps('mapFormValues', config)
  const enhance = compose(
    setDisplayName(config.displayName),
    connect(mapState({ mapFormValues }))
  )

  return enhance(Component)
}
