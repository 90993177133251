import PropTypes from 'prop-types'

export const DEFAULT_PROPS = {
  currency: localStorage.currency,
  bookButton: false
}

export const PROP_TYPES = {
  bookButton: PropTypes.bool,
  currency: PropTypes.string,
  describe: PropTypes.shape({
    for: PropTypes.string,
    level: PropTypes.string,
    shop: PropTypes.string
  }),
  id: PropTypes.number,
  image: PropTypes.shape({
    alt: PropTypes.string,
    src: PropTypes.string
  }),
  onBook: PropTypes.func,
  onClick: PropTypes.func,
  price: PropTypes.number,
  title: PropTypes.string
}