
import { PROP_TYPES as PRODUCT_META_TYPES } from 'views/components/ProductMeta/config'
import PropTypes from 'prop-types'

export const PROP_TYPES = {
  cartQuantity: PropTypes.number,
  children: PropTypes.any,
  className: PropTypes.string,
  productMeta: PropTypes.shape(PRODUCT_META_TYPES)
}
