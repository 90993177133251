
import { success } from 'helpers/redux/actionStateTypes'
import { combineActions, handleActions } from 'redux-actions'
import * as cx from './constants'

const actionTypes = [
  cx.SEARCH_PRODUCTS,
  success(cx.SEARCH_PRODUCTS),
  cx.SET_PRODUCTS_CATEGORY
]
const initialState = {
  category: null,
  fromDate: null,
  location: null,
  products: [],
  pageIndex: 0,
  toDate: null,
  totalPages: 0,
  totalResults: 0
}

export default handleActions({
  [combineActions(...actionTypes)]: (state, action) => ({
    ...state,
    ...action.payload
  })
}, initialState)
