
import { find } from 'lodash'

export default categoryAttributes => categoryAttributes instanceof Array
  ? categoryAttributes.reduce((acc, categoryAttribute) => {
      categoryAttribute.attributes.forEach(attribute => {
        const { attributeName, attributeValue, productDetailsId } = attribute
        const accObj = find(acc, a => a.name === attributeName)
        const toPush = {
          label: attributeValue,
          value: attributeValue,
          productAttributeId: categoryAttribute.productAttributeId,
          productDetailsId: productDetailsId
        }

        if (accObj) {
          const accObjOption = find(accObj.options, aoo => aoo.value === attributeValue)

          if (!accObjOption) {
            accObj.options.push(toPush)
          }
        } else {
          acc.push({
            name: attributeName,
            options: [toPush],
            placeholder: attributeName
          })
        }
      })
      return acc
    }, [])
  : []
