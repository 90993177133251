
import PropTypes from 'prop-types'

export const DEFAULT_PROPS = {
  quantity: 0
}

export const PROP_TYPES = {
  quantity: PropTypes.number
}
