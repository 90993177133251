import { apiCall } from 'helpers'
import { makeLogic } from 'helpers/redux'
import * as cx from './constants'
import { API_DOMAINS } from 'data/constants'

const getShoppingCartItems = makeLogic({
    httpMethod: apiCall.get,
    latest: true,
    path: `${API_DOMAINS.BASKET}/GetShoppingCartItems`,
    processError: err => {
      return err
    },
    processResult: res => res.data,
    type: cx.GET_SHOPPING_CART_ITEMS
  })

  const insertShoppingCartItem = makeLogic({
    httpMethod: apiCall.post,
    path: `${API_DOMAINS.BASKET}/AddToCart`,
    processError: err => {
      return err
    },
    processResult: res => res.data,
    type: cx.INSERT_SHOPPING_CART_ITEM
  })
  
  const clearShoppingCart = makeLogic({
    httpMethod: apiCall.post,
    latest: true,
    path: `${API_DOMAINS.BASKET}/ClearShoppingCartItems`,
    processError: err => {
      return err
    },
    processResult: res => res.data,
    type: cx.CLEAR_SHOPPING_CART_ITEMS
  })

  const deleteShoppingCartItem = makeLogic({
    httpMethod: apiCall.post,
    latest: true,
    path: `${API_DOMAINS.BASKET}/DeleteShoppingCartItemById`,
    processError: err => {
      return err
    },
    processResult: res => res.data,
    type: cx.DELETE_SHOPPING_CART_ITEM
  })

  const updateShoppingCartItem = makeLogic({
    httpMethod: apiCall.post,
    latest: true,
    path: `${API_DOMAINS.BASKET}/UpdateShoppingCartItem`,
    processError: err => {
      return err
    },
    processResult: res => res.data,
    type: cx.UPDATE_SHOPPING_CART_ITEM
  })

  const addShoppingCartItemDetails = makeLogic({
    httpMethod: apiCall.post,
    latest: true,
    path: `${API_DOMAINS.BASKET}/AddShoppingCartItemDetails`,
    processError: err => {
      return err
    },
    processResult: res => res.data,
    type: cx.ADD_SHOPPING_CART_ITEM_DETAILS
  })

  const getRelatedProducts = makeLogic({
    httpMethod: apiCall.get,
    latest: true,
    path: `${API_DOMAINS.BASKET}/GetReletedProducts`,
    payload: ({ payload }) => ({ params: payload }),
    processError: err => {
      return err
    },
    processResult: res => res.data,
    type: cx.GET_RELATED_PRODUCTS
  })

  const addShoppingCartItem = makeLogic({
    httpMethod: apiCall.post,
    latest: true,
    path: `${API_DOMAINS.BASKET}/AddShoppingCartItem`,
    processError: err => {
      return err
    },
    processResult: res => res.data,
    type: cx.ADD_SHOPPING_CART_ITEM
  })
  
  export default [ 
    getShoppingCartItems,
    insertShoppingCartItem,
    clearShoppingCart,
    deleteShoppingCartItem,
    updateShoppingCartItem,
    addShoppingCartItemDetails,
    getRelatedProducts,
    addShoppingCartItem
  ]