
import { success, error } from 'helpers/redux/actionStateTypes'
import { get } from 'lodash'
import { handleActions } from 'redux-actions'
import * as cx from './constants'

const initialState = {
  all: [],
  error: {}
}

export default handleActions({
  [success(cx.GET_ALL_CATEGORIES)]: (state, action) => ({
    ...state,
    all: get(action, 'payload.categories', [])
  }),
  [error(cx.GET_ALL_CATEGORIES)]: (state, action) => ({
    ...state,
    error: get(action, 'payload', [])
  })
}, initialState)
