
import PropTypes from 'prop-types'

export const DEFAULT_PROPS = {
  items: []
}

export const PROP_TYPES = {
  items: PropTypes.arrayOf(PropTypes.shape({
    image: PropTypes.shape({
      alt: PropTypes.string,
      src: PropTypes.string
    }),
    name: PropTypes.PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.element
    ])
  }))
}
