
import { currencies } from 'data'
import PropTypes from 'prop-types'

export const DEFAULT_PROPS = {
  currency: currencies.USD,
  products: 0,
  users: 0
}

export const PROP_TYPES = {
  clearCart: PropTypes.func,
  currency: PropTypes.string,
  products: PropTypes.number,
  total: PropTypes.number,
  users: PropTypes.number
}
