
import classnames from 'classnames'
import { ManageResults, ProductItem, RolletScrolled, Sorter, Status, Paginator } from 'views/components'
import { ResponsiveList } from 'views/components/lists'
import { NotFound } from 'views/components/texts'
import { fetchGoods } from 'helpers/products'
import { dataLoad } from 'helpers/redux'
import { get } from 'lodash'
import { ProductPage } from 'views/pages'
import React from 'react'
import ReactResizeDetector from 'react-resize-detector'
import { Else, If, Then, When } from 'react-if'
import { connect } from 'react-redux'
import { withSizes } from 'react-sizes'
import { compose, mapProps, pure, setDisplayName, withHandlers, withState } from 'recompose'
import { ACTIONS } from './config'
import { Modal } from 'views/components/modals'
import { ButtonDark } from 'views/components/buttons'
import { FormattedMessage } from 'react-intl'
import InputRange from 'react-input-range'
import { Link } from 'react-router-dom'
import 'react-input-range/lib/css/index.css'
import { dataLoader, 
         handleClickFilter, 
         handleMoreResultsClick, 
         handleProductItemClick, 
         handleProductMetaSubmit, 
         handleSelectCategory, 
         handleSorterChange, 
         handleAddToShoppingCart,
         handleCloseUpShoppingCartModal,
         handleOpenShoppingCartModal,
         handleChangeRadiusOfMerchantDistanse,
         mapState, 
         mapWindowSizes, 
         propsMapper,
         handleClickPage 
        } from './lib'


const enhance = compose(
  setDisplayName('views/routes/search/products/Page'),
  dataLoad(dataLoader),
  connect(mapState, ACTIONS),
  withSizes(mapWindowSizes),
  withHandlers({ fetchGoods }),
  withState('filtersVisible', 'toggleFiltersVisible', true),
  withState('selectedProductId', 'setSelectedProductId', 0),
  withState('shoppingCardModal', 'toggleUpShoppingCardModal', false),
  withState('merchantRadius', 'changeMerchantRadius', 1),
  mapProps(propsMapper),
  withHandlers({
    handleClickFilter,
    handleMoreResultsClick,
    handleProductItemClick,
    handleProductMetaSubmit,
    handleSelectCategory,
    handleSorterChange,
    handleAddToShoppingCart,
    handleClickPage,
    handleCloseUpShoppingCartModal,
    handleOpenShoppingCartModal,
    handleChangeRadiusOfMerchantDistanse,
  }),
  pure
)

export default enhance(({
  areSearchOpsByVisible,
  cartQuantity,
  handleClickFilter,
  handleMoreResultsClick,
  handleProductItemClick,
  handleProductMetaSubmit,
  handleSelectCategory,
  handleSorterChange,
  handleAddToShoppingCart,
  handleClickPage,
  handleCloseUpShoppingCartModal,
  handleOpenShoppingCartModal,
  handleChangeRadiusOfMerchantDistanse,
  relatedProducts,
  isMore,
  isWindowMobile,
  shoppingCardModal,
  selectedProduct,
  manageResults,
  productMeta,
  products,
  searchOpsBy,
  searchStatusPending,
  sorter,
  page,
  pages,
  merchantRadius
}) =>
  <ProductPage
    cartQuantity={cartQuantity}
    className='productsPage'
    productMeta={{
      ...productMeta,
      selectLookingFor: handleSelectCategory,
      submitForm: handleProductMetaSubmit
    }}
  >
    <div className='manageResults--wrap'>
      <ManageResults
        {...manageResults}
        clickFilter={handleClickFilter}
        submitSearch={handleClickPage}
      />
    </div>
    <div
      className='row'
      style={{ marginTop: '2em' }}
    >
      <When condition={areSearchOpsByVisible}>
        <aside className='col-12 col-md-3'>
          {searchOpsBy.map((sob, key) =>
            <div
              className={key ? 'mt_22' : ''}
              key={key}
            >
              <RolletScrolled
                opened
                {...sob}
              />
            </div>
          )}
        </aside>
      </When>
      <div className={classnames('col-12 main-right', {
        // eslint-disable-next-line
        ['col-md-9']: areSearchOpsByVisible
      })}>
        <div className='mt-20 mb-20'>
          <FormattedMessage id="ProductsPage.Radius.Label" />
          <div className='mt-20'>
            <InputRange
              maxValue={1000}
              minValue={100}
              value={merchantRadius}
              onChange={value => handleChangeRadiusOfMerchantDistanse(value)}
            />
          </div>
        </div>
        <If condition={!!get(products, 'length')}>
          <Then>
            <Sorter
              {...sorter}
              onChange={handleSorterChange}
            />           
            <ReactResizeDetector handleWidth>
              {({ width }) => (
                <ResponsiveList
                  className='productsSearched'
                  items={products.map((product, item) =>
                    <ProductItem
                      {...product}
                      bookButton={!isWindowMobile}
                      key={item}
                      onBook={handleOpenShoppingCartModal}
                      onClick={handleProductItemClick}
                    />                   
                  )}
                  itemsInRow={width < 690 ? 1 : 2}
                />
              )}
            </ReactResizeDetector>
            {shoppingCardModal ? (
                <Modal
                  children={
                    <article className='shoppingPage_modal'>
                      <FormattedMessage id="ProductsPage.Modal.Title"/>
                      <div className="mt10">
                        <FormattedMessage id="ProductsPage.Modal.ProductName"/> {selectedProduct.name}                       
                      </div>  
                      <div className="mt10">
                        <FormattedMessage id="ProductsPage.Modal.Quantity"/> <input type="number" className="form-control quantity" id="productQuantity"/>
                      </div>
                      <div className="mt10">
                        <FormattedMessage id="ProductsPage.Modal.RelatedProducts"/>
                        {relatedProducts.map((relatedProduct, key) =>
                          <div className="mt10">
                            <Link to={"/product/" + relatedProduct.productId2}>
                              {relatedProduct.product2Name}
                            </Link>
                          </div>
                        )}
                      </div>
                      <footer className='shoppingPage_modal_footer'>
                        <Link to='/' className='mr20'>
                          <FormattedMessage id="CardPage.Content.Buttons.ContinueShopping">
                            {
                              (msg) => (
                                <ButtonDark children={msg} onClick={handleAddToShoppingCart} />
                              )
                            }
                          </FormattedMessage>               
                        </Link>
                        <Link to='/cart'>
                          <FormattedMessage id="ProductsPage.Modal.Buttons.ShoppingCart">
                            {
                              (msg) => (
                                <ButtonDark children={msg} onClick={handleAddToShoppingCart} />
                              )
                            }
                          </FormattedMessage>               
                        </Link>
                      </footer>
                    </article>
                  }
                  onClose={handleCloseUpShoppingCartModal}
                />
              ) : null}
            <div className='notificationsPage_paginator'>
              <Paginator
                clickPage={handleClickPage}
                isWide={true}
                selected={page - 1}
                totalPages={pages}
              />
            </div>
            {/* <When condition={isMore && !searchStatusPending}>
              <MoreResults
                onClick={handleMoreResultsClick}
                text='OH...WE HAVE MORE RESULTS'
              />
            </When> */}
          </Then>
          <Else>
            <NotFound />
          </Else>
        </If>
        <When condition={searchStatusPending}>
          <Status />
        </When>
      </div>
    </div>
  </ProductPage>
)
