
import { methodFromProps } from 'helpers'
import { get } from 'lodash'

export default props => ev => {
  const files = get(ev, 'target.files', [])
  const inputOnChange = methodFromProps('input.onChange', props)
  const onChange = methodFromProps('onChange', props)
  inputOnChange(files)
  onChange(files)
}
