
import React from 'react'
import { compose, pure, setDisplayName, setPropTypes, withHandlers, withState } from 'recompose'
import { PROP_TYPES } from './config'
import { handleMouseOut, handleMouseOver } from './lib'

const enhance = compose(
  setDisplayName('views/components/Tip'),
  setPropTypes(PROP_TYPES),
  withState('textShowed', 'textShow', false),
  withHandlers({
    handleMouseOut,
    handleMouseOver
  }),
  pure
)

export default enhance(({
  children,
  handleMouseOut,
  handleMouseOver,
  inline,
  text,
  textShowed,
  tipTextStyle
}) => {
  const TipEl = inline ? 'span' : 'div'

  return (
    <TipEl className='tip'>
      <span
        className='tip_text'
        style={{
          ...tipTextStyle,
          display: textShowed ? 'flex' : 'none'
        }}
      >
        <span className='tip_text_message'>
          {text}
        </span>
      </span>
      <TipEl
        className='tip_child'
        onMouseOut={handleMouseOut}
        onMouseOver={handleMouseOver}
      >
        {children}
      </TipEl>
    </TipEl>
  )
})
