
import { Card, Paginator } from 'views/components'
import { ResponsiveList } from 'views/components/lists'
import { methodFromProps } from 'helpers'
import { dataLoad } from 'helpers/redux'
import { get } from 'lodash'
import { InnerPage } from 'views/pages'
import React, { PureComponent } from 'react'
import ReactResizeDetector from 'react-resize-detector'
import { Else, If, Then, When } from 'react-if'
import { connect } from 'react-redux'
import { compose, mapProps, setDisplayName, withHandlers } from 'recompose'
import { ACTIONS } from './config'
import { dataLoader, handleClickPage, mapState, propsMapper } from './lib'
import { FormattedMessage } from 'react-intl';

const enhance = compose(
  setDisplayName('views/routes/notifications/Page'),
  dataLoad(dataLoader),
  connect(mapState, ACTIONS),
  mapProps(propsMapper),
  withHandlers({
    handleClickPage
  })
)

class Page extends PureComponent {
  componentDidUpdate (prev) {
    const notificationsList = get(this.props, 'notificationsList') || []
    if (
      prev.getUserNotificationsPageStatus === 'pending'
      && this.props.getUserNotificationsPageStatus === 'success'
      && notificationsList.length
    ) {
      const markNotificationsReadByPage = methodFromProps('markNotificationsReadByPage', this.props)
      const { pageIndex, totalPages, userId } = this.props
      markNotificationsReadByPage({
        page: pageIndex,
        pageSize: totalPages,
        userId
      })
    }
  }

  render () {
    const {
      handleClickPage,
      notificationsList,
      pageIndex,
      totalPages
    } = this.props

    return (
      <InnerPage
        withSearch
        className='notificationsPage'
      >
        <h1 className='hd-page'>
          <FormattedMessage id="NotificationPage.Content.Name"/>
        </h1>
        <section className='notificationsPage_cnt'>
          <If condition={!!notificationsList.length}>
            <Then>
              <ReactResizeDetector handleWidth>
                {({ width }) => (
                  <div>
                    <ResponsiveList
                      className='notifications_list'
                      items={notificationsList.map((notification, key) =>
                        <Card
                          {...notification}
                          key={key}
                        />
                      )}
                      itemsInRow={width > 578 ? 2 : 1}
                    />
                    <When condition={totalPages > 0}>
                      <div className='notificationsPage_paginator'>
                        <Paginator
                          clickPage={handleClickPage}
                          isWide={width > 470}
                          selected={pageIndex}
                          totalPages={totalPages}
                        />
                      </div>
                    </When>
                  </div>
                )}
              </ReactResizeDetector>
            </Then>
            <Else>
              <p className='notificationsPage_tip'>
                <FormattedMessage id="NotificationPage.Content.NotFound"/>
              </p>
            </Else>
          </If>
        </section>
      </InnerPage>
    )
  }
}

export default enhance(Page)
