
import PropTypes from 'prop-types'

export const DEFAULT_TYPES = {
  items: []
}

export const PROP_TYPES = {
  items: PropTypes.arrayOf(PropTypes.shape({
    chosen: PropTypes.bool,
    lighted: PropTypes.bool,
    label: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.element
    ]),
    path: PropTypes.string,
    target: PropTypes.string,
    onClick: PropTypes.func
  }))
}
