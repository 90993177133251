import { getOrdersPageForClient } from 'redux/modules/orders/actions'
import { ORDERS_PAGE_DEFAULTS } from 'data/constants'

export default props => {
  const loaders = [
    {
      action: getOrdersPageForClient,
      payload: {
        page: ORDERS_PAGE_DEFAULTS.PAGE,
        pageSize: ORDERS_PAGE_DEFAULTS.PAGE_SIZE,
      }
    }
  ]

  return loaders
}