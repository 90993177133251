
import { keys } from 'lodash'

/**
 * @param {Object} data - object which properties are capitalized names
 * @param {Object} data.[PropertyName] - object containing label and value
 * @param {String} data.[PropertyName].label - property label
 * @param {String} data.[PropertyName].value - property value
 *
 * @returns {Array} An array of objects with properties "name" and "value"
*/
export default data => keys(data).map(kd => ({
  name: kd,
  value: data[kd].value
}))
