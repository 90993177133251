
import classnames from 'classnames'
import React from 'react'
import { compose, defaultProps, pure, setDisplayName, setPropTypes } from 'recompose'
import { DEFAULT_PROPS, PROP_TYPES } from './config'

const enhance = compose(
  setDisplayName('views/components/buttons/ButtonDark'),
  setPropTypes(PROP_TYPES),
  defaultProps(DEFAULT_PROPS),
  pure
)

export default enhance(({
  big,
  children,
  mod,
  type,
  onClick,
  disabled
}) =>
  <button
    className={classnames('buttonDark', {
      // eslint-disable-next-line
      ['buttonDark--big']: !!big,
      // eslint-disable-next-line
      ['buttonDark--mod1']: mod === 1
    })}
    disabled={disabled}
    type={type}
    onClick={onClick}
  >
    {children}
  </button>
)
