
import PropTypes from 'prop-types'
import uniqid from 'uniqid'

export const FORM_NAME = `components/ProductMeta--form_${uniqid()}`

export const PROP_TYPES = {
  categories: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    parentCategoryId: PropTypes.number
  })),
  categoryIdx: PropTypes.number,
  date: PropTypes.shape({
    from: PropTypes.instanceOf(Date),
    noDate: PropTypes.string,
    to: PropTypes.instanceOf(Date)
  }),
  deliveryAddress: PropTypes.shape({
    label: PropTypes.string
  }),
  selectLookingFor: PropTypes.func,
  submitForm: PropTypes.func
}
