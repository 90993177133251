
import { Clearer, LookingIn } from 'views/components'
import { CheckList } from 'views/components/lists'
import { SEARCH_PRODUCTS_SORT_BY } from 'data/constants'
import { getCategoryIndexById } from 'helpers/categories'
import { get, find } from 'lodash'
import moment from 'moment'
import React from 'react'
import { SEARCH_PRODUCTS } from 'redux/modules/products/constants'
import { categoryAttributeCheck, clearCategoryAttribute, makeSearchValues, merchantCheck } from './lib'
import { FormattedMessage } from 'react-intl'
import { API_DOMAINS } from 'data/constants'

export default ({
  asyncState,
  categories,
  products,
  relatedProducts,
  ...props
}) => {
  categories.all.forEach(element => {
    if(typeof element.name !== 'undefined') {
      // eslint-disable-next-line
      element.name = element.name.replace(/\-/g,'')
    }
  })
  var allCategoryAttributes = get(props, 'categoryAttributes.allByCategory', [])
  if (allCategoryAttributes.length === 0) {
    allCategoryAttributes = get(products, 'categoriesAttributes', [])
  }
  const categoriesList = get(categories, 'all', [])
  let sortCategories = [];
  categoriesList.forEach(element => {
    if (element.parentCategoryId === 0) {
      sortCategories.push(element)
      let childElements = searchChildElement(element)
      if (childElements !== undefined & childElements.length > 0) {                      
        PushArreyToCategories(childElements, 1)
      }
    }
  });
  const productsLocation = get(products, 'location.label', '')
  var sortableProducts = get(products, 'products', [])

  switch (get(products, 'sortBy')) {
    case SEARCH_PRODUCTS_SORT_BY.HIGHER_PRICE:
      sortableProducts.sort(function(a, b) {
        return b.price - a.price;
      });
      break
    case SEARCH_PRODUCTS_SORT_BY.LOWER_PRICE:
      sortableProducts.sort(function(a, b) {
        return a.price - b.price;
      });
      break
    default:
      break
  }
  products.products = sortableProducts
  const searchFormValuesCombined = makeSearchValues({
    ...props,
    categories,
    products
  })
  const sorterOptions = [
    {
      label: <FormattedMessage id="Select.None"/>,
      value: SEARCH_PRODUCTS_SORT_BY.NONE
    },{
      label: <FormattedMessage id="Select.LowerPrice"/>,
      value: SEARCH_PRODUCTS_SORT_BY.LOWER_PRICE
    },{
      label: <FormattedMessage id="Select.HigherPrice"/>,
      value: SEARCH_PRODUCTS_SORT_BY.HIGHER_PRICE
    }
  ]

function searchChildElement (searchElement) {
  let childCategories = [];
  categoriesList.forEach(element => {
    if(element.parentCategoryId === searchElement.id){
      childCategories.push(element)
      let searchElements = searchChildElement(element)
      childCategories.push(searchElements)
      return childCategories
    }
  })
  return childCategories
}

function PushArreyToCategories (array, iterationNumber) {
  array.forEach(element => {
    if (typeof element.id !== 'undefined') {
      for(let i = 0; i < iterationNumber; i++){
        element.name = "-" + element.name
      }      
      sortCategories.push(element)
    }
    else{
      iterationNumber++
      PushArreyToCategories(element, iterationNumber)  
    }
  })
}
  let searchOpsBy = []
  if (productsLocation) {
    searchOpsBy.push({
      children: (
        <LookingIn where={productsLocation} />
      ),
      title: <FormattedMessage id="Products.Buttons.Location"/>
    })

    searchOpsBy.push({
      children: (
        <Clearer
          children={
            <CheckList
              items={get(products, 'merchants', []).map((item, itemKey) => ({
                checked: item.selected,
                label: item.text,
                name: item.value,
                toggleCheck: merchantCheck({
                  ...props,
                  searchFormValues: searchFormValuesCombined
                })({
                  itemKey,
                  selected: item.selected
                })
              }))}
            />
          }
        />
      ),
      title: 'Merchants'
    })
  }

  searchOpsBy = searchOpsBy.concat(allCategoryAttributes.map((attr, attrKey) => ({
    children: (
      <Clearer
        children={
          <CheckList
            items={get(attr, 'categoryAttributeValues', []).map((item, itemKey) => ({
              checked: item.selected,
              label: item.text,
              name: item.value,
              toggleCheck: categoryAttributeCheck({
                ...props,
                searchFormValues: searchFormValuesCombined
              })({
                attrKey,
                itemKey,
                selected: item.selected
              })
            }))}
          />
        }
        onClear={clearCategoryAttribute({
          ...props,
          searchFormValues: searchFormValuesCombined
        })({ attrKey })}
      />
    ),
    title: get(attr, 'categoryAttribute.name', '')
  })))
  
  return {
    ...props,
    page: get(products, 'pageIndex', {}),
    pages: get(products, 'totalPages', {}),
    selectedProduct: get(products, 'products', []).find(x => x.id === get(props, 'selectedProductId')),
    areSearchOpsByVisible: !!searchOpsBy.length &&
      (!props.isWindowMobile || props.filtersVisible),
    cartQuantity: 0,
    relatedProducts: relatedProducts,
    isMore: get(products, 'totalPages') > 1,
    manageResults: {
      filters: allCategoryAttributes.reduce((acc, attr) => acc + get(attr, 'categoryAttributeValues', []).filter(cv => cv.selected).length, 0),
      results: get(products, 'products.length', 0),
      showFilter: props.isWindowMobile
    },
    productMeta: {
      categories: sortCategories.map(cat => ({
        label: cat.name,
        value: cat.id,
        parentCategoryId: cat.parentCategoryId
      })),
      categoryIdx: getCategoryIndexById(sortCategories, get(products, 'category')),
      date: {
        from: products.fromDate ? moment(products.fromDate).toDate() : null,
        to: products.toDate ? moment(products.toDate).toDate() : null,
        noDate: <FormattedMessage id="Products.NotDefined"/>
      },
      deliveryAddress: get(products, 'location')
    },
    products: get(products, 'products', []).filter(product => {
      const productName = product.name.toLowerCase()
      const searchInResultsTerm = get(props, 'searchInResultsTerm', '').toLowerCase()
      return !searchInResultsTerm || productName.indexOf(searchInResultsTerm) > -1
    }).map(product => ({
      describe: {
        // for: product.fullDescription
      },
      id: product.id,
      image: {
        alt: '',
        src: product.productPicture == null ? require('theme/images/thumbnail.svg') : `${API_DOMAINS.STORAGE}/${product.productPicture}`
      },
      price: product.price,
      title: product.name
    })),
    searchOpsBy: searchOpsBy.concat(allCategoryAttributes.map((attr, attrKey) => ({
      children: (
        <Clearer
          children={
            <CheckList
              items={get(attr, 'categoryAttributeValues', []).map((item, itemKey) => ({
                checked: item.selected,
                label: item.text,
                name: item.value,
                toggleCheck: categoryAttributeCheck({
                  ...props,
                  searchFormValues: searchFormValuesCombined
                })({
                  attrKey,
                  itemKey,
                  selected: item.selected
                })
              }))}
            />
          }
          onClear={clearCategoryAttribute({
            ...props,
            searchFormValues: searchFormValuesCombined
          })({ attrKey })}
        />
      ),
      title: get(attr, 'categoryAttribute.name', '')
    }))).filter(function(item, pos) {
      return searchOpsBy.indexOf(item) === pos;
  }),
    searchStatusPending: get(asyncState, `statuses.${SEARCH_PRODUCTS}`, '') === 'pending',
    sorter: {
      options: sorterOptions,
      value: find(sorterOptions, { value: get(products, 'sortBy') })
    },
  }
}
