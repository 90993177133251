import { get } from 'lodash'

export default props => () => {
  const productId = get(props, 'productMeta.id')

  const form = new FormData()
  form.set('productId', productId)
  form.set('productAttributeId', 1240)
  form.set('productSettingId', 2068)

  //props.history.push("/confirm");
  // addShoppingCartItemDetails(form)
}
