
import classnames from 'classnames'
import React from 'react'
import { compose, defaultProps, pure, setDisplayName, setPropTypes, withHandlers, withState } from 'recompose'
import { DEFAULT_PROPS, PROP_TYPES } from './config'
import { handleMouseOut, handleMouseOver } from './lib'

const enhance = compose(
  setDisplayName('views/components/buttons/ButtonSharp'),
  setPropTypes(PROP_TYPES),
  defaultProps(DEFAULT_PROPS),
  withState('hovered', 'hover', false),
  withHandlers({
    handleMouseOut,
    handleMouseOver
  }),
  pure
)

export default enhance(({
  children,
  className,
  disabled,
  handleMouseOut,
  handleMouseOver,
  hovered,
  hoverStyle,
  mod,
  onClick,
  style,
  type
}) =>
  <button
    className={classnames('button button-sharp', {
      // eslint-disable-next-line
      button_disabled: disabled,
      [`mod${mod}`]: mod,
      [className]: !!className
    })}
    disabled={disabled}
    onClick={onClick}
    onMouseOut={handleMouseOut}
    onMouseOver={handleMouseOver}
    style={hovered ? hoverStyle : style}
    type={type}
  >
    {children}
  </button>
)
