
import { FORM_ERRORS } from 'data/constants'
import { checker } from 'factories/AddressGoogle'

export default (values, props) => {
  const errors = {}
  if (values) {
    if (!values.deliveryAddress) {
      errors.deliveryAddress = FORM_ERRORS.REQUIRED
    } else if (!checker(values.deliveryAddress)) {
      errors.deliveryAddress = FORM_ERRORS.SELECT_FROM_LIST
    }

    if (!values.returnAddress && !values.useSameAddress) {
      errors.returnAddress = FORM_ERRORS.REQUIRED
    } else if (!checker(values.returnAddress)) {
      errors.returnAddress = FORM_ERRORS.SELECT_FROM_LIST
    }

    if (!values.deliveryTime) {
      errors.deliveryTime = FORM_ERRORS.REQUIRED
    }

    if (!values.returnTime) {
      errors.returnTime = FORM_ERRORS.REQUIRED
    }
  }
  return errors
}
