
import { apiCall } from 'helpers'
import { makeLogic } from 'helpers/redux'
import { pick } from 'lodash'
import * as cx from './constants'
import { API_DOMAINS } from 'data/constants'

const allCategoryAttributesByCategory = makeLogic({
  httpMethod: apiCall.get,
  latest: true,
  path: `${API_DOMAINS.CATALOG}/GetAllCategoryAttributesByCategory`,
  payload: ({ payload }) => ({ params: pick(payload, ['categoryId']) }),
  processResult: res => res.data,
  type: cx.ALL_CATEGORY_ATTRIBUTES_BY_CATEGORY
})

const allCategoryAttributesByCategoriesIds = makeLogic({
  httpMethod: apiCall.get,
  latest: true,
  path: `${API_DOMAINS.CATALOG}/GetAllCategoryAttributesByCategoriesIds`,
  payload: ({ payload }) => ({ params: pick(payload, ['categoriesIds']) }),
  processResult: res => res.data,
  type: cx.GET_ALL_ATTRIBUTES_BY_CATEGORIES
})

export default [ allCategoryAttributesByCategory, allCategoryAttributesByCategoriesIds ]
