
import PropTypes from 'prop-types'

export const DEFAULT_PROPS = {
  required: false
}

export const PROP_TYPES = {
  borderRadius: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  input: PropTypes.object,
  inputClassName: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string
  ]),
  meta: PropTypes.object,
  mod: PropTypes.oneOf([1]),
  noBorder: PropTypes.bool,
  placeholder: PropTypes.string,
  required: PropTypes.bool
}
