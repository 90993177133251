
export default [
  ...require('./modules/categories').logic,
  ...require('./modules/categoryAttributes').logic,
  ...require('./modules/notifications').logic,
  ...require('./modules/product').logic,
  ...require('./modules/products').logic,
  ...require('./modules/user').logic,
  ...require('./modules/shoppingCart').logic,
  ...require('./modules/productSettings').logic,
  ...require('./modules/orders').logic,
  ...require('./modules/currency').logic,
  ...require('./modules/languages').logic
]
