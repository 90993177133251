
import { FileInput } from 'views/components/forms'
import React from 'react'
import { Icon } from 'react-icons-kit'
import { iosCameraOutline } from 'react-icons-kit/ionicons/iosCameraOutline'
import { compose, defaultProps, pure, setDisplayName, setPropTypes, withHandlers } from 'recompose'
import { DEFAULT_PROPS, PROP_TYPES } from './config'
import { handleFileInputChange } from './lib'
import ImageRound from '../../'

const enhance = compose(
  setDisplayName('views/components/ImageRound/family/WithCamera'),
  defaultProps(DEFAULT_PROPS),
  setPropTypes(PROP_TYPES),
  withHandlers({
    handleFileInputChange
  }),
  pure
)

export default enhance(({
  backgroundColor,
  disabled,
  handleFileInputChange,
  padding,
  ...props
}) =>
  <figure
    className='withCamera'
    style={{
      backgroundColor,
      padding
    }}
  >
    <ImageRound {...props} />
    <FileInput
      accept='image/*'
      disabled={disabled}
      onChange={handleFileInputChange}
    >
      <div className='withCamera_icon'>
        <Icon icon={iosCameraOutline} size={30} />
      </div>
    </FileInput>
  </figure>
)
