
/*
 * @param {Object[]} categories - list of all categories
 * @param {number} categories[].id - category id
 * @param {string} categories[].name - category name
 * @param {number} id - identifier of the category we want to get
*/
import { find } from 'lodash'

export default (categories, id) => find(categories, { id }) || null
