
import { createAction } from 'redux-actions'
import * as cx from './constants'


export const countUnreadNotifications = createAction(cx.COUNT_UNREAD_NOTIFICATIONS)

/**
 * @param {Object} payload
 * @param {Integer} payload.page
 * @param {Integer} payload.pageSize
*/
export const getUserNotificationsPage = createAction(cx.GET_USER_NOTIFICATIONS_PAGE)

/**
 * @param {Object} payload
 * @param {Integer} payload.page
 * @param {Integer} payload.pageSize
 * @param {Integer} payload.userId
*/
export const markNotificationsReadByPage = createAction(cx.MARK_NOTIFICATIONS_READ_BY_PAGE)

/**
 * @param {Number} unreadQuantity - number of unread notifications
*/
export const setUnreadNotifications = createAction(cx.SET_UNREAD_NOTIFICATIONS, unreadQuantity => ({ unreadQuantity }))
