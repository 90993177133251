
import PropTypes from 'prop-types'

export const DEFAULT_PROPS = {
  opened: true
}

export const PROP_TYPES = {
  children: PropTypes.any,
  opened: PropTypes.bool,
  title: PropTypes.string.isRequired
}
