import { get } from 'lodash'
import { GetJobStatus } from 'helpers/orders'
import moment from 'moment'
import { FULL_DATE_FORMAT } from 'data/constants'

export default ({
  ...props
}) => {
  const items = get(props, 'orders.clientOrders.data', [])
  var details = get(props, 'orders.orderDetails', {})
  var loaded = false

  if (Object.keys(details).length > 0) {
    if (details.deliveryJob == null) {
      details.deliveryJob = {}
    }
    if (details.returnJob == null) {
      details.returnJob = {}
    }
    if (details.orderInfo == null) {
      details.orderInfo = {}
    }
    details.deliveryJob['jobStatus'] = GetJobStatus(get(details, 'deliveryJob.jobStatus', {}))
    details.returnJob['jobStatus'] = GetJobStatus(get(details, 'returnJob.jobStatus', {}))
    details.orderInfo['deliveryDate'] = details.orderInfo['deliveryDate'] ? moment(details.orderInfo['deliveryDate']).format(FULL_DATE_FORMAT) : ''
    details.orderInfo['returnDate'] = details.orderInfo['returnDate'] ? moment(details.orderInfo['returnDate']).format(FULL_DATE_FORMAT) : ''
    loaded = true
  }

  if (typeof items !== undefined && items.length > 0) loaded = true

  return {
    displayOrders: items,
    orderDetails: details,
    currency: localStorage.currency,
    isLoaded: loaded,
  ...props
  }
}
