import { handleActions } from 'redux-actions'
import { success, error } from 'helpers/redux/actionStateTypes'
import * as cx from './constants'
import { get } from 'lodash'

const initialState = {
  shoppingCardItems: [],
  relatedProducts: [],
  error: {}
}

export default handleActions({
  [success(cx.GET_SHOPPING_CART_ITEMS)]: (state, action) => ({
    ...state,
    shoppingCardItems: get(action, 'payload', [])
  }),
  [error(cx.GET_SHOPPING_CART_ITEMS)]: (state, action) => ({
    ...state,
    error: get(action, 'payload', [])
  }),
  [success(cx.GET_RELATED_PRODUCTS)]: (state, action) => ({
    ...state,
    relatedProducts: get(action, 'payload', [])
  }),
  [error(cx.GET_RELATED_PRODUCTS)]: (state, action) => ({
    ...state,
    error: get(action, 'payload', [])
  }),
  [error(cx.ADD_SHOPPING_CART_ITEM)]: (state, action) => ({
    ...state,
    error: get(action, 'payload', [])
  }),
}, initialState)