
import { methodFromProps } from 'helpers'
import { get } from 'lodash'

export default props => () => {
  const userManager = get(props, 'userManager')
  const signinRedirect = methodFromProps('signinRedirect', userManager)
  signinRedirect.call(userManager, {
    state: { loggedInFromPath: props.currentUrl }
  })
}
