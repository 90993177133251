
import { ButtonDark } from 'views/components/buttons'
import { get } from 'lodash'
import React from 'react'
import { compose, pure, setDisplayName, setPropTypes, withHandlers, withState } from 'recompose'
import { PROP_TYPES, ACTIONS } from './config'
import { FormattedMessage } from 'react-intl'
import { mapState, handleDeleteItemClick, handleEditItemClick, handleCloseUpsellModal } from './lib'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import CurrencyInput from 'react-currency-input-formatter'

const enhance = compose(
  setDisplayName('views/components/ProductInCart'),
  setPropTypes(PROP_TYPES),
  connect(mapState, ACTIONS),
  withState('upsellModal', 'toggleUpsellModal', false),
  withHandlers({
    handleDeleteItemClick,
    handleEditItemClick,
    handleCloseUpsellModal
  }),
  pure
)

export default enhance(({
  id,
  description,
  image,
  price,
  title,
  itemSettings,
  handleDeleteItemClick,
  handleEditItemClick,
  handleCloseUpsellModal,
  upsellModal,
  quantity
}) =>
  <div className='productInCart'>
    <Link to={`/product/${id}`}>
      <figure className='productInCart_image'>
        <img
          alt={get(image, 'alt', '')}
          src={get(image, 'src', '')}
        />
      </figure>
    </Link>
    <div className='productInCart_content'>
      <div className='productInCart_content_inner'>
        <Link to={`/product/${id}`}>
          <p className='productInCart_content_hd'>
            {title}
          </p>
        </Link>
        <div className='productInCart_content_desc'>
          {description}
          {/* <span className='productInCart_content_edit'>
            <button onClick={handleEditItemClick}>
              <FormattedMessage id="ProductCart.Content.Edit"/>,
            </button>
            {upsellModal ? (
              <Modal
                children={
                  <article className='productPage_modal'>
                    Edit Product <span className='productPage_modal_price'>
                    </span>
                    <br />                    
                    <footer className='productPage_modal_footer'>
                      <Link to='/confirm'>
                        <FormattedMessage id="ProductPage.Content.Modal.Buttons.Checkout">
                          {
                            (msg) => (
                              <ButtonDark children={msg} />
                            )
                          }
                        </FormattedMessage>               
                      </Link>
                    </footer>
                  </article>
                }
                onClose={handleCloseUpsellModal}
              />
            ) : null}
          </span> */}
        </div>
        <div className='productInCart_content_bottom'>
          <span className='productInCart_content_price'>
            <CurrencyInput
              value={price}
              prefix={localStorage.currency}
              decimalSeparator="."
              thousandSeparator=","
              precision="2"
            />
          </span>
          <ButtonDark
            mod={1}
            children={<FormattedMessage id="Products.Buttons.Delete" />}
            onClick={handleDeleteItemClick}
          />
        </div>
        <div>
          <div className='mt-3'>
            <div><FormattedMessage id="ProductCart.Content.Quantity" /></div>: {quantity}
          </div>
          <div className='mt-3'>
            <div><FormattedMessage id="ProductCart.Content.ProductSettings" /></div>
            {itemSettings !== null ? (itemSettings.map((item, key) => {
              return (
                <div className='mt-3'>
                  {item.categoryAttributeName} : {item.categoryAttributeMultipleValues === undefined ? '' : item.categoryAttributeMultipleValues[0]}
                </div>
              )
            })) : null}
          </div>
        </div>
      </div>
    </div>
  </div>
)
