
import { methodFromProps } from 'helpers'

export default props => data => {
  const fields = { ...props.categoryAttrValues.fields }
  const setCategoryAttrValue = methodFromProps('setCategoryAttrValue', props)

  if (data.data === null) {
    delete fields[data.name]
  } else {
    fields[data.name] = data.data
  }

  setCategoryAttrValue({ fields })
}
