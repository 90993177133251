
import { saveDefaultSearch } from 'helpers/user'
import { methodFromProps } from 'helpers'
import { get } from 'lodash'

export default props => () => {
    const inputValue = document.getElementsByName('place')[0].value
    const startDate = get(props,'StartDefaultDate','')
    const endDate = get(props,'EndDefaultDate','')

    saveDefaultSearch(inputValue, startDate, endDate)

    var form = new FormData()
    form.set('location', inputValue)
    form.set('startDate', startDate)
    form.set('endDate', endDate)
    const saveUserDefaultSearch = methodFromProps('saveUserDefaultSearch', props)
    saveUserDefaultSearch(form)
  }