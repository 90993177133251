
import { FORM_ERRORS, SPECIAL_CHARS } from 'data/constants'
import { FORM_ERRORS as PAGE_FORM_ERRORS } from '../config'

export default values => {
  const res = {}
  if (values) {
    if (!values.oldPassword) {
      res.oldPassword = FORM_ERRORS.REQUIRED
    }
    if (!values.newPassword) {
      res.newPassword = FORM_ERRORS.REQUIRED
    } else if (values.newPassword.length < 8) {
      res.newPassword = PAGE_FORM_ERRORS.MIN_LENGTH
    } else if (!values.newPassword.match(SPECIAL_CHARS)) {
      res.newPassword = FORM_ERRORS.SPECIAL_CHARS
    }
    if (values.newPassword !== values.confirmPassword) {
      res.confirmPassword = FORM_ERRORS.MATCH_NEW_PASSWORD
    }
  }
  return res
}
