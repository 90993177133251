export default {
    "Forms.Search2.Placeholder": "Rechercher autour de moi",
    "InfoList.AboutUs": "À propos de nous",
    "InfoList.Faq": "Faq",
    "InfoList.Terms & Conditions": "Conditions générales",
    "Lists.Menu.WithoutPath": "Sans chemin",
    "Lists.Menu.InsidePath": "Chemin interne",
    "Lists.Menu.OutsidePath": "Chemin extérieur",
    "Lists.Menu.OpenPage": "Ouvre dans une nouvelle page",
    "UserMenu.ShopNow": "Acheter maintenant",
    "UserMenu.OrderHistory": "Historique des commandes",
    "UserMenu.Profile": "Profil",
    "UserMenu.ResetPassword": "Réinitialiser le mot de passe",
    "UserMenu.Logout": "Déconnexion",
    "Header.WorkWithUs": "Travaillez avec nous",
    "Footer.GetInTouch": "Entrez en contact",
    "ProductsList.SkisAndBoards": "Skis & boards",
    "ProductsList.Boots": "Bottes",
    "ProductsList.Accessories": "Accessoires",
    "WorkFloatList.Search.Description": "Explorez des skis jusqu'à 10 km autour de vous!",
    "WorkFloatList.Rent.Description": "Réservez vos skis préférés aux prix les plus bas!",
    "WorkFloatList.Receive.Description": "Rencontrez notre chauffeur à votre endroit préféré et profitez de vos skis!",
    "WorkFloatList.Search": "Chercher",
    "WorkFloatList.Rent": "Location",
    "WorkFloatList.Receive": "Recevoir",
    "App.Loading": "Chargement de l'application ...",
    "FormErrors.IncorrectValue:": "Valeur incorrecte",
    "FormErrors.NewPassword": "Doit correspondre au nouveau mot de passe",
    "FormErrors.Required": "* Obligatoire",
    "FormErrors.SelectFromList": "Sélectionner dans la liste",
    "FormErrors.SpecialCharacters": "Doit contenir des caractères spéciaux",
    "NotFound.Default": "Désolé, nous n'avons trouvé aucun résultat correspondant à vos critères de recherche",
    "PointItems.ChooseShopsAround.Description": "Possibilité d’explorer et de choisir des skis et des produits parmi une douzaine de magasins jusqu’à 10 km",
    "PointItems.ComparePriceAndProducts.Description": "Comparaison de prix et de produits entre différents magasins",
    "PointItems.NoMoreQueueing.Description": "Finies les files d'attente dans les magasins surpeuplés grâce aux chauffeurs à la demande qui fourniront des skis et des accessoires quand et où les skieurs voudront!",
    "Fields.CardName.Placeholder": "Nom du titulaire",
    "PasswordChange.ErrorMessage": "La modification du mot de passe a échoué. Réessayez",
    "PasswordChanged.SuccessMessage": "Le mot de passe a été modifié avec succès",
    "HeadedForm.Buttons.ResetPassword": "Réinitialiser le mot de passe",
    "Fields.FirstName": "Prénom",
    "Fields.LastName": "Nom de famille",
    "Fields.Email": "Email",
    "Fields.CountryPrefix": "Préfixe de pays",
    "Fields.PhoneNumber": "Numéro de téléphone",
    "Fields.HomeAddress": "Adresse personnelle",
    "Fields.HomeAddress.Placeholder": "Entrez un emplacement",
    "UserProfile.Buttons.UpdateCard": "Carte de mise à jour",
    "UserProfile.Header.UpdateCard": "Carte de mise à jour",
    "UserProfile.Buttons.SaveChanges": "Enregistrer les modifications",
    "UserProfile.Buttons.Cancel": "Annuler",
    "MainOffer.SkiHire.Description": "Livré où vous le souhaitez",
    "MainOffer.SkiHire": "Location de skis",
    "MainOffer.SkiHire.Label": "Réservez votre ski préféré",
    "MainOffer.SkiHire.Label2": "Service de livraison",
    "Forms.Search.Placeholder": "Entrez l'adresse de livraison",
    "PanierRésumé.Cart": "Panier:",
    "CartSummary.Total": "Totale:",
    "CartSummary.Buttons.ClearCart": "effacer le panier",
    "ProductMeta.Category": "Catégorie:",
    "ProductMeta.DeliverTo": "Livrer à",
    "ProductMeta.Date": "Rendez-vous amoureux:",
    "Fields.NomClient.Placeholder": "Nom du client",
    "Forms.Delivery.DeliveryTime.Label": "Délai de livraison",
    "Forms.Delivery.DeliveryTime.Placeholder": "Sélectionnez un délai de livraison",
    "Forms.Delivery.ReturnAddress.Label": "Adresse de retour",
    "Forms.Delivery.ReturnAddress.Placeholder": "Entrez l'adresse de retour",
    "Forms.Delivery.ReturnTime.Label": "Heure de retour",
    "Forms.Delivery.ReturnTime.Placeholder": "Sélectionnez une heure de retour",
    "SearchExtended.GeoSuggestInputIconed.Label": "Adresse de livraison",
    "SearchExtended.SelectIconed.Label": "Vous cherchez?",
    "Components.LookingIn": "Vous regardez dans",
    "ProductCart.Content.Edit": "modifier",
    "ProductCart.Content.ProductSettings": "Impostazioni del prodotto",
    "ProductCart.Content.Quantity": "Quantité",
    "ProductPage.Content.For": "Pour:",
    "ProductPage.Content.Level": "Niveau:",
    "ProductPage.Content.Shop": "Boutique:",
    "ProductPage.Content.Description": "La description:",
    "Components.Sorter": "Trier par",
    "WorkFloatList.HowItWorks": "Comment ça marche",
    "ProductsPage.Content.OurProducts": "Nos produits",
    "HeadedForm.Fields.OldPassword.Placeholder": "Ancien mot de passe",
    "HeadedForm.Fields.NewPassword.Placeholder": "Nouveau mot de passe",
    "HeadedForm.Fields.ConfirmPassword.Placeholder": "Confirmer le mot de passe",
    "Components.Blocks.InnerBanner.Title": "Titre de la bannière",
    "Components.Blocks.ProductMeta.DeliveryAddress.Label": "Rue, maison, ville, état",
    "Components.Blocks.ProductInCard.Title": "Titre du produit",
    "Components.Blocks.ProductSec.Name": "Nom du produit",
    "Components.Blocks.Rollet.Title": "Rollet",
    "Components.Blocks.Scrolled.Title": "Fait défiler",
    "AboutPage.Content.Decription": "Deliquity est une plate-forme innovante qui permet aux skieurs d'explorer et de louer des skis et des accessoires de magasins à moins de 10 km et de les faire livrer, par des conducteurs à la demande, aux lieux de prédilection des skieurs à temps!",
    "AboutPage.Content.Invite": "Rejoignez deliverki aujourd'hui ou pour toute information complémentaire, n'hésitez pas à nous contacter à l'adresse",
    "AboutPage.Content.JoinUs": "Rejoignez-nous",
    "LogOutPage.Content.Process": "Déconnexion en cours ...",
    "AuthPage.Content.Process": "Autorisation en cours de traitement ...",
    "CardPage.Content.ExtraProduct": "+ Ajouter un produit supplémentaire pour",
    "CardPage.Content.Rollet.Title": "Délai de livraison et de retour",
    "CardPage.Content.Buttons.ContinueShopping": "Continuer mes achats",
    "CardPage.Content.Buttons.Checkout": "Check-out!",
    "ProductPage.Content.Breadcrumbs.Label": "Retour",
    "NotificationPage.Content.Name": "Les notifications",
    "NotificationPage.Content.NotFound": "Aucune notification trouvée",
    "OrdersPage.Content.Name": "Page de commandes",
    "ProductPage.Content.NotFound": "Ce produit n'a pas encore d'images",
    "ProductPage.Content.Modal.OrderTotal": "Total de la commande:",
    "ProductPage.Content.Modal.AddProducts": "Souhaitez-vous ajouter l'un des produits suivants?",
    "ProductPage.Content.Modal.Buttons.Checkout": "NON, amenez-moi à la caisse",
    "Search.Products.MoreResult": "OH ... NOUS AVONS PLUS DE RÉSULTATS",
    "TermsPage.Title": "Bienvenue dans Deliquity!",
    "TermsPage.Header": "Prenez le texte relatif aux conditions d'utilisation de la base de données.",
    "FormErrors.MinLenght": "Le mot de passe doit comporter 8 caractères ou plus",
    "UserPage.Content.SuccessUpdate": "Les informations personnelles ont été mises à jour avec succès",
    "UserPage.Content.Title": "Informations personnelles",
    "UserMenu.Buttons.Label.Login": "s'identifier",
    "Footer.AllRightsReserved": "Tous droits réservés 2017",
    "Products.Product.Description": "Description du produit",
    "Products.Buttons.Delete": "Supprimer",
    "Products.Buttons.Location": "Emplacement",
    "Products.Result":"Résultat",
    "Products.NotDefined": "Non défini",
    "Select.None": "Aucun",
    "Select.LowerPrice": "Prix ​​inférieur",
    "Select.HigherPrice": "Prix ​​plus élevé",
    "Products.Product.Price": "Prix :",
    "Products.Product.Book": "Reserve maintenant",
    "SearchExtended.Buttons.Search": "Chercher",
    "SearchExtended.Label.To": "à",
    "Customer.Button.Confirm": "Conferma i dettagli del cliente",
    "Customer.Name.Placeholder":"Nome del cliente",
    "CheckoutPage.Buttons.PlaceOrder": "Invia Ordine",
    "CheckoutPage.Rollet.Title": "Dettagli Dell'ordine",
    "Forms.Delivery.DeliveryAddress.Label": "Indirizzo di consegna",
    "Forms.Delivery.DeliveryAddress.Placeholder": "Imposta l'indirizzo di consegna",
    "Forms.Delivery.SameAddress.Title": "Utilizza lo stesso indirizzo per la consegna e il reso",
    "Forms.Delivery.DeliveryDate": "Data di consegna",
    "Forms.Delivery.ReturnDate": "Data di ritorno",
    "CheckoutPage.TotalPrice":"Prezzo totale",
    "Forms.Delivery.CouponCode.Label": "Codice Coupon",
    "Forms.Delivery.CouponCode.Placeholder": "Immettere il codice coupon",
    "OrderPage.ItemsInOrder": "Articoli in ordine",
    "OrderPage.PaymentStatus": "Stato del pagamento",
    "OrderPage.DeliveryAddress": "Indirizzo di consegna",
    "OrderPage.ReturnAddress": "Indirizzo di ritorno",
    "OrderPage.Total": "Prezzo totale",  
    "Product.Loading": "Les détails du produit sont en cours de chargement ...",
    "ShoppingCart.Loading": "Les articles du panier sont en cours de chargement ...",
    "Order.Loading": "L'ordre crée ...",
    "Components.Table.Page": "Page",
    "Components.Table.Of": "de",
    "Components.Table.GoToPage": "Aller à la page",
    "Components.Table.Show": "Spectacle",
    "Orders.Loading":"Les commandes sont en cours de chargement...",
    "OrderStatus.Pending": "En attente",
    "OrderStatus.Canceled": "Canceled",
    "OrderStatus.Active": "Annulé",
    "OrderStatus.Completed": "Complété",
    "OrderStatus.NotStarted": "Pas commencé",
    "OrderStatus.Blocked": "Bloqué",
    "OrderStatus.UnBlocked": "Débloqué",
    "OrderStatus.CancelledByUser": "Annulé par l'utilisateur",
    "OrderStatus.CancelledByMerchant": "Annulé par le marchand",
    "OrderStatus.CancelledByAdmin": "Annulé par l'administrateur",
    "OrderStatus.Delayed": "Retardé",
    "OrderStatus.Returned": "Revenu",
    "OrderStatus.SelfReturn": "Retour de soi",
    "OrderStatus.SelfReturnedInProgress": "Retour automatique en cours",
    "OrderStatus.ReturnInProgress": "Retour en cours",
    "OrderStatus.AwaitingForReturnPickUp": "En attente de ramassage de retour",
    "OrderStatus.AwaitingForReturnAcceptance": "En attente d'acceptation de retour",
    "OrderStatus.Delivered": "Livré",
    "OrderStatus.AwaitingForPickUp": "En attente de ramassage",
    "OrderStatus.AwaitingForAcceptance": "En attente d'acceptation",
    "OrderStatus.DeliveryInProgress": "Livraison en cours",
    "OrderDeliveryType.OnlyDelivery": "Livraison uniquement (retour automatique)",
    "OrderDeliveryType.OnlyReturn": "Seulement retour (à emporter)",
    "OrderDeliveryType.DeliveryAndReturn": "Livraison et retour",
    "OrderDeliveryType.None": "À emporter et retour de soi",
    "JobStatus.Accepted":"Accepté",
    "JobStatus.NotAssigned":"Non attribué",
    "JobStatus.Assigned":"Attribué",
    "JobStatus.Completed":"Complété",
    "JobStatus.Cancelled":"Annulé",
    "JobStatus.Collected":"Recueilli",
    "JobStatus.TakeAway":"Emporter",
    "JobStatus.SelfReturn":"Retour de soi",
    "JobStatus.Emergency":"Urgence",
    "JobStatus.Blocked":"Bloqué",
    "JobStatus.Unblocked":"Débloqué",
    "OrderPage.DeliveryDate": "Date de livraison",
    "OrderPage.ReturnDate":"Date de retour",
    "OrderPage.JobStatus":"Statut du travail",
    "OrderPage.DropAddress":"Adresse de dépôt",
    "OrderPage.PickUpAddress":"Adresse de ramassage",
    "OrderDetails.Loading":"Les détails de la commande sont en cours de chargement...",
    "OrderPage.OrderCreateSuccess":"La commande est créée avec succès!",
    "OrderPage.Merchant.FullName":"Nom et prénom",
    "OrderPage.Merchant.Email":"E-mail",
    "OrderPage.Merchant.PhoneNumber":"Numéro de téléphone",
    "OrderPage.Merchant.ShopName":"Nom de la boutique",
    "OrderPage.Merchant.ShopAddress":"Adresse de la boutique",
    "ProductsPage.Modal.Buttons.ShoppingCart": "Panier",
    "ProductsPage.Modal.Title":"Ajouter le produit au panier",
    "ProductsPage.Modal.ProductName":"Nom du produit :",
    "ProductsPage.Modal.Quantity":"Quantité:",
    "ProductsPage.Modal.RelatedProducts":"Produits connexes:",
    "LandingPage.LocationModal.Title": "Veuillez choisir l'emplacement:",
    "LandingPage.LocationModal.ButtonSave": "Sauvegarder",
    "LandingPage.LocationModal.DateTitle": "Sélectionnez des dates",
    "ProductsPage.Radius.Label": "Sélectionnez le rayon de la distance marchande en km"
}