
import { Status } from 'views/components'
import React, { Component } from 'react'
import { compose, mapProps, pure, setDisplayName, withHandlers } from 'recompose'
import { logout, propsMapper } from './lib'
import { FormattedMessage } from 'react-intl';

const enhance = compose(
  setDisplayName('views/routes/auth/logout/Page'),
  mapProps(propsMapper),
  withHandlers({
    logout
  }),
  pure
)

class Page extends Component {
  componentDidMount () {
    this.props.logout()
  }

  render () {
    return (
      <div className='container' style={{ padding: '1px 0' }}>
        <p className='text-center'>
          <FormattedMessage id="LogOutPage.Content.Process"/>
        </p>
        <Status />
      </div>
    )
  }
}
export default enhance(Page)
