
import PropTypes from 'prop-types'

export const DEFAULT_PROPS = {
  dateFormat: 'YYYY/MM/DD',
  placeholder: 'Click to select a date'
}

export const PROP_TYPES = {
  dateFormat: PropTypes.string,
  disableNonUsed: PropTypes.bool,
  minDate: PropTypes.object,
  placeholder: PropTypes.string,
  timeOnly: PropTypes.bool
}
