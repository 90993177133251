
import React from 'react'
import { Link } from 'react-router-dom'
import { compose, defaultProps, pure, setDisplayName, setPropTypes } from 'recompose'
import { DEFAULT_TYPES, PROP_TYPES } from './config'

const enhance = compose(
  setDisplayName('views/components/texts/Anchor'),
  setPropTypes(PROP_TYPES),
  defaultProps(DEFAULT_TYPES),
  pure
)

export default enhance(({
  children,
  href,
  ...props
}) => href.indexOf('http') === 0 ? (
  <a
    href={href}
    {...props}
  >
    {children}
  </a>
) : (
  <Link
    to={href}
    {...props}
  >
    {children}
  </Link>
))
