
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { compose, pure, setDisplayName } from 'recompose'
import { LibMenu } from './components'
import Page from './Page'

const enhance = compose(
  setDisplayName('views/routes/_lib/Router'),
  pure
)

export default enhance(() =>
  <div>
    <LibMenu
      items={[
        {
          label: 'Components',
          path: ''
        },
        {
          label: 'Blocks',
          path: '/blocks'
        }
      ]}
    />
    <Switch>
      <Route
        exact
        component={Page}
        path='/_lib'
      />
      <Route
        component={require('./_r/blocks').Router}
        path='/_lib/blocks'
      />
    </Switch>
  </div>
)
