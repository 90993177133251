
import classnames from 'classnames'
import { Status } from 'views/components'
import { ButtonSharp } from 'views/components/buttons'
import { GeoSuggestInput, Input } from 'views/components/forms'
import { WithCamera } from 'views/components/ImageRound/family'
import { omit } from 'lodash'
import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { When } from 'react-if'
import { compose, mapProps, pure, setDisplayName, setPropTypes, withHandlers } from 'recompose'
import { FIELDS, PROP_TYPES } from './config'
import { handleResetClick, handleUpdateCard, propsMapper, validate } from './lib'
import { FormattedMessage } from 'react-intl'

const enhance = compose(
  setDisplayName('views/blocks/forms/UserProfile'),
  setPropTypes(PROP_TYPES),
  reduxForm({
    form: 'views/component/forms/UserProfile',
    validate
  }),
  mapProps(propsMapper),
  withHandlers({
    handleResetClick,
    handleUpdateCard
  }),
  pure
)

export default enhance(({
  avatar,
  handleResetClick,
  handleSubmit,
  handleUpdateCard,
  isDisabled,
  isPending,
  onSubmit,
  updateAvatar
}) =>
  <form
    className='userProfile'
    onSubmit={handleSubmit(onSubmit)}
  >
    <div className='userProfile_avatar'>
      <WithCamera
        backgroundColor='#fff'
        borderColor='#555'
        disabled={isDisabled}
        onImageUpload={updateAvatar}
        padding={8}
        size={144}
        src={avatar}
      />
    </div>
    <div className='row userProfile_fields'>
      {FIELDS.map((fld, key) => (
        <div
          className={classnames('col-12 col-sm-6', {
            // eslint-disable-next-line
            ['col-md-4']: key < 3,
            // eslint-disable-next-line
            ['col-md-8']: key === FIELDS.length - 1
          })}
          key={key}
        >
          <Field
            noBorder
            required
            borderRadius={key === FIELDS.length - 1 ? '0.25em' : null}
            component={fld.geoSuggest ? GeoSuggestInput : Input}
            disabled={isDisabled}
            mod={1}
            {...omit(fld, ['geoSuggest'])}
          />
        </div>
      ))}
    </div>
    <div className='userProfile_bottom'>
      <div className='userProfile_btn_status'>
        <ButtonSharp
          mod='2'
          onClick={handleUpdateCard}
        >
          <FormattedMessage id="UserProfile.Buttons.UpdateCard"/>
        </ButtonSharp>
        <When condition={isPending}>
          <Status size={35} />
        </When>
      </div>
      <div className='userProfile_btn_wrap'>
        <div className='userProfile_btn'>
          <ButtonSharp
            disabled={isDisabled}
            mod='2'
            type='submit'
          >
            <FormattedMessage id="UserProfile.Buttons.SaveChanges"/>
          </ButtonSharp>
        </div>
        <div className='userProfile_btn'>
          <ButtonSharp
            disabled={isDisabled}
            mod='3'
            onClick={handleResetClick}
          >
            <FormattedMessage id="UserProfile.Buttons.Cancel"/>
          </ButtonSharp>
        </div>
      </div>
    </div>
  </form>
)
