
import { FORM_ERRORS } from 'data/constants'

export default values => {
  const errors = {}
  if (!values.place) {
    errors.place = FORM_ERRORS.REQUIRED
  }
  if (typeof values.place === 'string') {
    errors.place = FORM_ERRORS.SELECT_FROM_LIST
  }
  return errors
}
