
import { updateUserPassword } from 'redux/modules/user/actions'

export const ACTIONS = {
  updateUserPassword
}

export const FIELDS = [
  {
    name: 'oldPassword',
    placeholder: 'Old Password',
    type: 'password'
  },
  {
    name: 'newPassword',
    placeholder: 'New Password',
    type: 'password'
  },
  {
    name: 'confirmPassword',
    placeholder: 'Confirm Password',
    type: 'password'
  }
]

export const FORM_ERRORS = {
  MIN_LENGTH: 'Password must have 8 characters or more'
}
