import axios from 'axios'
import { getTokens } from 'helpers/auth'
import { get } from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import { Route, withRouter } from 'react-router-dom'
//import { Route, useNavigate } from 'react-router-dom'
import { compose, mapProps, pure, setDisplayName } from 'recompose'
import 'theme/styles/index.css'
import { ACTIONS } from './config'
import { mapState, propsMapper, signalR, dataLoader } from './lib'
import { IntlProvider } from "react-intl"
import { messages_de, messages_en,  messages_fr} from 'helpers/text/translations'
import { dataLoad } from 'helpers/redux'

const message = {
  'de-de': messages_de,
  'en-us': messages_en,
  'fr-fr': messages_fr
}

const enhance = compose(
  setDisplayName('App'),
  withRouter,
  //useNavigate,
  connect(mapState, ACTIONS),
  dataLoad(dataLoader),
  mapProps(propsMapper),
  signalR(),
  pure
)

export default enhance(({
  auth,
  authOnly
}) => {
  const accessToken = get(getTokens(auth), 'accessToken')
  if (accessToken) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`
  }
  if (localStorage.currency === undefined) {
    localStorage.currency = "USD"
  }
  return (
    <IntlProvider locale={ localStorage.lang } messages={ message[localStorage.lang]} defaultLocale='en-us'>
      <div style={{ height: '100%' }}>
        <Route
          exact
          component={require('views/routes').Router}
          path='/'
        />

        <Route
          component={require('views/routes/_r/auth').Router}
          path='/auth'
        />

        <Route
          component={require('views/routes/_r/about').Router}
          path='/about'
        />

        <Route
          component={require('views/routes/_r/cart').Router}
          path='/cart'
        />

        <Route
          component={require('views/routes/_r/confirm').Router}
          path='/confirm'
        />

        <Route
          component={require('views/routes/_r/faq').Router}
          path='/faq'
        />

        <Route
          component={authOnly(require('views/routes/_r/orders').Router)}
          path='/orders'
        />

        <Route
          component={authOnly(require('views/routes/_r/order').Router)}
          path='/order'
        />

        <Route
          component={authOnly(require('views/routes/_r/notifications').Router)}
          path='/notifications'
        />

        <Route
          component={require('views/routes/_r/product').Router}
          path='/product'
        />

        <Route
          component={require('views/routes/_r/search').Router}
          path='/search'
        />

        <Route
          component={require('views/routes/_r/terms').Router}
          path='/terms'
        />

        <Route
          component={authOnly(require('views/routes/_r/user').Router)}
          path='/user'
        />

        <Route
          component={require('views/routes/_r/checkout').Router}
          path='/checkout'
        />

        {/* TODO: remove further. These are components library */}
        <Route
          component={require('views/routes/_r/_lib').Router}
          path='/_lib'
        />
      </div>
    </IntlProvider>
  )
})


// import logo from './logo.svg';
// import './App.css';

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

// export default App;
