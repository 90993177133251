import Geocode from 'react-geocode'

export default () => {
    // if (navigator.geolocation) {
    //     navigator.geolocation.getCurrentPosition((position) => {
    //         Geocode.setApiKey(process.env.REACT_APP_GOOGLE_API_TEST_KEY);
    //         Geocode.fromLatLng(position.coords.latitude, position.coords.longitude).then(
    //          response => {
    //             var address = response.results[0].formatted_address
    //             localStorage.setItem('location',address)
    //           },
    //           error => {
    //             return(error);
    //           }
    //         );
    //     }, (error) => {
    //       return error
    //     }, {enableHighAccuracy: true, timeout: 5000, maximumAge: 30000 })
    //   }
  }