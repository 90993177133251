
import { CartSummary, DeliverTo, ProductInCart, Rollet } from 'views/components'
import { ButtonDark } from 'views/components/buttons'
import { ResponsiveList } from 'views/components/lists'
import { get } from 'lodash'
import { Page } from 'views/pages'
import React from 'react'
import ReactResizeDetector from 'react-resize-detector';
import { Link } from 'react-router-dom'
import { compose, mapProps, pure, setDisplayName, withHandlers, getContext } from 'recompose'
import { propsMapper, dataLoader, mapState, handleClearShoppingCart, handleCheckout } from './lib'
import { dataLoad } from 'helpers/redux'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { ACTIONS, CONTEXT_TYPES } from './config'

const enhance = compose(
  setDisplayName('views/routes/cart/Page'),
  dataLoad(dataLoader),
  connect(mapState, ACTIONS),
  getContext(CONTEXT_TYPES),
  mapProps(propsMapper),
  withHandlers({
    handleClearShoppingCart,
    handleCheckout
  }),
  pure
)

export default enhance(({
  cartQuantity,
  currency,
  deliverTo,
  productsInCart,
  totalAmount,
  users,
  usersInCart,
  handleClearShoppingCart,
  handleCheckout,
  checkoutDisable,
  error
}) =>
  <Page
    cartQuantity={cartQuantity}
    className='cartPage'
  >
    <DeliverTo address={deliverTo} />
    <CartSummary
      clearCart={handleClearShoppingCart}
      currency={currency}
      products={productsInCart}
      total={totalAmount}
      users={usersInCart}
    />
    <section className='cartPage_cnt container'>
      {users.map((user, key) => {
        const totalPrice = user.products.reduce((total, product) => total + product.price, 0)
        return (
          <Rollet
            key={key}
            opened={!key}
            title={`${user.name == null ? '' : user.name} ${Math.round(totalPrice * 100) / 100} ${currency}`}
          >
            {get(user, 'products.length') ? (
              <ReactResizeDetector handleWidth>
                {({ width }) => (
                  <ResponsiveList
                    items={user.products.map((product, key) =>
                      <ProductInCart {...product} />
                    )}
                    itemsInRow={width > 768 ? 2 : 1}
                  />
                )}
              </ReactResizeDetector>
            ) : null}
            <p className='cartPage_addExtra'>
              <Link
                className='cartPage_addExtra_link'
                to='/'
              >
                <FormattedMessage id="CardPage.Content.ExtraProduct" /> {user.name}
              </Link>
            </p>
          </Rollet>
        )
      })}
      <div className='cartPage_buttons container'>
        <div className='row'>
          <div className='col-12 col-sm-6'>
            <Link to='/'>
              <ButtonDark>
                <FormattedMessage id="CardPage.Content.Buttons.ContinueShopping" />
              </ButtonDark>
            </Link>
          </div>
          <div className='col-12 col-sm-6'>
            <ButtonDark type='submit' onClick={handleCheckout} disabled={checkoutDisable}>
              <FormattedMessage id="CardPage.Content.Buttons.Checkout" />
            </ButtonDark>
          </div>
        </div>
      </div>
    </section>
  </Page>
)
