
import { get } from 'lodash'

export default state => ({
  asyncState: get(state, 'asyncState', {}),
  shoppingCart: get(state, 'shoppingCart', {}),
  user: get(state, 'user', {}),
  product: get(state, 'product'),
  productSettings: get(state, 'productSettings'),
  products: get(state, 'products', {}),
  categories: get(state, 'categories', {}),
})