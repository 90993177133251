
import React from 'react'
import { OrderItem, Loader } from 'views/components'
import { compose, mapProps, pure, setDisplayName, setPropTypes } from 'recompose'
import { propsMapper, dataLoader, mapState, } from './lib'
import { dataLoad } from 'helpers/redux'
import { connect } from 'react-redux'
import { Page } from 'views/pages'
import { PROP_TYPES } from './config'
import { FormattedMessage } from 'react-intl'

const enhance = compose(
  setDisplayName('views/routes/order/Page'),
  setPropTypes(PROP_TYPES),
  dataLoad(dataLoader),
  connect(mapState),
  mapProps(propsMapper),
  pure
)

export default enhance(({
    displayOrders,
    orderDetails,
    currency,
    isLoaded,
  }) =>
    <Page
        className='cartPage'
    >
      <Loader message={<FormattedMessage id="OrderDetails.Loading"/>} isLoaded={isLoaded}/>
      <OrderItem orders={displayOrders} details={orderDetails} currency={currency}/>
    </Page>
)
