
import { Overlay } from 'views/components'
import React from 'react'
import { Icon } from 'react-icons-kit'
import { androidClose } from 'react-icons-kit/ionicons/androidClose'
import { compose, pure, setDisplayName, setPropTypes, withHandlers, withState } from 'recompose'
import { PROP_TYPES } from './config'
import { handleCloseClick, handleCurtainClick } from './lib'

const enhance = compose(
  setDisplayName('views/components/modals/Modal'),
  setPropTypes(PROP_TYPES),
  withState('opened', 'open', true),
  withHandlers({
    handleCloseClick,
    handleCurtainClick
  }),
  pure
)

export default enhance(({
  children,
  fullWidth,
  handleCloseClick,
  handleCurtainClick,
  opened
}) =>
  <Overlay
    fullWidth={fullWidth}
    clickCurtain={handleCurtainClick}
    visible={opened}
  >
    <div className='modall'>
      <Icon
        className='modall_close'
        icon={androidClose}
        onClick={handleCloseClick}
        size={20}
      />
      {children}
    </div>
  </Overlay>
)
