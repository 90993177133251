
import PropTypes from 'prop-types'
import uniqid from 'uniqid'

export const DEFAULT_PROPS = {
  filters: 0,
  results: 0,
  showFilter: false
}

export const FORM_NAME = 'views/components/ManageResults--searchForm-' + uniqid()

export const PROP_TYPES = {
  clickFilter: PropTypes.func,
  filters: PropTypes.number,
  results: PropTypes.number,
  showFilter: PropTypes.bool,
  submitSearch: PropTypes.func
}
