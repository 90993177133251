import { get } from 'lodash'
import { useToasts } from 'react-toast-notifications'
import { API_DOMAINS } from 'data/constants'
import { useEffect } from 'react';


export default ({
  shoppingCart,
  router,
  ...props
}) => {
  const { addToast } = useToasts();
  const state = props.state
  var users = []
  var isCheckOutDisable = true
  const shoppingCardItems = get(state, 'shoppingCart.shoppingCardItems', [])
  const error = get(state, 'shoppingCart.error')

  useEffect(() => {
    if (error !== undefined && error.message !== undefined) {
      addToast(error.message, { appearance: 'error', autoDismiss: true })
    }
  }, [])



  if (typeof shoppingCardItems !== 'undefined' && shoppingCardItems.length > 0) {
    isCheckOutDisable = false
    users = shoppingCardItems.map(item => ({
      name: item.customerName,
      // eslint-disable-next-line
      products: shoppingCardItems.map(element => {
        if (item.customerName === element.customerName) {
          return {
            id: element.product.id,
            description: element.product.fullDescription,
            image: {
              alt: require('theme/images/boot.jpg'),
              src: element.product.productPicture == null ? require('theme/images/thumbnail.svg') : `${API_DOMAINS.STORAGE}/${element.product.productPicture}`,
            },
            price: element.product.price,
            quantity: element.quantity,
            title: element.product.name,
            shoppingCartId: element.id,
            itemSettings: element.itemSettings,
          }
        }
      }).filter(item => item !== undefined)
    }))

    users = Array.from(new Set(users.map(s => s.name)))
      .map(name => {
        return {
          name: name,
          products: users.find(s => s.name === name).products,
        }
      })
  }

  var address = get(state, 'products.location.label')

  return {
    ...props,
    cartQuantity: 1,
    currency: localStorage.currency,
    deliverTo: address,
    productsInCart: users.reduce((products, user) => products + get(user, 'products.length', 0), 0),
    totalAmount: users.reduce((totalAmount, user) => {
      const arr = Array.from(user.products)
      const prices = arr.reduce((total, product) => total + product.price, 0)
      return Math.round((totalAmount + prices) * 100) / 100
    }, 0),
    users,
    usersInCart: users.length,
    router: router,
    error: error,
    checkoutDisable: isCheckOutDisable
  }
}
