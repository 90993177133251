
import PropTypes from 'prop-types'

export const PROP_TYPES = {
  disabled: PropTypes.bool,
  modify: PropTypes.oneOf(['1']),
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string
    ])
  })),
  placeholder: PropTypes.string,
  small: PropTypes.bool,
  value: PropTypes.any
}
