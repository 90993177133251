
import { NOT_FOUND } from 'data/constants'
import React from 'react'
import { compose, pure, setDisplayName, setPropTypes } from 'recompose'
import { PROP_TYPES } from './config'

const enhance = compose(
  setDisplayName('views/components/texts/NotFound'),
  setPropTypes(PROP_TYPES),
  pure
)

export default enhance(({
  text
}) =>
  <div className='notFound'>
    {text || NOT_FOUND.DEFAULT}
  </div>
)
