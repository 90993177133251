
import React from 'react'
import { compose, mapProps, pure, setDisplayName, withHandlers } from 'recompose'
import { propsMapper, dataLoader, mapState, handleClickPage } from './lib'
import { dataLoad } from 'helpers/redux'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { InnerPage } from 'views/pages'
import { Loader, Paginator } from 'views/components'
import { ACTIONS } from './config'
import styled from 'styled-components'
import { useTable } from 'react-table'

const enhance = compose(
  setDisplayName('views/routes/orders/Page'),
  dataLoad(dataLoader),
  connect(mapState, ACTIONS),
  mapProps(propsMapper),
  withHandlers({
    handleClickPage
  }),
  pure
)

const Styles = styled.div`
  padding: 1rem;

  table {
    border-spacing: 0;
    border: 1px solid black;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }
  }

  .pagination {
    padding: 0.5rem;
  }

 .form-control {
   display: unset;
 }

  button {
    margin-right: 5px;
  }

  input {
    margin-right: 5px;
  }
`

function Table({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  })

  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()}>{column.render('Header')}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row)
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

export default enhance(({
  headers,
  data,
  isLoaded,
  handleClickPage,
  page,
  pages
}) =>
  <InnerPage withSearch>
    <Loader message={<FormattedMessage id="Orders.Loading"/>} isLoaded={isLoaded}/>
    <Styles>
      <Table columns={headers} data={data} />
      <div className='notificationsPage_paginator'>
        <Paginator
          clickPage={handleClickPage}
          isWide={true}
          selected={page - 1}
          totalPages={pages}
        />
      </div>
    </Styles>
  </InnerPage>
)
