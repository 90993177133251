import { clearShoppingCart } from 'redux/modules/shoppingCart/actions'
import { createOrdersFromShoppingCart } from 'redux/modules/orders/actions'
import PropTypes from 'prop-types'

  export const ACTIONS = {
    clearShoppingCart,
    createOrdersFromShoppingCart
  }

  export const CONTEXT_TYPES = {
    router: PropTypes.object
  }
  