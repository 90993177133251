
import { typeGen } from 'helpers/redux'

const makeType = typeGen('user')

export const CHANGE_CUSTOMER_CARD = makeType('changeCustomerCard')
export const GET_CLIENT_PROFILE_DATA = makeType('getClientProfileData')
export const GET_USER_CARD_INFO = makeType('getUserCardInfo')
export const PREVIEW_USER_PROFILE_PICTURE = makeType('previewUserProfilePicture')
export const UPDATE_CLIENT_PROFILE = makeType('updateClientProfile')
export const UPDATE_USER_CARD = makeType('updateUserCard')
export const UPDATE_USER_PASSWORD = makeType('updateUserPassword')
export const UPDATE_USER_PROFILE_PICTURE = makeType('updateUserProfilePicture')
export const SAVE_USER_DEFAULT_SEARCH = makeType('saveUserDefaultSearch')
