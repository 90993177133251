
import { ASYNC_STATUSES } from 'data/constants'
import { omit, values } from 'lodash'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import React from 'react'

const statuses = omit(ASYNC_STATUSES, ['ONSUCCESS'])

export const FIELDS = [
  {
    label: <FormattedMessage id="Fields.FirstName"/>,
    name: 'firstName',
    placeholder: <FormattedMessage id="Fields.FirstName"/>
  },
  {
    label: <FormattedMessage id="Fields.LastName"/>,
    name: 'lastName',
    placeholder: <FormattedMessage id="Fields.LastName"/>
  },
  {
    label: <FormattedMessage id="Fields.Email"/>,
    name: 'email',
    placeholder: <FormattedMessage id="Fields.Email"/>,
    type: 'email'
  },
  {
    label: <FormattedMessage id="Fields.CountryPrefix"/>,
    name: 'countryPrefix',
    normalize: value => value.slice(0, 4),
    placeholder: <FormattedMessage id="Fields.CountryPrefix"/>
  },
  {
    label: <FormattedMessage id="Fields.PhoneNumber"/>,
    name: 'phoneNumber',
    placeholder: <FormattedMessage id="Fields.PhoneNumber"/>
  },
  {
    geoSuggest: true,
    label: <FormattedMessage id="Fields.HomeAddress"/>,
    name: 'address',
    placeholder: <FormattedMessage id="Fields.HomeAddress.Placeholder"/>
  }
]

export const PROP_TYPES = {
  avatar: PropTypes.string,
  disabled: PropTypes.bool,
  form: PropTypes.string.isRequired,
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  status: PropTypes.oneOf(values(statuses)),
  updateAvatar: PropTypes.func,
  updateCard: PropTypes.func
}
