
import React from 'react'
import { compose, pure, setDisplayName, setPropTypes } from 'recompose'
import { PROP_TYPES } from './config'

const enhance = compose(
  setDisplayName('views/components/Logo'),
  setPropTypes(PROP_TYPES),
  pure
)

export default enhance(({
  alt
}) =>
  <span className='logo-cnt'>
    <img
      alt={alt}
      className='logo'
      src={require('theme/images/eqdel_logo.png')}
    />
  </span>
)
