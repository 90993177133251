import React from 'react'
import { compose, pure, setDisplayName, setPropTypes, withHandlers } from 'recompose'
import { PROP_TYPES } from './config'
import { ReactMegaMenu } from "react-mega-menu"

const enhance = compose(
  setDisplayName('views/components/MultiLevelDropdown'),
  setPropTypes(PROP_TYPES),
  withHandlers({
    
  }),
  pure
)

export default enhance(({
    tolerance,
    direction,
    onExit,
    data,
}) =>
    <ReactMegaMenu 
        styleConfig={{
            menuProps: {
            style: {
                padding: "5px",
            }
            },
            contentProps: {
            style: {
                padding: "15px 20px 0px 0px"
            }
            },
            menuItemSelectedProps: {
            style: {
                padding: "2px",
                backgroundColor: "#D9DDDC"
            }
            },
        }}
        tolerance={tolerance}      // optional, defaults to 100
        direction={direction}  // optional, defaults to "RIGHT", takes in "RIGHT" || "LEFT"
        onExit={onExit}  // a function to be called when a mouse leaves the container
        data={data}        // array of data to be rendered
    />
)
