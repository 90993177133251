import { get} from 'lodash'
import moment from 'moment'

export default ({
  ...props
}) => {
  var address = get(props, 'products.location.label')
  var shoppingCartItems = get(props, 'shoppingCart.shoppingCardItems')
  var total = shoppingCartItems.reduce((total, item) => total + item.product.price, 0)
  var deliveryDate = get(shoppingCartItems[0], 'rentalStartDateUtc', '')
  var returnDate = get(shoppingCartItems[0], 'rentalEndDateUtc', '')
  return {
    deliveryAddress: address,
    isSameAddress: get(props, 'isSameAddress'),
    deliveryDate: (deliveryDate) ? moment(deliveryDate).format('DD/MM/YYYY') : '',
    returnDate: (returnDate) ? moment(returnDate).format('DD/MM/YYYY') : '',
    totalPrice: total,
    currency: localStorage.currency,
    ...props,
  }
}
