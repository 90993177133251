export default {
    "Forms.Search2.Placeholder": "Suche um mich herum",
    "InfoList.AboutUs": "Über uns",
    "InfoList.Faq": "Faq",
    "InfoList.TermsAndConditions": "Allgemeine Geschäftsbedingungen",
    "Lists.Menu.WithoutPath": "Ohne Weg",
    "Lists.Menu.InsidePath": "Innenweg",
    "Lists.Menu.OutsidePath": "Äußerer Pfad",
    "Lists.Menu.OpenPage": "Öffnet in einer neuen Seite",
    "UserMenu.ShopNow": "Jetzt einkaufen",
    "UserMenu.OrderHistory": "Bestellhistorie",
    "UserMenu.Profile": "Profil",
    "UserMenu.ResetPassword": "Passwort zurücksetzen",
    "UserMenu.Logout": "Ausloggen",
    "Header.WorkWithUs": "Arbeite mit uns",
    "Footer.GetInTouch": "In Kontakt kommen",
    "ProductsList.SkisAndBoards": "Ski & Boards",
    "ProductsList.Boots": "Stiefel",
    "ProductsList.Accessories": "Zubehör",
    "WorkFloatList.Search.Description": "Erkunde Skier bis zu 10 km um dich herum!",
    "WorkFloatList.Rent.Description": "Buchen Sie Ihre Lieblingsski zu den niedrigsten Preisen!",
    "WorkFloatList.Receive.Description": "Treffen Sie unseren Fahrer an Ihrem Lieblingsort und genießen Sie Ihre Skier!",
    "WorkFloatList.Search": "Suche",
    "WorkFloatList.Rent": "Miete",
    "WorkFloatList.Receive": "Erhalten",
    "App.Loading": "Laden der Anwendung ...",
    "FormErrors.IncorrectValue:": "Falscher Wert",
    "FormErrors.NewPassword": "Muss mit neuem Passwort übereinstimmen",
    "FormErrors.Required": "* Erforderlich",
    "FormErrors.SelectFromList": "Aus Liste auswählen",
    "FormErrors.SpecialCharacters": "Muss Sonderzeichen enthalten",
    "NotFound.Default": "Es wurden leider keine Ergebnisse gefunden, die Ihren Suchkriterien entsprechen",
    "PointItems.ChooseShopsAround.Description": "Möglichkeit, Skier und Produkte aus Dutzenden von Geschäften bis zu 10 km Entfernung zu entdecken und auszuwählen",
    "PointItems.ComparePriceAndProducts.Description": "Preis- und Produktvergleich zwischen verschiedenen Shops",
    "PointItems.NoMoreQueueing.Description": "Kein Anstehen mehr in überfüllten Läden dank On-Demand-Treibern, die Ski und Zubehör liefern, wann und wo die Skifahrer wollen!",
    "Fields.CardName.Placeholder": "Name des Karteninhabers",
    "PasswordChange.ErrorMessage": "Kennwortänderung fehlgeschlagen. Versuchen Sie es erneut",
    "PasswordChanged.SuccessMessage": "Passwort erfolgreich geändert",
    "HeadedForm.Buttons.ResetPassword": "Passwort zurücksetzen",
    "Fields.FirstName": "Vorname",
    "Fields.LastName": "Nachname",
    "Fields.Email": "Email",
    "Fields.CountryPrefix": "Länderpräfix",
    "Fields.PhoneNumber": "Telefonnummer",
    "Fields.HomeAddress": "Heimatadresse",
    "Fields.HomeAddress.Placeholder": "Ort eingeben",
    "UserProfile.Buttons.UpdateCard": "Karte Aktualisieren",
    "UserProfile.Header.UpdateCard": "Karte aktualisieren",
    "UserProfile.Buttons.SaveChanges": "Änderungen speichern",
    "UserProfile.Buttons.Cancel": "Abbrechen",
    "MainOffer.SkiHire.Description": "Geliefert, wo Sie brauchen",
    "MainOffer.SkiHire": "Skiverleih",
    "MainOffer.SkiHire.Label": "Buchen Sie Ihren Lieblingsski",
    "MainOffer.SkiHire.Label2": "Lieferservice",
    "Forms.Search.Placeholder": "Lieferadresse eingeben",
    "CartSummary.Cart": "Wagen:",
    "CartSummary.Total": "Gesamt:",
    "CartSummary.Buttons.ClearCart": "Warenkorb löschen",
    "ProductMeta.Category": "Category:",
    "ProductMeta.DeliverTo": "Liefern an",
    "ProductMeta.Date": "Datum:",
    "Fields.CustomerName.Placeholder": "Kundenname",
    "Forms.Delivery.DeliveryTime.Label": "Lieferzeit",
    "Forms.Delivery.DeliveryTime.Placeholder": "Wählen Sie eine Lieferzeit",
    "Forms.Delivery.ReturnAddress.Label": "Absenderadresse",
    "Forms.Delivery.ReturnAddress.Placeholder": "Rücksprungadresse eingeben",
    "Forms.Delivery.ReturnTime.Label": "Rückgabezeit",
    "Forms.Delivery.ReturnTime.Placeholder": "Wählen Sie eine Rückgabezeit",
    "SearchExtended.GeoSuggestInputIconed.Label": "Lieferadresse",
    "SearchExtended.SelectIconed.Label": "Auf der Suche nach?",
    "Components.LookingIn": "Sie suchen in",
    "ProductCart.Content.Edit": "ebearbeiten",
    "ProductCart.Content.ProductSettings": "Produkteinstellungen",
    "ProductCart.Content.Quantity": "Menge",
    "ProductPage.Content.For": "Zum:",
    "ProductPage.Content.Level": "Niveau:",
    "ProductPage.Content.Shop": "Geschäft:",
    "ProductPage.Content.Description": "Beschreibung:",
    "Components.Sorter": "Sortieren nach",
    "WorkFloatList.HowItWorks": "So funktioniert es",
    "ProductsPage.Content.OurProducts": "Unsere Produkte",
    "HeadedForm.Fields.OldPassword.Placeholder": "Altes Passwort",
    "HeadedForm.Fields.NewPassword.Placeholder": "Neues Passwort",
    "HeadedForm.Fields.ConfirmPassword.Placeholder": "Passwort bestätigen",
    "Components.Blocks.InnerBanner.Title": "Bannertitel",
    "Components.Blocks.ProductMeta.DeliveryAddress.Label": "Straße, Haus, Stadt, Staat",
    "Components.Blocks.ProductInCard.Title": "Produkttitel",
    "Components.Blocks.ProductSec.Name": "Produktname",
    "Components.Blocks.Rollet.Title": "Rollet",
    "Components.Blocks.Scrolled.Title": "Gescrollt",
    "AboutPage.Content.Decription": "Deliquity ist eine innovative Plattform, mit der Skifahrer innerhalb von 10 km Skier und Zubehör in Geschäften erkunden und ausleihen können und diese auf Abruf von Fahrern zu einem bestimmten Zeitpunkt am Lieblingsort des Skifahrers ausliefern lassen können",
    "AboutPage.Content.Invite": "Melden Sie sich noch heute bei deliverki an oder zögern Sie nicht, uns unter zu kontaktieren",
    "AboutPage.Content.JoinUs": "Begleiten Sie uns",
    "LogOutPage.Content.Process": "Ausloggen in Bearbeitung ...",
    "AuthPage.Content.Process": "Autorisierung in Bearbeitung ...",
    "CardPage.Content.ExtraProduct": "+ Zusätzliches Produkt hinzufügen für",
    "CardPage.Content.Rollet.Title": "Liefer und Rückgabezeit",
    "CardPage.Content.Buttons.ContinueShopping": "Weiter einkaufen",
    "CardPage.Content.Buttons.Checkout": "Auschecken!",
    "ProductPage.Content.Breadcrumbs.Label": "Zurück",
    "NotificationPage.Content.Name": "Benachrichtigungen",
    "NotificationPage.Content.NotFound": "Keine Benachrichtigung gefunden",
    "OrdersPage.Content.Name": "Bestellungen Seite",
    "ProductPage.Content.NotFound": "Dieses Produkt hat noch keine Bilder",
    "ProductPage.Content.Modal.OrderTotal": "Gesamtbetrag der Bestellung:",
    "ProductPage.Content.Modal.AddProducts": "Möchten Sie eines der folgenden Produkte hinzufügen?",
    "ProductPage.Content.Modal.Buttons.Checkout": "NEIN, bring mich zur Kasse",
    "Search.Products.MoreResult": "OH ... WIR HABEN MEHR ERGEBNISSE",
    "TermsPage.Title": "Willkommen bei Deliquity!",
    "TermsPage.Header": "Übernehmen Sie den Nutzungsbedingungenstext aus der Datenbank.",
    "FormErrors.MinLenght": "Passwort muss 8 Zeichen oder mehr haben",
    "UserPage.Content.SuccessUpdate": "Persönliche Daten erfolgreich aktualisiert",
    "UserPage.Content.Title": "Persönliche Informationen",
    "UserMenu.Buttons.Label.Login": "Anmeldung",
    "Footer.AllRightsReserved": "Alle Rechte vorbehalten 2017",
    "Products.Product.Description": "Produktbeschreibung",
    "Products.Buttons.Delete": "löschen",
    "Products.Buttons.Location": "Ort",
    "Products.Result": "Ergebnis",
    "Products.NotDefined": "Nicht definiert",
    "Select.None":"Keiner",
    "Select.LowerPrice":"Niedrigerer Preis",
    "Select.HigherPrice":"Höherer Preis",
    "Products.Product.Price": "Preis :",
    "Products.Product.Book": "Buchen Sie jetzt",
    "SearchExtended.Buttons.Search": "Suche",
    "SearchExtended.Label.To": "Zu",
    "Customer.Button.Confirm": "Kundendaten bestätigen",
    "Customer.Name.Placeholder":"Kundenname",
    "CheckoutPage.Buttons.PlaceOrder": "Bestellung Aufgeben",
    "CheckoutPage.Rollet.Title": "Bestelldetails",
    "Forms.Delivery.DeliveryAddress.Label": "Lieferadresse",
    "Forms.Delivery.DeliveryAddress.Placeholder": "Lieferadresse einstellen",
    "Forms.Delivery.SameAddress.Title": "Verwenden Sie für Lieferung und Rücksendung dieselbe Adresse",
    "Forms.Delivery.DeliveryDate": "Liefertermin",
    "Forms.Delivery.ReturnDate": "Rückflugdatum",
    "CheckoutPage.TotalPrice":"Gesamtpreis",
    "Forms.Delivery.CouponCode.Label": "Gutscheincode",
    "Forms.Delivery.CouponCode.Placeholder": "Gutschein-Code eingeben",
    "OrderPage.ItemsInOrder": "Artikel in der Reihenfolge",
    "OrderPage.PaymentStatus": "Zahlungsstatus",
    "OrderPage.DeliveryAddress": "Lieferadresse",
    "OrderPage.ReturnAddress": "Absender",
    "OrderPage.Total": "Gesamtpreis",
    "Product.Loading": "Produktdetails werden geladen ...",
    "ShoppingCart.Loading": "Warenkorbartikel werden geladen ...",
    "Order.Loading": "Ordnung schafft ...",
    "Components.Table.Page": "Seite",
    "Components.Table.Of": "von",
    "Components.Table.GoToPage": "Zur Seite gehen",
    "Components.Table.Show": "Show",
    "Orders.Loading":"Bestellungen werden geladen...",
    "OrderStatus.Pending": "Steht aus",
    "OrderStatus.Canceled": "Abgesagt",
    "OrderStatus.Active": "Aktiv",
    "OrderStatus.Completed": "Abgeschlossen",
    "OrderStatus.NotStarted": "Nicht angefangen",
    "OrderStatus.Blocked": "Verstopft",
    "OrderStatus.UnBlocked": "Entsperrt",
    "OrderStatus.CancelledByUser": "Vom Benutzer storniert",
    "OrderStatus.CancelledByMerchant": "Vom Händler storniert",
    "OrderStatus.CancelledByAdmin": "Abgebrochen von Admin",
    "OrderStatus.Delayed": "Verspätet",
    "OrderStatus.Returned": "Ist zurückgekommen",
    "OrderStatus.SelfReturn": "Selbstrückkehr",
    "OrderStatus.SelfReturnedInProgress": "Selbstrückkehr in Bearbeitung",
    "OrderStatus.ReturnInProgress": "Rückkehr in Bearbeitung",
    "OrderStatus.AwaitingForReturnPickUp": "Warten auf Abholung",
    "OrderStatus.AwaitingForReturnAcceptance": "Warten auf Rücknahme",
    "OrderStatus.Delivered": "Geliefert",
    "OrderStatus.AwaitingForPickUp": "Warten auf Abholung",
    "OrderStatus.AwaitingForAcceptance": "Warten auf Akzeptanz",
    "OrderStatus.DeliveryInProgress": "Lieferung läuft",
    "OrderDeliveryType.OnlyDelivery": "Nur Lieferung (Selbstrückgabe)",
    "OrderDeliveryType.OnlyReturn": "Nur zurückgeben (wegnehmen)",
    "OrderDeliveryType.DeliveryAndReturn": "Lieferung & Rückgabe",
    "OrderDeliveryType.None": "Wegbringen & Selbstrückkehr",
    "JobStatus.Accepted":"Akzeptiert",
    "JobStatus.NotAssigned":"Nicht zugeordnet",
    "JobStatus.Assigned":"Zugewiesen",
    "JobStatus.Completed":"Abgeschlossen",
    "JobStatus.Cancelled":"Abgesagt",
    "JobStatus.Collected":"Gesammelt",
    "JobStatus.TakeAway":"Wegbringen",
    "JobStatus.SelfReturn":"Selbstrückkehr",
    "JobStatus.Emergency":"Notfall",
    "JobStatus.Blocked":"Verstopft",
    "JobStatus.Unblocked":"Entsperrt",
    "OrderPage.DeliveryDate": "Liefertermin",
    "OrderPage.ReturnDate":"Rückflugdatum",
    "OrderPage.JobStatus":"Beruflicher Status",
    "OrderPage.DropAddress":"Adresse ablegen",
    "OrderPage.PickUpAddress":"Abholadresse",
    "OrderDetails.Loading":"Bestelldaten werden geladen...",
    "OrderPage.OrderCreateSuccess":"Bestellung ist erfolgreich erstellt!",
    "OrderPage.Merchant.FullName":"Vollständiger Name",
    "OrderPage.Merchant.Email":"Email",
    "OrderPage.Merchant.PhoneNumber":"Telefonnummer",
    "OrderPage.Merchant.ShopName":"Laden Name",
    "OrderPage.Merchant.ShopAddress":"Geschäftsadresse",
    "ProductsPage.Modal.Buttons.ShoppingCart": "Einkaufswagen",
    "ProductsPage.Modal.Title":"Produkt in den Warenkorb legen",
    "ProductsPage.Modal.ProductName":"Produktname:",
    "ProductsPage.Modal.Quantity":"Menge:",
    "ProductsPage.Modal.RelatedProducts":"Verwandte Produkte:",
    "LandingPage.LocationModal.Title": "Bitte Standort wählen:",
    "LandingPage.LocationModal.ButtonSave": "Speichern",
    "LandingPage.LocationModal.DateTitle": "Wählen Sie Daten aus",
    "ProductsPage.Radius.Label": "Wählen Sie den Radius der Händlerentfernung in km",
}