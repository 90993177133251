
import { methodFromProps } from 'helpers'

export default props => () => {
  const onClose = methodFromProps('onClose', props)
  const open = methodFromProps('open', props)
  if (props.opened) {
    open(false)
    onClose()
  }
}
