
import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { compose, pure, setDisplayName } from 'recompose'
import Page from './Page'

const enhance = compose(
  setDisplayName('views/routes/product/Router'),
  pure
)

export default enhance(() =>
  <Switch>
    <Redirect
      exact
      from='/product'
      to='/search/products'
    />
    <Route
      component={Page}
      path='/product/:product'
    />
  </Switch>
)
