
import { ASYNC_STATUSES } from 'data/constants'

export default ({
  status,
  ...props
}) => ({
  ...props,
  isPending: status === ASYNC_STATUSES.PENDING
})
