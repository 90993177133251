
import { get, pick } from 'lodash'

export default obj => ({
  borderRadius: 0,
  ...pick(obj, [
    'checked',
    'label',
    'options',
    'placeholder'
  ]),
  name: get(obj, 'name', '')
})
