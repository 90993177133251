
import React from 'react'
import onClickOutside from 'react-onclickoutside'
import { compose, pure, setDisplayName, setPropTypes, withHandlers } from 'recompose'
import { PROP_TYPES } from './config'
import { handleClickOutside } from './lib'

const enhance = compose(
  setDisplayName('views/components/Dropdown/Child'),
  setPropTypes(PROP_TYPES),
  withHandlers({
    handleClickOutside
  }),
  onClickOutside,
  pure
)

export default enhance(({
  children,
  onClick,
  style
}) =>
  <div className='dropdown_children'>
    <div
      className='dropdown_children_cnt'
      onClick={onClick}
      style={style}
    >
      {children}
    </div>
  </div>
)
