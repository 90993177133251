
import { ASYNC_STATUSES } from 'data/constants'
import { get, omit, pick } from 'lodash'

export default (state, action) => {
  const { status, type } = action.payload
  const currentError = get(state, `errors.${type}`)
  const newState = {
    ...state,
    statuses: {
      ...state.statuses,
      [type]: status
    }
  }
  if (status === ASYNC_STATUSES.ERROR && action.meta) {
    newState.errors = {
      ...newState.errors,
      [type]: { ...pick(action.meta, ['message']) }
    }
  }
  if (status === ASYNC_STATUSES.SUCCESS && currentError) {
    newState.errors = { ...omit(newState.errors, type) }
  }
  return newState
}
