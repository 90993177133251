
import { success } from 'helpers/redux/actionStateTypes'
import { get } from 'lodash'
import { handleActions } from 'redux-actions'
import * as cx from './constants'

const initialState = {
  avatar: null,
  card: null,
  data: null
}

export default handleActions({
  [cx.PREVIEW_USER_PROFILE_PICTURE]: (state, action) => ({
    ...state,
    avatar: get(action, 'payload', initialState.avatar)
  }),
  [success(cx.GET_CLIENT_PROFILE_DATA)]: (state, action) => ({
    ...state,
    data: get(action, 'payload.clientProfileData', initialState.data)
  }),
  [success(cx.GET_USER_CARD_INFO)]: (state, action) => ({
    ...state,
    card: get(action, 'payload', initialState.card)
  })
}, initialState)
