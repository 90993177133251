
import React from 'react'
import { Icon } from 'react-icons-kit'
import { bell } from 'react-icons-kit/fa/bell'
import { compose, mapProps, pure, setDisplayName, setPropTypes } from 'recompose'
import { propsMapper } from './lib'
import { PROP_TYPES } from './config'

const enhance = compose(
  setDisplayName('views/components/Card'),
  setPropTypes(PROP_TYPES),
  mapProps(propsMapper),
  pure
)

export default enhance(({
  message,
  title
}) =>
  <div className='card notification'>
    <div className='card-header'>
      <Icon
        className='notification_icon'
        icon={bell}
        size={20}
      />
      {title}
    </div>
    <div className='card-body'>
      {message}
    </div>
  </div>
)
