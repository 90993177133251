
import { Input } from 'views/components/forms'
import React from 'react'
import { Icon } from 'react-icons-kit'
import { androidSearch } from 'react-icons-kit/ionicons/androidSearch'
import { Field, reduxForm } from 'redux-form'
import { compose, pure, setDisplayName, setPropTypes } from 'recompose'
import { PROP_TYPES } from './config'

const enhance = compose(
  setDisplayName('views/components/forms/Search3'),
  setPropTypes(PROP_TYPES),
  reduxForm({
    form: 'views/component/forms/Search3',
  }),
  pure
)

export default enhance(({
  handleSubmit,
  onSubmit
}) =>
  <form
    className='f_search3'
    onSubmit={handleSubmit(onSubmit)}
  >
    <div className='f_search3_input'>
      <Field
        small
        borderRadius={0}
        component={Input}
        name='searchTerm'
        style={{
          border: '0 none',
          borderBottom: '1px dashed #000'
        }}
      />
    </div>
    <button
      className='f_search3_button'
      type='submit'
    >
      <Icon
        icon={androidSearch}
        size={30}
      />
    </button>
  </form>
)
