
import { Rollet } from 'views/components'
import React from 'react'
import { compose, mapProps, pure, setDisplayName, withHandlers } from 'recompose'
import uniqid from 'uniqid'
import { connect } from 'react-redux'
import { propsMapper, mapState, dataLoader } from './lib'
import { ACTIONS } from './config'
import { Delivery } from 'views/components/forms'
import { FormattedMessage } from 'react-intl'
import { Page } from 'views/pages'
import { dataLoad } from 'helpers/redux'

const enhance = compose(
  setDisplayName('views/routes/checkout/Page'),
  dataLoad(dataLoader),
  connect(mapState, ACTIONS),
  mapProps(propsMapper),
  withHandlers({
  }),
  pure
)

export default enhance(({
  shoppingCartItems,
  props
}) =>
  <Page
    className='cartPage'
  >
    <section className='cartPage_cnt container'>
        <Rollet
            opened
            backgroundColor='#F1F4ED'
            className='cartPage_delivery'
            title={<FormattedMessage id="CheckoutPage.Rollet.Title"/>}
            >
            <div className='cartPage_delivery_cnt'>
                <Delivery
                    form={`routes/cart/Page_form--${uniqid()}`}
                    shoppingCartItems={shoppingCartItems}
                />         
            </div>
        </Rollet>
    </section>
  </Page>
)
