
import { pending } from 'redux/modules/asyncState/actions'
import { changeCustomerCard, previewUserProfilePicture, updateClientProfile, updateUserProfilePicture } from 'redux/modules/user/actions'

export const ACTIONS = {
  changeCustomerCard,
  pending,
  previewUserProfilePicture,
  updateClientProfile,
  updateUserProfilePicture
}
