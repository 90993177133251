import { methodFromProps } from 'helpers'
import { get } from 'lodash'

export default props => values => {
  values.preventDefault()
  const addShoppingCartItemDetails = methodFromProps('addShoppingCartItemDetails', props)
  const products = get(props, 'products', [])
  const customerName = values.target['names'].value

  const form = new FormData()
  form.set('productId', products[0].id)
  form.set('customerName', customerName)

  addShoppingCartItemDetails(form)
  setTimeout(()=>{
    window.location.href = '/cart'
}, 2000)
}