
import { fromToDatesDuration } from 'helpers/dateTime'
import { get } from 'lodash'
import moment from 'moment'
import { FormattedMessage } from 'react-intl'
import React from 'react'

export default ({
  categoryIdx,
  date,
  ...props
}) => {
  const chosenCategory = get(props, `categories.[${categoryIdx}]`)
  const dateFrom = get(date, 'from')
  const dateTo = get(date, 'to')
  const noDate = get(date, 'noDate')

  return {
    ...props,
    category: get(chosenCategory, 'label', <FormattedMessage id="Products.NotDefined"/>),
    dateString: (dateFrom && dateTo) ? fromToDatesDuration(date) : noDate,
    initialFormValues: {
      address: props.deliveryAddress,
      from: dateFrom ? moment(dateFrom) : null,
      lookingFor: chosenCategory,
      to: dateTo ? moment(dateTo) : null
    }
  }
}
