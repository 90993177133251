
import classnames from 'classnames'
import React from 'react'
import { compose, defaultProps, pure, setDisplayName, setPropTypes, withHandlers } from 'recompose'
import { DEFAULT_PROPS, PROP_TYPES } from './config'
import { handleCurtainClick } from './lib'

const enhance = compose(
  setDisplayName('views/components/Overlay'),
  setPropTypes(PROP_TYPES),
  defaultProps(DEFAULT_PROPS),
  withHandlers({
    handleCurtainClick
  }),
  pure
)

export default enhance(({
  children,
  handleCurtainClick,
  fullWidth,
  transparent,
  visible
}) =>
  <article className='overlay'>
    {visible ? (
      <div className={classnames({
        // eslint-disable-next-line
        ['overlay--full_width']: fullWidth
      })}>
        <div className={classnames('overlay_children', {
          // eslint-disable-next-line
          ['overlay_children--full_width']: fullWidth
        })}>
          {children}
        </div>
        <span
          className='overlay_curtain'
          onClick={handleCurtainClick}
          style={{
            background: transparent ? 'none' : '#000'
          }}
        />
      </div>
    ) : null}
  </article>
)
