
import React from 'react'
import { Icon } from 'react-icons-kit'
import { shoppingCart } from 'react-icons-kit/typicons/shoppingCart'
import { compose, defaultProps, pure, setDisplayName, setPropTypes } from 'recompose'
import { DEFAULT_PROPS, PROP_TYPES } from './config'

const enhance = compose(
  setDisplayName('views/components/Basket'),
  setPropTypes(PROP_TYPES),
  defaultProps(DEFAULT_PROPS),
  pure
)

export default enhance(({
  quantity
}) =>
  <span className='basket'>
    <span className='basket_icon'>
      <Icon
        icon={shoppingCart}
        size={25}
      />
    </span>
    <span className='basket_quantity'>
      {quantity}
    </span>
  </span>
)
