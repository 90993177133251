
import React from 'react'
import { compose, defaultProps, pure, setDisplayName, setPropTypes } from 'recompose'
import { DEFAULT_PROPS, PROP_TYPES } from './config'

const enhance = compose(
  setDisplayName('views/components/texts/HdTitle2'),
  setPropTypes(PROP_TYPES),
  defaultProps(DEFAULT_PROPS),
  pure
)

export default enhance(({
  align,
  children
}) =>
  <h1
    className='hd-title2'
    style={{ textAlign: align }}
  >
    {children}
  </h1>
)
