
import PropTypes from 'prop-types'

export const PROP_TYPES = {
  limits: PropTypes.shape({
    bottom: PropTypes.number,
    top: PropTypes.number
  }),
  onChange: PropTypes.func,
  units: PropTypes.string,
  values: PropTypes.shape({
    bottom: PropTypes.number,
    top: PropTypes.number
  })
}
