
import { isAuthorized } from 'helpers/auth'
import { authOnly } from 'helpers/router'
import { get } from 'lodash'

export default props => ({
  ...props,
  authOnly: authOnly({
    authorized: isAuthorized(props.auth)
  }),
  currencies: get(props, 'currencies.currencies')
})
