
import { pluralFromNumber } from 'helpers/text'

export default ({
  results,
  ...props
}) => ({
  ...props,
  resultString: results + ' ' + pluralFromNumber({
    number: results,
    word: 'Result'
  })
})
