
import React from 'react'
import { compose, pure, setDisplayName, setPropTypes } from 'recompose'
import { PROP_TYPES } from './config'

const enhance = compose(
  setDisplayName('views/components/texts/MoreResults'),
  setPropTypes(PROP_TYPES),
  pure
)

export default enhance(({
  onClick,
  text
}) =>
  <div className='moreResults'>
    <div
      className='moreResults_text'
      onClick={onClick}
    >
      {text}
    </div>
  </div>
)
