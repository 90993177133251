
import { Input } from 'views/components/forms'
import React from 'react'
import { Icon } from 'react-icons-kit'
import { androidSearch } from 'react-icons-kit/ionicons/androidSearch'
import { compose, pure, setDisplayName, setPropTypes } from 'recompose'
import { PROP_TYPES } from './config'

const enhance = compose(
  setDisplayName('views/components/forms/Search2'),
  setPropTypes(PROP_TYPES),
  pure
)

export default enhance(({
  placeholder
}) =>
  <form className='f-search2'>
    <Input
      inputClassName='f-search2_input'
      placeholder={placeholder}
    />
    <button className='f-search2_btn'>
      <Icon
        icon={androidSearch}
        size={25}
        style={{ color: '#fff' }}
      />
    </button>
  </form>
)
