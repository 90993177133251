
import classnames from 'classnames'
import React from 'react'
import { compose, defaultProps, pure, setDisplayName, setPropTypes } from 'recompose'
import { DEFAULT_PROPS, PROP_TYPES } from './config'

const enhance = compose(
  defaultProps(DEFAULT_PROPS),
  setDisplayName('views/components/MainOffer'),
  setPropTypes(PROP_TYPES),
  pure
)

export default enhance(({
  description,
  offer,
  options
}) =>
  <div className='main-offer'>
    <h1 className='hd-title main-offer__heading'>
      {offer}
    </h1>
    <p className='main-offer__description'>
      {description}
    </p>
    {options.length
      ? <ul className='main-offer__options'>
        {options.map((option, key) =>
          <li
            className={classnames({
              'main-offer__options__emph': option.emphasized,
              'main-offer__options__marked': key
            })}
            key={key}
          >
            {option.label}
          </li>
        )}
      </ul>
      : null}
  </div>
)
