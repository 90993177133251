
import { PROP_TYPES as SELECT_PROP_TYPES } from 'views/components/forms/Select/config'
import { pick } from 'lodash'

const selectProps = [
  'onChange',
  'options',
  'value'
]

export const PROP_TYPES = {
  ...pick(SELECT_PROP_TYPES, selectProps)
}
