import { handleActions } from 'redux-actions'
import { success, error } from 'helpers/redux/actionStateTypes'
import * as cx from './constants'
import { get } from 'lodash'

const initialState = {
  currencies: [],
  currencyRate: {},
  error: {}
}

export default handleActions({
  [success(cx.GET_AVAIBLE_CURRENCIES)]: (state, action) => ({
    ...state,
    currencies: get(action, 'payload', [])
  }),
  [error(cx.GET_AVAIBLE_CURRENCIES)]: (state, action) => ({
    ...state,
    error: get(action, 'payload', [])
  }),
  [success(cx.GET_CURRENT_RATE)]: (state, action) => ({
    ...state,
    currencyRate: get(action, 'payload', [])
  }),
  [error(cx.GET_CURRENT_RATE)]: (state, action) => ({
    ...state,
    error: get(action, 'payload', [])
  })
}, initialState)