
import { typeGen } from 'helpers/redux'

const makeType = typeGen('categoryAttributes')

export const ALL_CATEGORY_ATTRIBUTES_BY_CATEGORY = makeType('allCategoryAttributesByCategory')

export const CLEAR_ATTRIBUTES_BY_CATEGORY = makeType('clearAttributesByCategory')

export const CLEAR_ATTRIBUTE_VALUES = makeType('clearAttributeValues')

export const UPDATE_CATEGORY_ATTRIBUTE_VALUE = makeType('updateCategoryAttributeValue')

export const GET_ALL_ATTRIBUTES_BY_CATEGORIES = makeType('getAllAttributesByCategories')
