
import { ButtonSharp } from 'views/components/buttons'
import { PointsList } from 'views/components/lists'
import { POINT_ITEMS } from 'data/constants'
import { Page } from 'views/pages'
import React from 'react'
import { compose, pure, setDisplayName } from 'recompose'
import { FormattedMessage } from 'react-intl'

const enhance = compose(
  setDisplayName('views/routes/about/Page'),
  pure
)

export default enhance(() =>
  <Page>
    <section className='about'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h1 className='hd-cap'>
              <FormattedMessage id="InfoList.AboutUs"/>
            </h1>
            <p className='about_desc p-description'>
              <FormattedMessage id="AboutPage.Content.Decription"/>
            </p>
            <PointsList items={POINT_ITEMS} />
            <p className='about_invite'>
              <FormattedMessage id="AboutPage.Content.Invite"/> <a href='mailto:info@deliquity.com'>info@deliquity.com</a>.
            </p>
            <div className='about_cta'>
              <ButtonSharp hoverStyle={{ color: '#fff' }}>
                <FormattedMessage id="AboutPage.Content.JoinUs"/>
              </ButtonSharp>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Page>
)
