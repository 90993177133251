
import { CheckList, InfoList, ListSocial, Menu, PlusList, PointsList, ProductsList, ResponsiveList, WorkFloatList } from 'views/components/lists'
import { POINT_ITEMS } from 'data/constants'
import faker from 'faker'
import { random, range } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { compose, pure, setDisplayName, setPropTypes } from 'recompose'

const enhance = compose(
  setDisplayName('views/routes/_lib/components/Lists'),
  setPropTypes({
    title: PropTypes.string
  }),
  pure
)

export default enhance(({
  title
}) =>
  <div>
    <h2 style={{ marginTop: 20 }}>
      {title}
    </h2>

    <p>
      components/lists/CheckList
    </p>
    <CheckList
      items={range(1, random(3, 7)).map(key => ({
        checked: faker.random.boolean(),
        icon: {
          alt: 'Beginner',
          src: require('theme/images/beginner.svg')
        },
        label: `Item ${key}`,
        name: `item_${key}`,
        toggleCheck: data => { console.log(data) }
      }))}
    />
    <span>
      With colors:
    </span>
    <CheckList
      items={range(1, random(3, 7)).map(key => ({
        checked: faker.random.boolean(),
        colorDot: '#0f0',
        label: `Item ${key}`,
        name: `item_${key}`,
        toggleCheck: data => { console.log(data) }
      }))}
    />

    <p>
      components/lists/InfoList
    </p>
    <InfoList
      items={[
        {
          href: '/about',
          label: 'About us',
          targetBlank: true
        },
        {
          href: '/faq',
          label: 'Faq',
          targetBlank: true
        },
        {
          href: '/terms',
          label: 'Terms & Conditions',
          targetBlank: true
        }
      ]}
    />

    <p>
      components/lists/ListSocial
    </p>
    <ListSocial
      items={[
        {
          href: 'https://www.facebook.com/',
          icon: require('react-icons-kit/ionicons/socialFacebook').socialFacebook,
          targetBlank: true
        },
        {
          href: 'https://twitter.com/',
          icon: require('react-icons-kit/ionicons/socialTwitter').socialTwitter,
          targetBlank: true
        },
        {
          href: 'https://instagram.com/',
          icon: require('react-icons-kit/fa/instagram').instagram,
          targetBlank: true
        },
        {
          href: 'https://www.pinterest.com/',
          icon: require('react-icons-kit/fa/pinterestP').pinterestP,
          targetBlank: true
        }
      ]}
    />

    <p>
      components/lists/Menu
    </p>
    <Menu
      items={[
        { label: 'Without path' },
        {
          chosen: true,
          label: 'Inside path',
          path: '/_lib'
        },
        {
          label: 'Outside path',
          path: 'https://google.com'
        },
        {
          label: 'Opens in a new page',
          path: '/',
          target: '_blank'
        }
      ]}
    />

    <p>
      components/lists/PlusList
    </p>
    <PlusList
      clickItem={(item, i) => { console.log(item, i) }}
      items={[
        { chosen: true, label: 'Gloves (+$39)' },
        { label: 'Sunglasses' },
        { label: 'Ropes' },
        { label: 'Boots' }
      ]}
    />

    <p>
      components/lists/PointsList
    </p>
    <PointsList items={POINT_ITEMS} />

    <p>
      components/lists/ProductsList
    </p>
    <ProductsList
      items={[
        {
          image: {
            alt: 'Skis & boards',
            src: require('theme/images/product-1.jpg')
          },
          name: 'Skis & boards'
        },
        {
          image: {
            alt: 'Boots',
            src: require('theme/images/product-2.jpg')
          },
          name: 'Boots'
        },
        {
          image: {
            alt: 'Accessories',
            src: require('theme/images/product-3.jpg')
          },
          name: 'Accessories'
        }
      ]}
    />

    <p>
      components/lists/ResponsiveList
    </p>
    <ResponsiveList
      items={[
        <div>
          Div A
        </div>,
        <div>
          Div B
        </div>,
        <div>
          Div C
        </div>,
        <div>
          Div D
        </div>,
        <div>
          Div E
        </div>
      ]}
      itemsInRow={3}
    />

    <p>
      components/lists/WorkFloatList
    </p>
    <WorkFloatList
      items={[
        {
          description: 'Explore skis up to 10km around you!',
          heading: 'Search',
          image: {
            alt: 'Search',
            path: require('theme/images/search.png')
          }
        },
        {
          description: 'Book your favorite skis at the lowest prices!',
          heading: 'Rent',
          image: {
            alt: 'Rent',
            path: require('theme/images/shopping-cart.png')
          }
        },
        {
          description: 'Meet our driver at your favorite location and enjoy your skis!',
          heading: 'Receive',
          image: {
            alt: 'Receive',
            path: require('theme/images/skiing.png')
          }
        }
      ]}
    />
  </div>
)
