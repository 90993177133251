import { get } from 'lodash'
import { useToasts } from 'react-toast-notifications'


export default ({
  shoppingCart,
  ...props
}) => {
  const { addToast } = useToasts();
  const error = get(props, 'orders.error', [])
  if (error !== undefined && error.message !== undefined) {
    addToast(error.message, { appearance: 'error', autoDismiss: true })
  }

  const orderIds = get(props, 'orders.orderIds.data', [])
  if (typeof orderIds !== undefined && orderIds.length > 0) {
      var push = get(props, 'history.push')
      push('/order')
  }

  return {
    shoppingCartItems: get(props, 'shoppingCart.shoppingCardItems',{}),
  ...props
  }
}
