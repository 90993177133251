
import { get } from 'lodash'
/**
 * @param {Array} categoryAttrs
 * @param {Array} categoryAttrs[].attributes
 * @param {String} categoryAttrs[].attributes[].attributeName
 * @param {String} categoryAttrs[].attributes[].attributeValue

 * @param {Array} filters
 * @param {String} filters[].name
 * @param {String} filters[].value
*/
export default (categoryAttrs, filters) => (filters instanceof Array)
  ? categoryAttrs.filter(ca => !filters
    .find(filter => !get(ca, 'attributes', [])
      .find(caa => caa.attributeName === filter.name
        && caa.attributeValue === filter.value
      )
    )
  ) : categoryAttrs || []
