import { FormattedMessage } from 'react-intl'
import React from 'react'

export default status => {
    switch (status) {
        case 0: return <FormattedMessage id='OrderStatus.Pending' />
        case 1: return <FormattedMessage id='OrderStatus.Canceled' />
        case 2: return <FormattedMessage id='OrderStatus.Active' />
        case 3: return <FormattedMessage id='OrderStatus.Completed' />
        case 4: return <FormattedMessage id='OrderStatus.NotStarted' />
        case 5: return <FormattedMessage id='OrderStatus.Blocked' />
        case 6: return <FormattedMessage id='OrderStatus.UnBlocked' />
        case 7: return <FormattedMessage id='OrderStatus.CancelledByUser' />
        case 8: return <FormattedMessage id='OrderStatus.CancelledByMerchant' />
        case 9: return <FormattedMessage id='OrderStatus.CancelledByAdmin' />
        case 10: return <FormattedMessage id='OrderStatus.Delayed' />
        case 11: return <FormattedMessage id='OrderStatus.Returned' />
        case 12: return <FormattedMessage id='OrderStatus.SelfReturn' />
        case 13: return <FormattedMessage id='OrderStatus.SelfReturnedInProgress' />
        case 14: return <FormattedMessage id='OrderStatus.ReturnInProgress' />
        case 15: return <FormattedMessage id='OrderStatus.AwaitingForReturnPickUp' />
        case 16: return <FormattedMessage id='OrderStatus.AwaitingForReturnAcceptance' />
        case 17: return <FormattedMessage id='OrderStatus.Delivered' />
        case 18: return <FormattedMessage id='OrderStatus.AwaitingForPickUp' />
        case 19: return <FormattedMessage id='OrderStatus.AwaitingForAcceptance' />
        case 20: return <FormattedMessage id='OrderStatus.DeliveryInProgress' />
        default: return ''
      }
}