
import { get } from 'lodash'

export default sizes => {
  const res = {}
  const width = get(sizes, 'width')
  if (width) {
    res.isSmall = width < 480
  }
  return res
}
