
import faker from 'faker'
import { Factory } from 'rosie'

export default Factory.define('AddressGoogle')
  .option('place', faker.lorem.words)
  .attr('description', ['place'], place => place)
  .attr('gmaps', () => ({}))
  .attr('isFixture', faker.random.boolean)
  .attr('label', ['place'], place => place)
  .attr('location', () => ({}))
  .attr('matchedSubstrings', () => ({}))
  .attr('placeId', faker.lorem.word)
