import React from 'react'
import { compose, pure, setDisplayName } from 'recompose'
import { changeMultilanguageClick } from './lib'
import { Dropdown, DropdownToggler, ImageRound } from 'views/components'
import { Menu } from 'views/components/lists'
import { If, Then } from 'react-if'

const enhance = compose(
  setDisplayName('views/components/Multilanguage'),
  pure
)

export default enhance(({
  languages
}) =>
    <Dropdown
      childrenPosition={{ right: 0 }}
      toggler=
        {
          <DropdownToggler>
            <If condition={localStorage.lang === 'en-us' || localStorage.lang === undefined || localStorage.lang === ''}>
              <Then>
                <ImageRound
                  size={46}
                  src={require('theme/images/united-kingdom.png')}
                />
              </Then>
            </If>
            <If condition={localStorage.lang === 'de-de'}>
              <Then>
                <ImageRound
                  size={46}
                  src={require('theme/images/germany.png')}
                />
              </Then>
            </If>
            <If condition={localStorage.lang === 'fr-fr'}>
              <Then>
                <ImageRound
                  size={46}
                  src={require('theme/images/france.png')}
                />
              </Then>
            </If>           
          </DropdownToggler>
        }
     >
       <Menu items={
         languages.map((language, key) => 
          ({
            label: language.name,
            onClick: changeMultilanguageClick(language.localizationCulture)
          }))
         }
        />     
     </Dropdown>
  )