
import { createOrdersFromShoppingCart } from 'redux/modules/orders/actions'
import PropTypes from 'prop-types'

export const PROP_TYPES = {
  form: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  shoppingCardItems: PropTypes.arrayOf(PropTypes.object.isRequired)
}

export const ACTIONS = {
  createOrdersFromShoppingCart
}

export const CONTEXT_TYPES = {
  router: PropTypes.object
}